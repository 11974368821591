(function () {
  "use strict";

  /**
   * @ngdoc service
   * @name lessons.factory:CurrentUser
   *
   * @description
   *
   */
  angular
    .module("lessons")
    .factory("CurrentUser", CurrentUser);

  function CurrentUser($cookies, $window, $state, localStorageService, EventTracker, AuthService, CurrentBriefs, CurrentProgram) {
    var localUser = localStorageService.get("currentUser"),
      currentUser = {
        getUser: getUser,
        storeUser: storeUser,
        clearUser: clearUser
      };

    function getUser() {
      if (!localUser) {
        return AuthService.apiService()
          .one("users/current")
          .get();
      }
    }

    function storeUser() {
      var toStateName = $state.toState.name;

      if (toStateName === "workbooks_home" && localStorageService.get("currentProgram")) return;

      if (localUser) {
        CurrentProgram.setCurrentProgram();
        if (localUser.allow_briefs) {
          CurrentBriefs.setBriefs();
        }
        return localUser;
      }
      return getUser().then(function (res) {
        localUser = res.user;
        localStorageService.set("currentUser", localUser);
        EventTracker.identify(localUser.id);
        CurrentProgram.setCurrentProgram();
        if (localUser.allow_briefs) {
          CurrentBriefs.setBriefs();
        }
      });
    }

    function clearUser() {
      localStorageService.clearAll();
      localUser = null;
    }

    return currentUser;
  }
}());
