(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name directives.menus.directive:footerMenu
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="directives.menus">
   <file name="index.html">
   <footer-menu></footer-menu>
   </file>
   </example>
   *
   */
  angular
      .module('directives.menus')
      .directive('footerMenu', footerMenu);

  function footerMenu() {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'directives/menus/footer-menu-directive.tpl.html',
      replace: false,
      controllerAs: 'footerMenu',
      controller: function () {
        var vm = this;
        vm.name = 'footerMenu';
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
