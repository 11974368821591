(function () {
  'use strict';

  angular
    .module('programs')
    .controller('ProgramPartCtrl', ProgramPartCtrl);

  function ProgramPartCtrl(currentPart, OnboardingFactory) {
    var vm = this;
    vm.ctrlName = 'ProgramPartCtrl';
    vm.currentPart = currentPart;

    OnboardingFactory.init(currentPart.program_part);

    return vm;
  }
}());
