(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name directives.directive:msMessageBox
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="directives">
   <file name="index.html">
   <ms-message-box></ms-message-box>
   </file>
   </example>
   *
   */
  angular
    .module('directives')
    .directive('msMessageBox', msMessageBox);

  function msMessageBox() {
    return {
      restrict: 'EA',
      scope: {
        msgTitle: '=',
        msgText: '=',
        msgBtnText: '=',
        msgBtnIcon: '=',
        iconColor: '=',
        msgBtnFct: '&'
      },
      bindToController: true,
      templateUrl: 'directives/ms-message-box-directive.tpl.html',
      replace: false,
      controllerAs: 'msMessageBoxVm',
      controller: function () {
        var vm = this;
        vm.name = 'msMessageBoxVm';
      },
      link: function (scope, element, attrs, vm) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
