(function () {
  'use strict';

  angular
    .module('briefs')
    .controller('BriefsCtrl', function BriefsCtrl($rootScope) {
      var vm = this;
      vm.ctrlName = 'BriefsCtrl';

      $rootScope.$broadcast("page:load"); // inform parts menu

      return vm;
    });
}());
