(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name directives.directive:NotificationIndicator
   * @restrict EA
   * @element
   *
   *
   */
  angular
    .module('directives')
    .directive('notificationIndicator', notificationIndicator);

  function notificationIndicator($interval, NotificationsService) {
    return {
      restrict: 'EA',
      templateUrl: 'directives/notification-indicator-directive.tpl.html',
      replace: false,
      controllerAs: 'notificationIndicatorVm',
      controller: function () {
        var vm = this;
        vm.show = false;
        setIndicatorStatus();

        $interval(setIndicatorStatus, 60000);

        function setIndicatorStatus() {
          NotificationsService.count().then(function(res) {
            if (res.notifications) {
              if (res.notifications.count > 0) {
                vm.show = true;
              } else {
                vm.show = false;
              }
            }
          });
        }
      }
    };
  }
}());
