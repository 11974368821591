(function () {
  "use strict";

  /**
   * @ngdoc directive
   * @name directives.directive:moduleMenu
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="directives">
   <file name="index.html">
   <module-menu></module-menu>
   </file>
   </example>
   *
   */
  angular
    .module("directives")
    .directive("moduleMenu", moduleMenu);

  function moduleMenu() {
    return {
      restrict: "E",
      scope: {},
      bindToController: {},
      templateUrl: "directives/menus/module-menu-directive.tpl.html",
      replace: false,
      controllerAs: "moduleMenuVm",
      controller: moduleMenuController
    };
  }

  moduleMenuController.$inject = ["$rootScope", "$state", "$timeout", "CurrentProgram", "localStorageService"];

  function moduleMenuController($rootScope, $state, $timeout, CurrentProgram, localStorageService) {
    /* jshint validthis: true */
    var vm = this;
    vm.name = "moduleMenu";
    vm.part = localStorageService.get("currentPart");
    vm.modules = vm.part.class_modules;

    return vm;
  }
}());
