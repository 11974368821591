/* eslint-disable camelcase */
/* jshint camelcase: false */
(function () {
  "use strict";

  angular
    .module("workbook")
    .directive("taskAndProject", taskAndProject);

  function taskAndProject() {
    var directive = {
      restrict: "E",
      template: "<div ng-include='getContentUrl()'></div>",
      scope: {},
//      replace: true,
      bindToController: {
        tuple: "=",
        mode: "=",
        owner: "="
      },
      controller: TaskAndProjectController,
      controllerAs: "taskAndProjectVm",
      link: function (scope, element, attrs, vm) {
        scope.getContentUrl = getContentUrl;

        function getContentUrl() {
          return vm.tuple.task.networkMaterial ?
            "directives/workbooks/task-and-project-brief-directive.tpl.html" :
            "directives/workbooks/task-and-project-directive.tpl.html";
        }
      }
    };

    return directive;
  }

  TaskAndProjectController.$inject = ["$state", "$window", "CollaboratorsService"];

  function TaskAndProjectController($state, $window, CollaboratorsService) {
    var vm = this,
      absoluteContext = vm.tuple.task.networkMaterial ? "briefs." : "part.",
      hashParams = {
        partId: vm.tuple.task.part_id,
        lessonId: vm.tuple.task.id
      };
    vm.notDeleted = true;
    vm.feedbacks = vm.tuple.project.feedbacks;
    vm.myProject = vm.owner.name === vm.tuple.project_owner.name && vm.mode === "you";
    vm.projectOwner = vm.myProject ? "You" : vm.tuple.project_owner.name;
    vm.projectOwnedBy = vm.myProject ? "Your" : vm.tuple.project_owner.name + "'s";

    vm.images = _.last(vm.tuple.project.images, 5).reverse();
    vm.gridSize = vm.images.length;

    vm.menuArr = [{
      name: "Remove tag",
      iconClass: "icon icon-delete",
      action: function () {
        removeTag();
      }
    }];

    vm.gotoProject = gotoProject;
    vm.gotoPart = gotoPart;

    if (vm.tuple.task.networkMaterial) getCollaborators();
    if (absoluteContext === "part.") hashParams.programId = vm.tuple.course_id;

    // ------------------ Function Declarations

    function gotoProject() {
      var action = absoluteContext + (vm.myProject ? "project_edit" : "project");

      if (vm.tuple.project) hashParams.projectId = vm.tuple.project.id;
      $state.go(action, hashParams);
    }

    function gotoPart() {
      $state.go(absoluteContext + "lesson", hashParams);
    }

    function setCollaboratorList() {
      var collaboratorNameArray = [];
      if (vm.collaborators.length > 0) {
        collaboratorNameArray = vm.collaborators.map(function (collab) {
          return collab.name;
        });
        vm.collaboratorList = collaboratorNameArray.join(", ");
      }
    }

    function getCollaborators() {
      return CollaboratorsService.getCollaborators(vm.tuple.project.id).then(function (resp) {
        vm.collaborators = resp.project_collaborators;
        vm.projectAuthor = resp.author;
        setCollaboratorList();
      });
    }

    function removeTag() {
      var collabToRemove = {};
      if ($window.confirm("Are you sure you want to remove yourself from this project?")) {
        collabToRemove = _(vm.collaborators).find(function (collab) {
          return collab.name === vm.owner.name;
        });
        CollaboratorsService.deleteCollaborator(vm.tuple.project.id, collabToRemove.id).then(function () {
          vm.notDeleted = false;
        });
      }
    }
  }
}());
