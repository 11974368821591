/* eslint-disable camelcase*/
/* eslint-disable dot-notation*/
/* jshint camelcase: false */
/* jshint sub: true */

(function () {
  "use strict";

  angular
    .module("directives")
    .directive("msUserLinks", msUserLinks);

  function msUserLinks() {
    return {
      restrict: "E",
      scope: {},
      templateUrl: "directives/ms-user-links-directive.tpl.html",
      replace: false,
      bindToController: {
        user: "=",
        programId: "=",
        programTitle: "="
      },
      controllerAs: "msUserLinksVm",
      controller: function () {
        var vm = this;
        if (angular.isDefined(vm.user.social_links["personal_website"])) {
          // change personal_website attribute to link so it displays correctly with font awesome
          vm.user.social_links["link"] = vm.user.social_links["personal_website"];
          vm.user.social_links = _.omit(vm.user.social_links, "personal_website");
        }
        return vm;
      }
    };
  }
}());
