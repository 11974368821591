<div class="container-fluid edit-collabs" ng-if="collabVm.collaborator">
  <form ng-submit="collabVm.saveCollaborator(collabVm.itemId)">
    <div class="row row-eq-height vertical-align">
      <div class="col-xs-6 collabs-role-col">
        <h3 ng-if="collabVm.author">Your role</h3>
        <h3 ng-if="!collabVm.author">{{collabVm.collaborator.first_name}}'s role</h3>
      </div>
      <div class="col-xs-6 remove-col">
        <a href='' ng-if="!collabVm.author" ng-click="collabVm.deleteCollaborator(collabVm.collaborator)">Remove {{collabVm.collaborator.first_name}}</a>
      </div>
    </div>
    <div class="collab-choice-holder row">
      <div class="row">
        <div class="col-xs-12">
          <div class="what-role" ng-if="collabVm.collaborator.external"><strong>Where can we see {{collabVm.collaborator.first_name}}'s work?</strong></div>
        </div>
      </div>
      <div class="row" ng-if="collabVm.collaborator.external">
        <div class="col-xs-12">
          <div class="form-group">
            <input
            ng-model="collabVm.websiteField"
            type="text"
            class="form-control other-role"
            ng-required="true"
            placeholder="Website URL">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <div class="what-role" ng-if="collabVm.author"><strong>What is your role in this project?</strong></div>
          <div class="what-role" ng-if="!collabVm.author"><strong>What is {{collabVm.collaborator.first_name}}'s role in this project?</strong></div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <button
              class="btn btn-default discipline-btn"
              ng-click="collabVm.disciplineSelected(discipline)"
              ng-class="{active: discipline.checked}"
              ng-repeat="discipline in collabVm.disciplines">
              {{discipline.name}}
            </button>
          </div>
        </div>
          <div class="col-xs-12">
            <div class="form-group">
              <div class="col-xs-12">
                <input
                ms-focus-me="collabVm.customDisc"
                ng-change="collabVm.clearDisciplines(null)"
                ng-if="collabVm.author"
                ng-model="collabVm.disciplineField"
                type="text"
                class="form-control other-role"
                placeholder="Other (enter what you did here)">
              <input
                ms-focus-me="collabVm.customDisc"
                ng-if="!collabVm.author"
                ng-model="collabVm.disciplineField"
                type="text"
                class="form-control other-role"
                placeholder="Other (enter what {{collabVm.collaborator.first_name}} did here)">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <button class="btn btn-black tag-done-btn" type="submit">Done</button>
    </div>
  </form>
</div>
