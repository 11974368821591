(function () {
  'use strict';

  angular
    .module('programs')
    .controller('IntroductionVideoCtrl', IntroductionVideoCtrl);

  function IntroductionVideoCtrl(introductionData, $window, $sce, OnboardingFactory) {
    var vm = this;
    vm.ctrlName = 'IntroductionVideoCtrl';
    vm.introductionData = introductionData.introduction_body;

    // redirect when no introduction page data
    if (vm.introductionData === null) {
      $window.location = "/404";
      return;
    }

    vm.introductionDataHTML = $sce.trustAsHtml(vm.introductionData);
    vm.continue = function() {
      OnboardingFactory.skipIntroductionVideo();
    };
    vm.part = OnboardingFactory.part;

    return vm;
  }
}());
