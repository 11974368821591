(function () {
  "use strict";

  /**
   * @ngdoc service
   * @name sessions.service:SessionsService
   *
   * @description
   *
   */
  angular
    .module("classroom")
    .service("SessionsService", SessionsService);

  function SessionsService($state, localStorageService, AuthService, CurrentUser) {
    var apiService = AuthService.apiService(),
      apiWithoutAuth = AuthService.apiService({auth: false}),
      session = {
        authenticatedUser: authenticatedUser,
        login: login,
        logout: signOut
      };

    return session;

    function login(user) {
      return apiWithoutAuth.all("sign_in").post({"email": user.email, "password": user.password});
    }

    function signOut() {
      return apiService
        .one("sign_out")
        .remove("", {"X-User_Email": localStorageService.get("email"), "X-User_Token": localStorageService.get("token"), "Content-Type": "application/json"})
        .then(function () {
          CurrentUser.clearUser();
          $state.go("sign-in", {}, { reload: true });
        });
    }

    function authenticatedUser() {
      return !!localStorageService.get("token");
    }
  }
}());
