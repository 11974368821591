(function () {
  "use strict";

  /* @ngdoc object
   * @name msHoneybadger
   * @description
   *
   */
  angular
    .module("msHoneybadger", [])
    .config(config)
    .run(run);

  function config($provide) {
    logDecorator.$inject = ["$delegate"];

    function logDecorator($delegate) {
      var errorFn = $delegate.error;
      $delegate.error = function (e) {
        Honeybadger.beforeNotify(function (notice) {
          Honeybadger.setContext({
            info: notice ? notice.data : null
          });
          if (notice.data && !notice.message) {
            notice.message = notice.data.title + ", status: " + notice.data.status;
          }
          // Returning false stops notification to honeybadger allegedly - need to check in production
          if (notice.message === "Object doesn't support property or method 'vjs_getProperty'") {
            return false;
          }
        });
        Honeybadger.notify(e);
        errorFn.apply(null, arguments);
      };
      return $delegate;
    }

    $provide.constant("logDecorator", logDecorator);
    $provide.decorator("$log", logDecorator);
  }

  function run(envService) {
    var apiKey = envService.read("honeyBadgerApiKey"),
      env = envService.get();
    if (apiKey) {
      Honeybadger.configure({
        apiKey: apiKey,
        environment: env,
        maxErrors: 20
      });
    }
  }
}());
