(function () {
  'use strict';

  angular
    .module('projects')
    .controller('ProjectItemEditCtrl', ProjectItemEditCtrl);

  function ProjectItemEditCtrl($rootScope, $state, $stateParams, ProjectsService, ProjectItemService) {
    var vm = this;
    vm.programId = $stateParams.programId;
    vm.partId = $stateParams.partId;
    vm.lessonId = $stateParams.lessonId;
    vm.itemId = parseInt($stateParams.itemId, 10);
    vm.project = {};
    vm.item = {};
    vm.canSave = true;

    vm.save = function () {
      save();
    };
    vm.cancel = function () {
      cancel();
    };
    // init
    getCurrentUserProject();

    return vm;

    // Function declarations

    function save() {
      vm.canSave = false;

      ProjectItemService
        .update(vm.project.id, vm.item)
        .then(function () {
          vm.canSave = true;
          vm.cancel();
        });
    }

    function cancel() {
      $state.go('^.project_edit', {
        programId: vm.programId,
        partId: vm.partId,
        lessonId: vm.lessonId
      });
    }

    function getCurrentUserProject() {
      ProjectsService.currentUserProject(vm.lessonId)
        .then(function (res) {
          if (res.project.id) {
            vm.project = res.project;
            vm.item = _.find(res.project.items, {id: vm.itemId});
            if (vm.item.projectable.text) {
              vm.editable = vm.item.projectable.text;
              vm.type = 'Text';
            } else if (vm.item.projectable.embed_video_url) { /* jshint ignore:line */ // eslint-disable-line camelcase
              vm.editable = vm.item.projectable.body;
              vm.type = 'Video';
            } else {
              vm.editable = vm.item.projectable.body;
              vm.type = 'Image';
            }
          }
          // ToDo: refactoring $rootScope
          $rootScope.$broadcast("page:load");
        });
    }
  }
}());

