(function () {
  'use strict';

  angular
    .module('directives.menus')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('menus', {
        parent: "main",
        url: '/menus'
      });
  }
}());
