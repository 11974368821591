<div class="top-menu-directive-holder">
  <nav class="top-menu-directive navbar">
    <div class="container-fluid full-width">
      <!-- Brand and toggle get grouped for better mobile display -->
      <div class="navbar-header">


        <a class="navbar-brand"
          ng-disabled="!topMenuVm.showHome()"
          ui-sref="workbook({
             programId:  topMenuVm.currentProgram.id,
             workbookId: topMenuVm.currentProgram.workbookId,
             partId: topMenuVm.currentPart.id})"
          role="button">
          <img class="mastered-logo" src="images/logo-sm.svg">
        </a>

        <button class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
          <img class="avatar-img-circle context-more-menu-icon icon-avatar" ng-src="{{topMenuVm.user.avatar_url}}">
          <ms-icon icontype="'close'" class="context-more-menu-icon"></ms-icon>
        </button>

        <div class="clearfix visible-xs-block"></div>
        <div class="divider-horizontal visible-xs-block"></div>

        <!-- Mobile nav -->
        <div class="collapsed navbar-toggle mobile-nav">
          <div class="nav navbar-nav navbar-left">
            <div class="ms-flex-container">
              <div class="ms-flex-item" ui-sref-active="active">
                <div ui-sref="workbooks_home"
                   ng-class="{ active: topMenuVm.isWorkbook() }"
                   ng-if="topMenuVm.showHome()">
                  Home
                </div>
              </div>
              <div class="ms-flex-item" ui-sref-active="active" ng-class="{ overactive: topMenuVm.isPrograms() && !topMenuVm.isWorkbook() }">
                <a ui-sref="courseOutline({ programId:  topMenuVm.currentProgram.id})"
                   ng-class="{ active: topMenuVm.isPrograms() && !topMenuVm.isWorkbook() }"
                   ng-if="topMenuVm.currentProgram">
                  Classroom<span ng-if="topMenuVm.user.admin" class="hidden-xs"> - {{topMenuVm.currentProgram.name}}</span>
                </a>
              </div>
              <div class="ms-flex-item" ui-sref-active="active" ng-class="{ overactive: topMenuVm.isBriefs() }" ng-if="!topMenuVm.hideDirBriefsAndSupport()">
                <a ui-sref="briefOutline({partId: topMenuVm.user.default_brief_id})"
                   ng-class="{ active: topMenuVm.isBriefs() }"
                   role="button">
                  Briefs</a>
              </div>
              <div class="ms-flex-item" ui-sref-active="active" ng-if="!topMenuVm.hideDirBriefsAndSupport()">
                <a href="/directory" role="button">Directory</a>
              </div>
            </div>
          </div>
        </div>

      </div>

      <!-- Collect the nav links, forms, and other content for toggling -->
      <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">

        <!--Left side of top menu-->
        <ul class="nav navbar-nav navbar-left">

          <!-- Desktop -->
          <li ui-sref-active="active" class="hidden-xs">
            <a ui-sref="workbooks_home"
               ng-class="{ active: topMenuVm.isWorkbook() }"
               ng-if="topMenuVm.showHome()">
              Home
            </a>
          </li>

          <li ui-sref-active="active" class="hidden-xs">
            <a ui-sref="courseOutline({ programId:  topMenuVm.currentProgram.id})"
               ng-class="{ active: topMenuVm.isPrograms() && !topMenuVm.isWorkbook() }"
               ng-if="topMenuVm.currentProgram">
              Classroom<span ng-if="topMenuVm.user.admin" class="hidden-xs"> - {{topMenuVm.currentProgram.name}}</span>
            </a>
          </li>

          <li ng-if="topMenuVm.user.allow_briefs===true && !topMenuVm.hideDirBriefsAndSupport()"
              ui-sref-active="active"
              class="hidden-xs">
            <a ui-sref="briefOutline({partId: topMenuVm.user.default_brief_id})"
               ng-class="{ active: topMenuVm.isBriefs() }"
               role="button">
              Briefs</a>
          </li>
          <li ng-if="topMenuVm.user.allow_directory===true && !topMenuVm.hideDirBriefsAndSupport()"
              class="hidden-xs">
            <a href="/directory" role="button">Directory</a>
          </li>
        </ul>

        <!--Right side of top menu-->
        <ul class="nav navbar-nav navbar-right">
          <!--Dropdown for multiple enrolments-->
          <li ng-repeat="program in topMenuVm.user.programs" ng-if='topMenuVm.user.programs.length > 0' class="visible-xs-block program">
            <a ui-sref="courseOutline({programId: program.id})" ui-sref-opts="{location: 'replace'}">
              {{program.name}}
            </a>
          </li>

          <!--Show help and notification icons to users enrolled on full courses-->
          <li class="support" ng-if="topMenuVm.user.programs.length > 0 && !topMenuVm.onlyProgCollab && !topMenuVm.hideDirBriefsAndSupport()">
            <a href="http://support.mastered.com/" target="_blank">
              <span>Support</span>
            </a>
          </li>
          <li class="notifications" ng-if="topMenuVm.user.programs.length > 0 && !topMenuVm.onlyProgCollab">
            <a href="/notifications/me">
              <div class="new-notifications-title" ng-attr-title="{{notificationIndicatorVm.show ? 'You have unread notifications' : ''}}">Notifications</div>
              <notification-indicator></notification-indicator>
            </a>
          </li>

          <li class="dropdown profile-dropdown hidden-xs">
            <a href="" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
              <img class="avatar-img-circle" ng-src="{{topMenuVm.user.avatar_url}}">
              <span class="caret"></span>
            </a>
            <ul class="dropdown-menu">
              <li class="top-menu-drop-item" ng-if="topMenuVm.user.programs.length > 1" ng-repeat="program in topMenuVm.user.programs">
                <a ui-sref="courseOutline({ programId:  program.id})" ng-class="{active: program.id === topMenuVm.currentProgram.id}">
                  {{program.name}}
                </a>
              </li>
              <li role="seperator" ng-if="topMenuVm.user.programs.length > 1" class="divider-horizontal top-menu-drop-item"></li>
              <li class="top-menu-drop-item">
                <a href="/users/{{topMenuVm.user.id}}">Your profile</a>
              </li>
              <li class="top-menu-drop-item">
                <a href="/users/edit?tab=profile">Settings</a>
              </li>
              <li role="seperator" class="divider-horizontal top-menu-drop-item"></li>
              <li class="top-menu-drop-item">
                <a href="" ng-click="topMenuVm.signOut()">Sign out</a>
              </li>
            </ul>
          </li>
          <li class="visible-xs-block">
            <a href="/users/{{topMenuVm.user.id}}">Your profile</a>
          </li>
          <li class="visible-xs-block">
            <a href="/users/edit?tab=profile">Settings</a>
          </li>
          <li class="visible-xs-block">
            <a href="" ng-click="topMenuVm.signOut()">Sign out</a>
          </li>
        </ul>
      </div><!-- /.navbar-collapse -->
    </div><!-- /.container-fluid -->
  </nav>
  <div class="grey-bg" ng-if="topMenuVm.showGreyBg"></div>
  <ms-banners ng-if="topMenuVm.currentProgram && topMenuVm.isWorkbook()" program-id="{{topMenuVm.currentProgram.id}}"></ms-banners>
  <ms-announcements ng-if="topMenuVm.isWorkbook()"></ms-announcements>
</div>
