/* eslint-disable max-params */

(function () {
  "use strict";

  angular
    .module("workbook")
    .controller("WorkbookCtrl", WorkbookCtrl);

  function WorkbookCtrl(
    $document,
    $filter,
    $rootScope,
    $stateParams,
    $log,
    $timeout,
    $window,
    envService,
    localStorageService,
    ReflectionsService,
    WorkbookService) {
    var vm = this;
    vm.ctrlName = "workbookCtrl";
    vm.workbookId = null;
    vm.workbook = null;
    vm.currentUser = localStorageService.get("currentUser");
    vm.currentProgram = localStorageService.get("currentProgram");
    vm.showHangouts = false;
    vm.isExpert = false;
    vm.showSticky = false;

    vm.addReflection = addReflection;
    vm.updateReflection = updateReflection;
    vm.removeReflection = removeReflection;
    vm.hideDirBriefsAndSupport = hideDirBriefsAndSupport;
    vm.scrollToAndFocus = scrollToAndFocus;

    activate();

    return vm;

    // ------------------

    function addReflection(reflection) {
      ReflectionsService.post(vm.workbookId, reflection).then(function (resp) {
        vm.workbook.tuples.splice(0, 0, resp.reflection);
      }, function (error) {
        $log.error(error);
        return error;
      });
    }

    function updateReflection(reflection) {
      ReflectionsService.put(vm.workbookId, reflection).then(function (resp) {
        var idx;
        for (idx = 0; idx < vm.workbook.tuples.length; idx++) {
          if (vm.workbook.tuples[idx].id === reflection.id) {
            vm.workbook.tuples[idx] = resp.reflection;
            break;
          }
        }
      }, function (error) {
        $log.error(error);
        return error;
      });
    }

    function removeReflection(reflection) {
      var idx;
      ReflectionsService.remove(vm.workbookId, reflection.id).then(function () {
        idx = vm.workbook.tuples.indexOf(reflection);
        if (idx > -1) {
          vm.workbook.tuples.splice(idx, 1);
        }
      }, function (error) {
        $log.error(error);
        return error;
      });
    }

    function setupHangout(wbk) {
      vm.isExpert = !wbk.is_current_owner && (vm.currentUser.admin || vm.currentUser.expert);
    }

    function hideDirBriefsAndSupport() {
      if (vm.currentProgram) {
        return envService.read("hideDirBriefsAndSupport").indexOf(vm.currentProgram.id) !== -1;
      }
      return false;
    }

    function scrollToAndFocus(anchor) {
      var targetElement = angular.element(anchor),
        offset = 100,
        duration = 750;
      $document.scrollToElement(targetElement, offset, duration);
      angular.element("#textarea-999").focus();
    }

    function newReflectionInView(delayForLoad) {
      var
        testElement,
        rect,
        inView;
      $timeout(function () {
        delayForLoad = delayForLoad || 0;
        testElement = $document[0].getElementById("new-reflection");
        if (!testElement) {
          return;
        }
        rect = testElement.getBoundingClientRect();
        inView = rect.top >= 0 && rect.bottom <= ($window.innerHeight || $document[0].documentElement.clientHeight);
        vm.showSticky = !inView;
      }, delayForLoad);
    }

    function setupWorkbook(pageLoadDelay) {
      $timeout(function () {
        vm.workbookId = $stateParams.workbookId || localStorageService.get("currentProgram").workbookId;
        WorkbookService.get(vm.workbookId).then(function (result) {
          vm.workbook = result.workbook;

          vm.header = vm.workbook.is_current_owner ? "Your journal" : vm.workbook.owner.name + "'s journal";
          vm.goalsHeader = vm.workbook.is_current_owner ? "Goals" : vm.workbook.owner.name + "'s goals";
          newReflectionInView(500);

          setupHangout(vm.workbook);
          return vm.workbook;
        });
      }, pageLoadDelay);
    }

    function activate() {
      var pageLoadDelay = 0;
      // Sets delay in rare case where currentProgram has not yet returned from api
      if (!localStorageService.get("currentProgram")) pageLoadDelay = 500;
      setupWorkbook(pageLoadDelay);
      angular.element($window).bind("scroll", function() {
        newReflectionInView();
      });
    }
  }
}());
