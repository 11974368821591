(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name classroom.service:CurrentPartService
   *
   * @description
   *
   */
  angular
      .module('classroom')
      .service('CurrentPartService', CurrentPartService);

  function CurrentPartService(AuthService) {
    var self = {
      get: function (partId) {
        self.partId = partId;
        return AuthService.apiService()
          .one('parts', partId)
          .get();
      },
      bookingFeedback: function (partId) {
        self.partId = partId;
        return AuthService.apiService()
          .one('parts', partId)
          .one('booking-feedback')
          .get();
      },
      bookingCoaching: function (partId) {
        self.partId = partId;
        return AuthService.apiService()
          .one('parts', partId)
          .one('booking-coaching')
          .get();
      },
      partId: null,
      set: function(partId) {
        self.partId = partId;
      }
    };

    return self;
  }
}());
