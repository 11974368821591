(function () {
  "use strict";

  /**
   * @ngdoc directive
   * @name directives.directive:msJournal
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="directives">
   <file name="index.html">
   <ms-journal></ms-journal>
   </file>
   </example>
   *
   */
  angular
    .module("directives")
    .directive("msJournal", msJournal);

  function msJournal(localStorageService) {
    return {
      restrict: "E",
      scope: true,
      templateUrl: "directives/home-page/ms-journal-directive.tpl.html",
      bindToController: true,
      replace: false,
      controllerAs: "journalVm",
      controller: function ($scope) {
        var vm = this,
          workbookVm = $scope.workbookVm;
        vm.workbook = workbookVm.workbook;
        vm.userProgs = localStorageService.get("currentUser").programs;
      }
    };
  }
}());
