(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name classroom.service:LessonsService
   *
   * @description
   *
   */
  angular
    .module('classroom')
    .service('LessonsService', LessonsService);

  function LessonsService(AuthService) {
    var self = {
      get: function(lessonId) {
        return AuthService.apiService()
          .one('lessons', lessonId)
          .get();
      }
    };

    return self;
  }
}());
