(function () {
  'use strict';

  /* @ngdoc object
   * @name courseOutline
   * @description
   *
   */
  angular
    .module('courseOutline', [
      'ui.router',
      'courseOutline.briefsOutline'
    ]);
}());
