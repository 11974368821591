(function () {
  "use strict";

  /**
   * @ngdoc directive
   * @name directives.directive:msInSession
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="directives">
   <file name="index.html">
   <ms-in-session></ms-in-session>
   </file>
   </example>
   *
   */
  angular
    .module("directives")
    .directive("msInSession", msInSession);

  function msInSession(envService) {
    return {
      restrict: "E",
      scope: true,
      templateUrl: "directives/home-page/ms-in-session-directive.tpl.html",
      bindToController: true,
      replace: false,
      controllerAs: "inSessionVm",
      controller: function ($state, CurrentProgram, localStorageService, $log) {
        var vm = this,
          currentProgramId = parseInt($state.toParams.programId, 10) || localStorageService.get("currentProgram").id;

        vm.hideDirBriefsAndSupport = hideDirBriefsAndSupport;

        activate();

        return vm;

        // -----------------

        function activate() {
          CurrentProgram.inSession(currentProgramId).then(function (res) {
            vm.openPartList = res.parts;
            return vm.openPartList;
          }, function (error) {
            $log.error(error);
            return error;
          });
        }

        function hideDirBriefsAndSupport() {
          return envService.read("hideDirBriefsAndSupport").indexOf(currentProgramId) !== -1;
        }
      }
    };
  }
}());
