<div class="collab-holder">
  <a ng-href="{{collaboratorItemVm.userProfileUrl}}" target="_blank">
    <div class="vertical-align">
      <div class="flex2">
        <img class="img-circle img-responsive user-avatar" ng-src="{{collaboratorItemVm.userAvatarUrl}}">
      </div>
      <div class="flex10 name-col">
        <div class="name-bit">
          <span ng-if="collaboratorItemVm.userDiscipline">
            <strong>{{collaboratorItemVm.userDiscipline}}</strong> by
          </span>
          <span>
            <strong>{{collaboratorItemVm.userFullname}}</strong> {{collaboratorItemVm.userTitle}}
          </span>
        </div>
      </div>
    </div>
  </a>
</div>
