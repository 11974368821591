(function () {
  "use strict";

  /**
   * @ngdoc service
   * @name classroom.service:CommentsService
   *
   * @description
   *
   */
  angular
    .module("classroom")
    .service("CommentsService", CommentsService);

  function CommentsService(localStorageService, AuthService, EventTracker) {
    var self = {
        get: function (pathToCommentsFeed, page) {
          return AuthService.apiService().one(pathToCommentsFeed).get({page: page});
        },
        post: function (comment) {
          return AuthService.apiService().all("comments").post(comment)
            .then(function (res) {
              EventTracker.track("Added Comment", {
                app: "web",
                user_id: localStorageService.get("currentUser").id, /* jshint ignore:line */ // eslint-disable-line camelcase
                target_type: res.comment.commentable_type, /* jshint ignore:line */ // eslint-disable-line camelcase
                target_id: res.comment.commentable_id, /* jshint ignore:line */ // eslint-disable-line camelcase
              });

              return res;
            });
        },
        remove: function (commentId) {
          return AuthService.apiService()
            .all("comments")
            .one(String(commentId))
            .remove();
        },
        restore: function (commentId) {
          return AuthService.apiService()
            .all("comments")
            .one(String(commentId))
            .one("restore")
            .put();
        }
      };

    return self;
  }
}());
