(function () {
  "use strict";

  angular
    .module("projects")
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state("project_new", {
        url: "/projects/:projectId",
        templateUrl: "projects/project.tpl.html",
        controller: "ProjectCtrl",
        controllerAs: "projectVm"
      });
  }
}());
