<div class="ms-project-collaborations">
  <div ng-repeat="item in msProjectCollaborationsVm.items track by $index">
    <div class="row">
      <div class="mastered-msg-box">
        <div class="col-xs-7 col-sm-9 vcenter">
          <div class="mastered-msg-text">
            <strong>
              <a href="/users/{{item.owner_id}}">{{item.owner_name}}</a>
            </strong> tagged you to their project
          </div>
        </div>
        <div class="col-xs-12 vcenter">
          <a ui-sref="^.project({ lessonId: msProjectCollaborationsVm.lessonId,
                                  projectId: item.project_id })"
             class="btn btn-default mastered-msg-btn">
            View project
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
