(function () {
  "use strict";

  /**
   * @ngdoc service
   * @name lessons.factory:CurrentBriefs
   *
   * @description
   *
   */
  angular
    .module("classroom")
    .factory("CurrentBriefs", CurrentBriefs);

  function CurrentBriefs($log, $state, $stateParams, AuthService, localStorageService) {
    var currentBriefs = {
        setBriefs: setBriefs
      };

    function fetch() {
      return AuthService.apiService()
        .one("briefs")
        .get();
    }

    function setBriefs() {
      fetch().then(function (result) {
        var isOutline = $state.toState.name === "courseOutline" || $state.toState.name === "briefOutline",
          briefPartFallbackId = result.course_parts[result.course_parts.length - 1].id,
          briefs = result.course_parts,
          currentBrief = null,
          partId,
          lessonId,
          isBrief = $state.toState.name === "briefs" || $state.toState.name === "briefs.lesson" || $state.toState.name === "briefOutline";
        partId = parseInt($state.toParams.partId, 10) || briefPartFallbackId;

        if (isBrief) {
          currentBrief = _.find(briefs, {id: partId});
        } else {
          currentBrief = _.find(briefs, {id: partId});
        }

        localStorageService.set("briefs", briefs);
        localStorageService.set("briefPartFallbackId", briefPartFallbackId);
        localStorageService.set("currentBrief", currentBrief);
        if (currentBrief && !isOutline && isBrief) {
          lessonId = $state.toParams.lessonId || currentBrief.latest_lesson_id;
          $state.go("briefs.lesson", {partId: currentBrief.id, lessonId: lessonId}, {location: "replace"});
        }
      }, function (error) {
        $log.error(error);
      });
    }
    return currentBriefs;
  }
}());
