(function () {
  "use strict";

  /**
   * @ngdoc object
   * @name courseOutline.briefsOutline.controller:BriefsOutlineCtrl
   *
   * @description
   *
   */
  angular
    .module("courseOutline.briefsOutline")
    .controller("BriefsOutlineCtrl", BriefsOutlineCtrl);

  function BriefsOutlineCtrl(
    $stateParams,
    $rootScope,
    $state,
    $window,
    localStorageService) {
    var vm = this;
    vm.ctrlName = "BriefsOutlineCtrl";
    vm.currentUser = localStorageService.get("currentUser");
    vm.currentBrief = localStorageService.get("currentBrief");
    vm.currentParts = vm.currentBrief ? localStorageService.get("briefs") : null;
    vm.currentPartId = vm.currentBrief ? vm.currentBrief.id : null;

    $rootScope.loading = !($state.fromState.name.indexOf("briefs.") >= 0 || $state.fromState.name.indexOf("workbook") >= 0);
    $rootScope.$on("LocalStorageModule.notification.setitem", function (event, data) { // eslint-disable-line angular/on-watch
      if (data.key === "currentBrief" && !vm.currentBrief) {
        $window.location.reload();
      }
    });
  }
}());
