<div class="container--full-width">
  <div class="row sentiment-tester" ng-if="vm.visible">
    <div ng-hide="vm.score">
      <p>How are you feeling about your Mastered program?</p>
      <div class="mood-icons">
        <img
          ng-repeat="s in [1, 2, 3, 4, 5]"
          ng-src="/classroom/images/sentiments/{{s}}.png"
          ng-click="vm.sendScore(s)">

        <button type="button" class="close" ng-click="vm.sendScore(-1); vm.hide()">&times;</button>
      </div>
    </div>

    <div ng-show="vm.score">
      <p ng-bind-html="vm.message | trustAsHtml"></p>
      <button type="button" class="close" ng-click="vm.hide()">&times;</button>
    </div>
  </div>
</div>
