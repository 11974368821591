<div class="mastered-msg-box">
  <div class="container-fluid vertical-align">
    <div class="logo-col">
      <img class="mastered-msg-logo" src="images/mastered-round-logo.svg">
    </div>
    <div class="text-col">
      <div class="mastered-msg-text">
        <strong>{{msMessageBoxVm.msgTitle}}</strong>
        <br ng-if="msMessageBoxVm.msgTitle && msMessageBoxVm.msgText">
        {{msMessageBoxVm.msgText}}
      </div>
    </div>
    <div class="complete-col">
      <button class="btn btn-default mastered-msg-btn" ng-click="msMessageBoxVm.msgBtnFct()">
        <span class="tick-icon" ms-icon icontype="msMessageBoxVm.msgBtnIcon" watch-icon="true" icon-color="msMessageBoxVm.iconColor"></span>
        {{msMessageBoxVm.msgBtnText}}
      </button>
    </div>
  </div>
</div>
</div>
