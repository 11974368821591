//
// Menu array strcuture
//   name - item name. It could be String or Function type.
//   iconClass - icon class i.ex. "fa fa-trash". It could be String or Function type.
//   action - handler to action function
//   confirm - i.ex. "are you sure to delete?"
//             if present then launch confirm dialog with the message

(function () {
  'use strict';

  angular
    .module('directives')
    .directive('msThreeDotsMenu', ["$window", msThreeDotsMenu]);

  function msThreeDotsMenu($window) {
    return {
      restrict: 'EA',
      scope: {
        menuArr: "="
      },
      templateUrl: 'directives/ms-three-dots-menu-directive.tpl.html',
      replace: true,
      bindToController: true,
      controllerAs: 'msThreeDotsVm',
      controller: function() {
        var vm = this;
        vm.callAction = function(idx) {
          if (vm.isFunction(vm.menuArr[idx].action)) {
            if (angular.isDefined(vm.menuArr[idx].confirm)) {
              if ($window.confirm(vm.menuArr[idx].confirm)) {
                vm.menuArr[idx].action();
              }
            } else {
              vm.menuArr[idx].action();
            }
          }
        };

        // helper method
        vm.isFunction = function(arg) {
          return angular.isFunction(arg);
        };

        return vm;
      }
    };
  }
}());
