(function() {
  'use strict';

  angular
    .module('classroom')
    .service('trackerClient', MixpanelInitializer)
    .service('EventTracker', EventTracker);

  function MixpanelInitializer($window, envService) {
    $window.mixpanel.init(envService.read('mixpanelToken'));

    return $window.mixpanel;
  }

  function EventTracker(trackerClient) {
    var client = trackerClient;

    this.identify = function(userId) {
      client.identify(userId);
    };

    this.track = function(name, properties) {
      client.track(name, properties);
    };
  }
}());
