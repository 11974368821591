(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name classroom.factory:RecursionHelper
   *
   * @description
   *
   */
  angular
    .module('classroom')
    .factory('RecursionHelper', RecursionHelper);

  function RecursionHelper($compile) {
    return {
      compile: function (element, link) {
        var compiledContents,
          contents = element.contents().remove();

        if (angular.isFunction(link)) {
          link = {post: link};
        }

        return {
          pre: link && link.pre ? link.pre : null,

          /**
           * Compiles and re-adds the contents
           * @override
           */
          post: function (scope, element) {
            // Compile the contents
            if (!compiledContents) {
              compiledContents = $compile(contents);
            }

            // Re-add the compiled contents to the element
            compiledContents(scope, function (clone) {
              element.append(clone);
            });

            // Call the post-linking function, if any
            if (link && link.post) {
              link.post.apply(null, arguments);
            }
          }
        };
      }
    };
  }
}());
