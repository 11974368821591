(function() {
  'use strict';

  angular
    .module('classroom')
    .service('SentimentTesterService', SentimentTesterService);

  function SentimentTesterService(AuthService) {
    var self = {
      checkTest: function(programId) {
        return AuthService.apiService()
          .one('programs', programId)
          .one('sentiment')
          .get();
      },

      sendScore: function(programId, score) {
        return AuthService.apiService()
          .one('programs', programId)
          .post('sentiment', {score: score});
      }
    };

    return self;
  }
}());
