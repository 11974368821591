(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name classroom.service:CurrentProgramPartsService
   *
   * @description
   *
   */
  angular
    .module('classroom')
    .service('CurrentProgramPartsService', CurrentProgramPartsService);

  function CurrentProgramPartsService() {
    var self = this;

    self.get = function () {
      return 'CurrentProgramPartsService';
    };
  }
}());
