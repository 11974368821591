(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name directives.directive:msStickyBottomBanner
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="directives">
       <file name="index.html">
        <ms-sticky-bottom-banner></ms-sticky-bottom-banner>
       </file>
     </example>
   *
   */
  angular
    .module('directives')
    .directive('msStickyBottomBanner', msStickyBottomBanner);

  function msStickyBottomBanner() {
    return {
      restrict: 'E',
      scope: true,
      bindToController: {
        btnFn: '&',
        btnMsg: '@'
      },
      templateUrl: 'directives/ms-sticky-bottom-banner-directive.tpl.html',
      replace: false,
      controllerAs: 'msStickyBottomBannerVm',
      controller: function () {
        var vm = this;
        return vm;
      }
    };
  }
}());
