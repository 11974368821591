/* eslint-disable no-multi-str */
// jshint multistr:true
(function () {
  "use strict";

  angular
    .module("directives")
    .directive("newReflection", newReflection);

  function newReflection(deviceDetector) {
    return {
      restrict: "E",
      templateUrl: "directives/workbooks/new-reflection-directive.tpl.html",
      scope: {
        addToWorkbook: "&",
        mode: "=",
        reflectionPrompt: "="
      },
      bindToController: true,
      controllerAs: "newReflectionVm",
      replace: false,
      controller: function ($document, localStorageService) {
        var vm = this;
        vm.reflection = {body: ""};
        vm.user = null;
        vm.prompt = null;
        vm.taHeight = 36;
        vm.editing = false;
        vm.isMobile = deviceDetector.isMobile();
        vm.showPrompt = vm.reflectionPrompt !== null && localStorageService.get("showPrompt", "sessionStorage") !== false;

        vm.add = add;
        vm.reset = reset;
        vm.hidePrompt = hidePrompt;

        activate();

        return vm;

        // ------------------ Function Declarations

        function activate() {
          switch (vm.mode) {
          case "expert":
            vm.prompt = "Remember when giving feedback to think about all the \
                          professional's projects together and how they are progressing towards \
                          their own goals.";
            vm.placeholder = "Write feedback...";
            break;
          case "admin":
            vm.placeholder = "Write message...";
            break;
          case "you":
            vm.placeholder = "Post to your journal";
            break;
          default:
            vm.placeholder = "Post to your journal";
          }
          vm.currentUser = localStorageService.get("currentUser");
          vm.taHeight = vm.isMobile ? 0 : 36;
        }

        function add() {
          vm.addToWorkbook({newReflection: vm.reflection});
          hidePrompt();
          reset();
        }

        function reset() {
          vm.reflection = {body: ""};
          vm.editing = false;
          vm.taHeight = vm.isMobile ? 0 : 46;
        }

        function hidePrompt() {
          vm.showPrompt = false;
          localStorageService.set("showPrompt", false, "sessionStorage");
        }
      }
    };
  }
}());
