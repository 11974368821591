(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name directives.directive:partThumbTitle
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="directives">
       <file name="index.html">
        <thumb-title part="part-object" title-class="classname"></thumb-title>
       </file>
     </example>
   *
   */
  angular
    .module('directives')
    .directive('thumbTitle', thumbTitle);

  function thumbTitle() {
    return {
      restrict: 'E',
      scope: {
        part: "=",
        titleClass: "@"
      },
      templateUrl: 'directives/building-blocks/thumb-title-directive.tpl.html',
      replace: false,
      controllerAs: 'vmThumbTitle',
      controller: function ($scope) {
        var vm = this;
        vm.name = 'vmThumbTitle';
        vm.part = $scope.part;
        vm.titleClass = $scope.titleClass;
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
