(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name directives.directive:msProjectCollaborations
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="directives">
       <file name="index.html">
        <ms-project-collaborations></ms-project-collaborations>
       </file>
     </example>
   *
   */
  angular
    .module('directives')
    .directive('msProjectCollaborations', msProjectCollaborations);

  function msProjectCollaborations () {
    var directive = {
      restrict: 'EA',
      scope: {
        lessonId: '@'
      },
      templateUrl: 'directives/ms-project-collaborations-directive.tpl.html',
      replace: true,
      bindToController: true,
      controllerAs: 'msProjectCollaborationsVm',
      controller: ['ProjectsService', MsProjectCollaborationsController]
    };

    return directive;
  }

  function MsProjectCollaborationsController (ProjectsService) {
    var vm = this;
    vm.name = 'msProjectCollaborations';

    activate();

    return vm;

    // ------------------

    function activate () {
      ProjectsService.currentUserCollaborations(vm.lessonId).then(function (result) {
        vm.items = result.project_collaborations; /* jshint ignore:line */ // eslint-disable-line camelcase
      });
    }
  }
}());
