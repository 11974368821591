/* eslint-disable camelcase*/
/* jshint camelcase: false */
(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name collaborators.controller:CollaboratorCtrl
   *
   * @description
   *
   */
  angular
    .module('projects')
    .controller('CollaboratorCtrl', CollaboratorCtrl);

  function CollaboratorCtrl(
    CollaboratorsService,
    ProjectsService,
    DisciplinesService,
    localStorageService,
    $timeout,
    $state,
    $stateParams
  ) {
    var vm = this;
    vm.ctrlName = 'CollaboratorCtrl';
    vm.user = localStorageService.get("currentUser");
    vm.itemId = $stateParams.itemId;
    vm.programId = $stateParams.programId;
    vm.partId = $stateParams.partId;
    vm.lessonId = $stateParams.lessonId;
    vm.selectedDiscipline = null;
    vm.disciplineField = null;
    vm.websiteField = null;
    vm.nameField = $stateParams.name;
    vm.disciplines = [];
    vm.customDisc = false;

    $timeout(function () {
      init();
    });

    vm.clearDisciplines = function(discipline) {
      if (discipline !== null) {
        vm.disciplineField = null;
      }
      _.each(vm.disciplines, function(option) {
        if (option !== discipline) {
          option.checked = false;
        }
      });
    };

    vm.createCollaborator = function() {
      var options = {
        name: vm.nameField,
        website: vm.websiteField,
        discipline: vm.disciplineField || vm.selectedDiscipline
      };

      if (vm.nameField) {
        CollaboratorsService
          .addCollaborator(vm.project.id, options)
          .then(function () {
            $state.go('^.tag-collaborators', {
              programId: vm.programId,
              lessonId: vm.lessonId
            });
          });
      }
    };

    vm.saveCollaborator = function(id) {
      var options = {
        id: id,
        website: vm.websiteField,
        discipline: vm.disciplineField || vm.selectedDiscipline
      };

      if (id === 'author') {
        options.id = vm.project.id;
        ProjectsService
        .update(options)
        .then(function () {
          $state.go('^.tag-collaborators', {
            programId: vm.programId,
            lessonId: vm.lessonId
          });
        });
      } else {
        CollaboratorsService
        .updateCollaborator(vm.project.id, options)
        .then(function () {
          $state.go('^.tag-collaborators', {
            programId: vm.programId,
            lessonId: vm.lessonId
          });
        });
      }
    };

    vm.disciplineSelected = function(option) {
      option.checked = true;
      vm.selectedDiscipline = option.name;
      vm.clearDisciplines(option);
    };

    vm.deleteCollaborator = function(collab) {
      CollaboratorsService.deleteCollaborator(vm.project.id, collab.id).then(function () {
        $state.go('^.tag-collaborators', {
          programId: vm.programId,
          lessonId: vm.lessonId
        });
      });
    };

    function init() {
      vm.author = vm.itemId === "author";
      getDisciplines();
      ProjectsService.currentUserProject(vm.lessonId)
        .then(function (res) {
          if (res.project.id) {
            vm.project = res.project;
            vm.lesson = res.project.lesson;
            if (vm.itemId === "author") {
              vm.collaborator = {discipline: vm.project.discipline};
              populateFields();
            } else {
              getCollaborator(vm.itemId);
            }
          }
        });
    }

    function getDisciplines() {
      DisciplinesService
        .fetch()
        .then(function (result) {
          vm.disciplines = _.map(result.disciplines, function(d) {
            return {name: d, checked: false};
          });
        });
    }

    function populateFields() {
      vm.customDisc = true;
      vm.selectedDiscipline = vm.collaborator.discipline;
      vm.websiteField = vm.collaborator.profile_url;

      _.each(vm.disciplines, function(option) {
        if (option.name === vm.selectedDiscipline) {
          option.checked = true;
          vm.customDisc = false;
        }
      });
      if (vm.customDisc) {
        vm.disciplineField = vm.selectedDiscipline;
      }
    }

    function getCollaborator(itemId) {
      if (itemId) {
        CollaboratorsService
          .getCollaborator(vm.project.id, itemId)
          .then(function (result) {
            vm.collaborator = result.project_collaborator/* jshint ignore:line *//* eslint-disable-line camelcase */;
            populateFields();
          });
      }
    }
  }
}());
