(function () {
  'use strict';
  angular
    .module('directives')
    .directive('msAnnouncements', msAnnouncements);

  function msAnnouncements () {
    var directive = {
      restrict: 'EA',
      templateUrl: 'directives/ms-announcements-directive.tpl.html',
      replace: true,
      bindToController: true,
      controllerAs: 'msAnnouncementsVm',
      controller: ['NotificationsService', '$log', MsAnnouncements]
    };

    return directive;
  }

  function MsAnnouncements (NotificationsService, $log) {
    var vm = this;
    vm.name = 'MsAnnouncements';
    vm.items = [];

    vm.clear = function(id) {
      NotificationsService.clear(id).then(function() {
        vm.items = _.without(vm.items, _.findWhere(vm.items, {
          id: id
        }));
        return;
      }, function(error) {
        $log.error(error);
        return error;
      });
    };

    activate();

    return vm;

    // ------------------

    function activate () {
      NotificationsService.getList().then(function(resp) {
        vm.items = resp.announcements;
        return;
      }, function(error) {
        $log.error(error);
        return error;
      });
    }
  }
}());
