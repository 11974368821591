(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name directives.directive:collaboratorItem
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="directives">
       <file name="index.html">
        <collaborator-item class="collaborative-item"></collaborator-item>
       </file>
     </example>
   *
   */
  angular
    .module('directives')
    .directive('collaboratorItem', collaboratorItem);

  function collaboratorItem() {
    return {
      restrict: 'E',
      scope: {
        userProfileUrl: '=',
        userAvatarUrl: '=',
        userFullname: '=',
        userDiscipline: '=',
        userTitle: '=',
        userCourse: '='
      },
      bindToController: true,
      templateUrl: 'directives/collaborator-item-directive.tpl.html',
      replace: false,
      controllerAs: 'collaboratorItemVm',
      controller: function () {
        var vm = this;
        vm.name = "collaboratorItemVm";
      },
      link: function (scope, element, attrs, vm) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
