(function () {
  'use strict';

  angular
    .module('projects')
    .directive('projectItemImage', projectItemImage);

  function projectItemImage() {
    return {
      restrict: 'EA',
      scope: true,
      templateUrl: 'directives/projects/items/project-item-image-directive.tpl.html',
      replace: false
    };
  }
}());
