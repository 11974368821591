<a href
   ng-click="vmCoursePartLink.checkPartDetails()"
   ng-class="{'disabled': !vmCoursePartLink.part.active}"
   ng-cloak
   tabindex="0"
   role="button">
  <img class="img part-thumb" ng-src="images/transparent_image.png"
       ng-style="{'background-image': 'url(' + vmCoursePartLink.part.medium_image_url + '), linear-gradient(to bottom, #d7d7d7 0%,#a7a7a7 100%)'}">
  <thumb-title part="vmCoursePartLink.part" title-class="thumb-detail-grid"></thumb-title>
</a>
