<div class="show-goal">
  <div class="col-xs-1">
    <span class="goals-directive__position circle">{{goal.position}}</span>
  </div>
  <div class="col-xs-11 goals-directive__item">
    <div ng-bind-html="goal.html"
         ms-expandable
         show-char="100"></div>
  </div>
</div>
