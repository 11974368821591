<div class="ms-announcements">
  <div ng-repeat='item in msAnnouncementsVm.items track by $index' ng-init="toggled=false">
    <div class='announcement'>
      <a class="announcement__title" ng-click="toggled=!toggled" href>
        <div class="container-fluid">
          <div class="row">
            <div class="col-xs-11">{{ item.title }}</div>
            <div class="announcement__title__angle col-xs-1 text-right">
              <i class="fa fa-lg" ng-class="{ 'fa-angle-up':toggled, 'fa-angle-down': !toggled }"></i>
            </div>
          </div>
        </div>
      </a>
      <div class="announcement__body" ng-show="toggled">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xs-12" ng-bind-html="item.html">
            </div>
            <div class='col-xs-3 col-xs-offset-9 col-sm-2 col-sm-offset-10'>
              <button class='announcement__body__gotit btn btn-default text-centered col-xs-12'
                      ng-click="msAnnouncementsVm.clear(item.id); $event.stopPropagation()">
                <ms-icon icontype="'tick'"></ms-icon>
                <span>Got it</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
