(function () {
  "use strict";

  angular
    .module("briefs")
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state("briefs", {
        url: "/briefs/:partId",
        templateUrl: "main.html",
        resolve: {
          // This is needed when there is no local storage and the user goes directly to the briefs page from a link
          CurrentUser: "CurrentUser",
          currentUser: function (CurrentUser) {
            return CurrentUser.storeUser();
          }
        },
        controller: "BriefsCtrl",
        controllerAs: "briefsVm"
      })

      .state("briefOutline", {
        parent: "briefs",
        url: "/outline",
        templateUrl: "course-outline/briefs-outline/briefs-outline.tpl.html",
        controller: "BriefsOutlineCtrl",
        controllerAs: "briefsOutlineVm",
        params: {reloadPage: false}
      })

      .state("briefs.lesson", {
        parent: "briefs",
        url: "/lessons/:lessonId",
        templateUrl: "lessons/lesson.tpl.html",
        controller: "LessonCtrl",
        controllerAs: "lessonVm"
      })

      // part menu related routes

      .state("briefs.projects", {
        parent: "briefs",
        url: "/lessons/:lessonId/projects?page",
        templateUrl: "projects/projects.tpl.html",
        controller: "ProjectsCtrl",
        controllerAs: "projectsVm"
      })
      .state("briefs.project", {
        parent: "briefs",
        url: "/lessons/:lessonId/projects/:projectId",
        templateUrl: "projects/project.tpl.html",
        controller: "ProjectCtrl",
        controllerAs: "projectVm"
      })
      .state("briefs.project_edit", {
        parent: "briefs",
        url: "/lessons/:lessonId/edit-project",
        templateUrl: "projects/project.tpl.html",
        controller: "ProjectCtrl",
        controllerAs: "projectVm"
      })
      .state("briefs.edit-collaborator", {
        parent: "briefs",
        url: "/lessons/:lessonId/edit-collaborator/:itemId",
        templateUrl: "projects/edit-collaborator.tpl.html",
        controller: "CollaboratorCtrl",
        controllerAs: "collabVm"
      })
      .state("briefs.new-collaborator", {
        parent: "briefs",
        url: "/lessons/:lessonId/edit-project/new-collaborator?name",
        templateUrl: "projects/new-collaborator.tpl.html",
        controller: "CollaboratorCtrl",
        controllerAs: "collabVm"
      })
      .state("briefs.tag-collaborators", {
        url: "/lessons/:lessonId/edit-project/tag-collaborators",
        templateUrl: "projects/tag-collaborators.tpl.html",
        controller: "ProjectCtrl",
        controllerAs: "projectVm",
        params: {reloadPage: false}
      })
      .state("briefs.project_item_edit", {
        parent: "briefs",
        url: "/lessons/:lessonId/edit-project/:itemId",
        templateUrl: "projects/project-item-edit.tpl.html",
        controller: "ProjectItemEditCtrl",
        controllerAs: "itemEditVm"
      });
  }
}());
