(function() {
  'use strict';

  angular
    .module('workbook')
    .directive('msFallbackSrc', msFallbackSrc);

  function msFallbackSrc() {
    var directive = {
      link: function postLink(scope, iElement, iAttrs) {
        if (_.isEmpty(iAttrs.ngSrc)) {
          iElement.attr('src', iAttrs.msFallbackSrc);
        }
        iElement.bind('error', function() {
          angular.element(this).attr("src", iAttrs.msFallbackSrc);
        });
      }
    };
    return directive;
  }
} ());
