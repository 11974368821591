(function () {
  'use strict';

  angular
      .module('classroom')
      .service('WorkbookService', WorkbookService);

  function WorkbookService(AuthService) {
    var self = {
      get: function(id) {
        return AuthService.apiService()
          .one('workbooks', id)
          .get();
      }
    };

    return self;
  }
}());
