(function () {
  "use strict";

  /**
   * @ngdoc service
   * @name sessions.service:AuthService
   *
   * @description
   *
   */
  angular
    .module("classroom")
    .factory("AuthService", AuthService);

  function AuthService(envService, localStorageService, Restangular) {
    var apiUrl = envService.read("apiUrl"),
      authService = {
        apiService: apiService
      };

    return authService;

    function apiService(config) {
      config = config || {};

      return Restangular.withConfig(function (RestangularConfigurer) {

        // Set the service base url
        RestangularConfigurer.setBaseUrl(apiUrl);

        // Set authentication
        if (config.auth !== false && localStorageService.get("email")) {
          var authEmail = localStorageService.get("email"),
              authToken = localStorageService.get("token");

          RestangularConfigurer.setDefaultHeaders({
            "X-User-Email": authEmail,
            "X-User-Token": authToken
          });
        }

//        RestangularConfigurer.setErrorInterceptor(function (response, deferred, responseHandler) {
//          if (response.status === 422) {
//            $rootScope.addAlert({type: "danger", msg: $translate.instant("conditions.alerts.error")});
//          }
//
//          return true;
//        });

      });

    }
  }
}());
