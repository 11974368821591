(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name classroom.service:NotificationsService
   *
   * @description
   *
   */
  angular
      .module('classroom')
      .service('NotificationsService', NotificationsService);

  function NotificationsService(AuthService) {
    var self = {
        count: function() {
          return AuthService.apiService()
            .one('notifications', 'count')
            .get();
        },

        getList: function() {
          return AuthService.apiService()
            .one('notifications')
            .get();
        },

        clear: function(id) {
          return AuthService.apiService()
            .one('notifications', id)
            .one('clear')
            .get();
        }
      };

    return self;
  }
}());
