<div class="in-session-directive" ng-if="inSessionVm.openPartList.length>0">
  <h4>In session</h4>

  <div class="open-partlist-flex-cnt">
    <a class="open-part-flex-itm" ng-repeat="part in inSessionVm.openPartList"
       ui-sref="part.lesson({lessonId: part.latest_lesson_id, partId: part.id})"
       ng-if="!(part.isBrief && inSessionVm.hideDirBriefsAndSupport())">
      <div class="part thumbnail-wrapper">
        <img ng-src="{{part.small_image_url}}" class="thumbnail-img">
      </div>
      <div class="part part-fcontainer">
        <div class="part-fitem mod-no" ng-if="part.position > 0">Module {{part.position}}</div>
        <div class="part-fitem mod-name">{{part.name}}</div>
      </div>
    </a>
  </div>
</div>
