(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name directives.directive:lessonMenu
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="directives">
       <file name="index.html">
        <lesson-menu></lesson-menu>
       </file>
     </example>
   *
   */
  angular
    .module('directives')
    .directive('lessonMenu', lessonMenu);

  function lessonMenu() {
    var directive = {
      restrict: 'EA',
      scope: {
        taskEnabled: "=",
        lessons: '='
      },
      bindToController: true,
      templateUrl: 'directives/menus/lesson-menu-directive.tpl.html',
      replace: false,
      controllerAs: 'lessonMenuVm',
      controller: LessonMenuController
    };

    return directive;
  }

  LessonMenuController.$inject = ["localStorageService"];
  function LessonMenuController(localStorageService) {
    var vm = this;
    vm.name = 'lessonMenu';
    vm.currentProgram = localStorageService.get("currentProgram");
    vm.programYear = new Date(vm.currentProgram.end_date).getFullYear();
    vm.collaborationBtn = null;

    activate();

    return vm;

    // ------------------

    function activate() {
      vm.task = _.find(vm.lessons, {task: true});
      vm.shortBriefs = _.find(vm.lessons, {networkMaterial: true});

      if (vm.task) {
        vm.collaborationBtn = vm.task.collaboration_tool_btn/* jshint ignore:line */;
      } else if (vm.shortBriefs) {
        vm.collaborationBtn = vm.shortBriefs.collaboration_tool_btn/* jshint ignore:line */;
      }
    }
  }
}());
