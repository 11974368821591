(function () {
  "use strict";

  /**
   * @ngdoc object
   * @name sessions.controller:SignInCtrl
   *
   * @description
   *
   */
  angular
    .module("sessions")
    .controller("SignInCtrl", SignInCtrl);

  function SignInCtrl($state, CurrentUser, localStorageService, SessionsService) {
    var vm = this;
    vm.ctrlName = "SignInCtrl";
    vm.user = {};

    vm.authenticatedUser = SessionsService.authenticatedUser();
    vm.login = login;
    vm.logout = logout;

    function login() {
      SessionsService.login(vm.user).then(function (res) {
        localStorageService.set("email", res.data.email);
        localStorageService.set("token", res.data.token);
        CurrentUser.storeUser();
        $state.go("workbooks_home");
      });
    }

    function logout() {
      SessionsService.logout();
    }
  }
}());
