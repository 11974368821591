(function () {
  "use strict";

  /**
   * @ngdoc service
   * @name classroom.service:Enrollees
   *
   * @description
   *
   */
  angular
    .module("classroom")
    .service("EnrolleesService", EnrolleesService);

  function EnrolleesService(AuthService, $q) {
    var abortGet,
      self = {
        get: function (programId, search) {
          var endpoint = angular.isDefined(programId) ? "programs/" + programId + "/enrollees" : "enrollees";

          if (abortGet) abortGet.resolve();
          abortGet = $q.defer();

          return AuthService.apiService()
            .one(endpoint)
            .withHttpConfig({timeout: abortGet.promise})
            .get({search: search});
        },

        findEnrollees: function (search, limit) {
          var endpoint;
          limit = limit || 50;
          endpoint = "enrollees?search=" + search + "&limit=" + limit;

          if (abortGet) abortGet.resolve();
          abortGet = $q.defer();

          return AuthService.apiService()
            .one(endpoint)
            .withHttpConfig({timeout: abortGet.promise})
            .get();
        }
      };

    return self;
  }
}());
