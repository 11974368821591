<div class="user-links-directive">
  <h4> {{msUserLinksVm.user.name}}</h4>
  <div class="social-links-fcontainer">
    <a href="{{msUserLinksVm.user.profile_url}}" target="_blank" class="social-link-fitem">
      <img class="avatar-img-circle" ng-src="{{msUserLinksVm.user.avatar_url}}">
    </a>
    <div class="social-link-fitem" ng-repeat="(platform, link) in msUserLinksVm.user.social_links">
      <a href="{{link}}" target="_blank">
        <i class="fa fa-{{platform}}"></i>
      </a>
    </div>
  </div>
  <div class="enrolled-on">
    <strong>Accelerator:</strong>
    <a ui-sref="courseOutline({ programId:  msUserLinksVm.programId})" target="_blank">{{msUserLinksVm.programTitle}}</a>
    <br>
    <strong>Email:</strong>
    <a ng-href="mailto:{{msUserLinksVm.user.email}}">{{msUserLinksVm.user.email}}</a>
  </div>
  <div class="divider-horizontal"></div>
</div>
