<div class="journal-directive">
  <h4>{{workbookVm.header}}</h4>

  <div class="journal-flex-container workbook-block">
    <div class="journal-flex-item workbook-entry" ng-if="journalVm.workbook.allow_create_reflection">
      <new-reflection id="new-reflection"
                      add-to-workbook="workbookVm.addReflection(newReflection)"
                      mode="journalVm.workbook.mode"
                      reflection-prompt="workbookVm.workbook.reflection_reminder_prompt">
      </new-reflection>
    </div>

    <div ng-repeat="tuple in journalVm.workbook.tuples"
         class="journal-flex-item workbook-entry tuple">
      <edit-reflection ng-if="tuple.klass_name === 'Reflection' && tuple.allow_show"
                       reflection="tuple"
                       remove-from-workbook="workbookVm.removeReflection(tuple)"
                       update-workbook="workbookVm.updateReflection(tuple)"
                       id="tuple-{{tuple.id}}">
      </edit-reflection>

      <task-and-project
        ng-if="(tuple.klass_name === 'TaskAndProject' || tuple.klass_name === 'TaggedProject') && (tuple.project.status === 'complete' || tuple.project.status === 'started') "
        tuple="tuple"
        mode="journalVm.workbook.mode"
        owner="journalVm.workbook.owner">
      </task-and-project>
    </div>
  </div>
</div>
