(function () {
  "use strict";

  angular
    .module("classroom")
    .service("GoalsService", GoalsService);

  function GoalsService(AuthService) {
    var self = {
      agent: function (workbookId) {
        return AuthService.apiService()
          .one("workbooks", workbookId);
      },

      get: function (workbookId) {
        return self.agent(workbookId)
          .one("goals")
          .get();
      },
      post: function (workbookId, goal) {
        return self.agent(workbookId)
          .all("goals")
          .post(goal);
      },
      put: function (workbookId, goal) {
        return self.agent(workbookId)
          .one("goals", goal.id)
          .customPUT({goal: goal});
      },
      remove: function (workbookId, goalId) {
        return self.agent(workbookId)
          .one("goals", goalId)
          .remove();
      }
    };

    return self;
  }
}());
