(function () {
  "use strict";

  angular
    .module("programs")
    .controller("BookingCtrl", BookingCtrl);

  function BookingCtrl(bookingData, $window, $sce, $timeout, usSpinnerService, OnboardingFactory) {
    var vm = this;

    vm.ctrlName = "BookingCtrl";
    vm.bookingData = bookingData;

    // redirect when no data about booking
    if (vm.bookingData.ycbmId === null) {
      $window.location = "/404";
      return;
    }

    $timeout(function () {
      var iframe = angular.element("#ycbmiframe-" + bookingData.ycbmId);

      usSpinnerService.spin("spinner-1");

      $window.addEventListener("message", function (ev) {
        if (ev.origin === "https://" + vm.bookingData.ycbmId + ".youcanbook.me") {
          iframe.css("height", ev.data + "px");
        }
      });

      iframe.on("load", function () {
        usSpinnerService.stop("spinner-1");
      });
    }, 0);

    vm.bookingData.ycbmUrl = $sce.trustAsResourceUrl(vm.bookingData.ycbmUrl);

    vm.continue = function () {
      if (vm.bookingData.route === "booking-feedback") {
        OnboardingFactory.skipBookingFeedback();
      } else {
        OnboardingFactory.skipBookingCoach();
      }
    };
    vm.part = OnboardingFactory.part;

    return vm;
  }
}());
