(function () {
  "use strict";

  /**
   * @ngdoc filter
   * @name filters.filter:ordinals
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module("filters")
    .filter("ordinals", ordinals);

  function ordinals() {
    var lastDigit;
    return function (number) {
      if (isNaN(number) || number < 1) {
        return number;
      }
      lastDigit = number % 10;
      if (lastDigit === 1) {
        return number + "st";
      } else if (lastDigit === 2) {
        return number + "nd";
      } else if (lastDigit === 3) {
        return number + "rd";
      } else if (lastDigit > 3) {
        return number + "th";
      }
    };
  }
}());
