<div class="module-menu">
  <div class="modules-list">
    <div class="modules-list-item" ng-repeat="module in moduleMenuVm.modules track by $index">
      <h5 ng-if="$index > 0">{{module.title}}</h5>
      <div lesson-menu
           lessons="module.lessons"
           task-enabled="moduleMenuVm.part.task_enabled">
      </div>
    </div>
  </div>
</div>
