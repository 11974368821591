<div class="form-group img-upload-form" ng-if="!vm.item.id" nv-file-drop="" uploader="vm.imgUploader" filters="imageFilter, imgSize">
  <p>My test</p>

  <div class="well img-drop-zone" nv-file-over="" uploader="vm.imgUploader">
    Base drop zone
  </div>


  <table class="table">
    <thead>
    <tr>
      <th width="50%">Name</th>
      <th ng-show="vm.imgUploader.isHTML5">Size</th>
      <th ng-show="vm.imgUploader.isHTML5">Progress</th>
      <th>Status</th>
      <th>Actions</th>
    </tr>
    </thead>
    <tbody>
    <tr ng-repeat="item in vm.imgUploader.queue" required>

      {{item}}

      <td>
        <strong>{{ item.file.name }}</strong>

        <div ng-show="vm.imgUploader.isHTML5" project-img-thumb="{ file: item._file, height: 100 }"></div>
        <!--<div ng-show="vm.imgUploader.isHTML5" project-img-thumb="{ file: item.file, height: 100 }"></div>-->
        <!--fixed width and height -->
        <!--<div ng-thumb="{ file: item.file, width: 100, height: 100 }"></div>-->
      </td>
      <td ng-show="vm.imgUploader.isHTML5" nowrap>{{ item.file.size/1024/1024|number:2 }} MB</td>
      <td ng-show="vm.imgUploader.isHTML5">
        <div class="progress" style="margin-bottom: 0;">
          <div class="progress-bar" role="progressbar" ng-style="{ 'width': item.progress + '%' }"></div>
        </div>
      </td>
      <td class="text-center">
        <span ng-show="item.isSuccess"><i class="glyphicon glyphicon-ok"></i></span>
        <span ng-show="item.isCancel"><i class="glyphicon glyphicon-ban-circle"></i></span>
        <span ng-show="item.isError"><i class="glyphicon glyphicon-remove"></i></span>
      </td>
      <td nowrap>
        <button type="button" class="btn btn-success btn-xs" ng-click="item.upload()" ng-disabled="item.isReady || item.isUploading || item.isSuccess">
          <span class="glyphicon glyphicon-upload"></span> Upload
        </button>
        <button type="button" class="btn btn-warning btn-xs" ng-click="item.cancel()" ng-disabled="!item.isUploading">
          <span class="glyphicon glyphicon-ban-circle"></span> Cancel
        </button>
        <button type="button" class="btn btn-danger btn-xs" ng-click="item.remove()">
          <span class="glyphicon glyphicon-trash"></span> Remove
        </button>
      </td>
    </tr>
    </tbody>
  </table>
  <div>
    <div>
      Queue progress:
      <div class="progress" style="">
        <div class="progress-bar" role="progressbar" ng-style="{ 'width': vm.imgUploader.progress + '%' }"></div>
      </div>
    </div>
    <button type="button" class="btn btn-success btn-s" ng-click="vm.imgUploader.uploadAll()" ng-disabled="!vm.imgUploader.getNotUploadedItems().length">
      <span class="glyphicon glyphicon-upload"></span> Upload all
    </button>
    <button type="button" class="btn btn-warning btn-s" ng-click="vm.imgUploader.cancelAll()" ng-disabled="!vm.imgUploader.isUploading">
      <span class="glyphicon glyphicon-ban-circle"></span> Cancel all
    </button>
    <button type="button" class="btn btn-danger btn-s" ng-click="vm.imgUploader.clearQueue()" ng-disabled="!vm.imgUploader.queue.length">
      <span class="glyphicon glyphicon-trash"></span> Remove all
    </button>
  </div>
  <p>end of my test</p>

  <label>Add an image</label>
  <p class="help-block">Up to 3 MB, JPG, PNG or GIF files.</p>
  <input ng-if="vm.os !== 'ios'" type="file" ngf-select ng-model="vm.item.data" class="form-control" name="pic"
         accept="image/*" ngf-max-size="50MB" ngf-pattern=".jpg,.png,.gif" ngf-model-invalid="errorFiles" ngf-pattern-err-type="filetype" disable-validation-message="">
  </input>
  <input ng-if="vm.os === 'ios'" type="file" ngf-select ng-model="vm.item.data" class="form-control" name="pic"
         accept="image/*" ngf-max-size="50MB" ngf-model-invalid="errorFiles" ngf-pattern-err-type="filetype" disable-validation-message="">
  </input>
  <i class="file-error" ng-show="imgForm.pic.$error.maxSize">Please select a file under 50MB.</i>
  <i class="file-error" ng-show="imgForm.pic.$error.pattern">Please select a supported file type.</i>
</div>
<div class="form-group">
  <label>Add a caption</label>
  <p class="help-block">Optional</p>
  <textarea ng-model="vm.item.caption" class="form-control"></textarea>
  <br/>
  <input type="checkbox" ng-model="vm.item.cover">
  <span>Set as cover image</span>
</div>
