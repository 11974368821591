<div class="goals-directive"
     ng-class="{'goals-directive__goals-grey-bg': goalsVm.noGoals}">

  <h4>
    {{goalsVm.header}} 
  </h4>

  <!-- when goals is locked -->

  <div ng-if="goalsVm.permissions.blocked">
    <div class="goals--add-goals font-small-pale row">
      <div class="goal-message">
        {{ goalsVm.msgBlocked }}
      </div>
    </div>
  </div>

  <!-- when goals are unlocked -->

  <div ng-if="goalsVm.permissions.blocked===false">

    <!-- when golas are NOT editable  -->
    <div class="row" ng-if="goalsVm.permissions.edit===false">
      <div ng-repeat="goal in goalsVm.goals">
        <show-goal goal="goal"></show-goal>
      </div>
    </div>

    <!-- when goals are editable  -->
    <div class="row" ng-if="goalsVm.permissions.edit">
      <div ng-repeat="goal in goalsVm.goals">
        <edit-goal goal="goal"
                   update="goalsVm.updateGoal"
                   remove="goalsVm.removeGoal(goal)"></new-goal>
      </div>
      <new-goal ng-hide="goalsVm.reachedTheLimit()"
                position="{{(goalsVm.goals.length + 1) || 1}}"
                add="goalsVm.addGoal"></new-goal>
    </div>

  </div>

</div>
