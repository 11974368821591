(function () {
  "use strict";

  /**
   * @ngdoc directive
   * @name directives.menus.directive:mobileCourseMenu
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="directives.menus">
   <file name="index.html">
   <mobile-course-menu></mobile-course-menu>
   </file>
   </example>
   *
   */
  angular
    .module("directives.menus")
    .directive("mobileCourseMenu", mobileCourseMenu);

  function mobileCourseMenu() {
    return {
      restrict: "E",
      scope: true,
      bindToController: {partId: "@"},
      templateUrl: "directives/menus/mobile-course-menu-directive.tpl.html",
      replace: false,
      controllerAs: "mobileCourseMenuVm",
      controller: function ($rootScope, $scope, $state, $timeout, CurrentPartService, localStorageService) {
        var vm = this,
          lessonVm = $scope.lessonVm;/* jshint ignore:line */ // eslint-disable-line no-unused-vars
        vm.hideMenu = hideMenu;
        vm.numpos = 4;
        vm.rotate = false;

        $timeout(function () {
          activate();
        });

        return vm;

        // ------------------ Function declarations ------------------

        function hideMenu() {
          return $state.current.name !== "part.lesson" && $state.current.name !== "briefs.lesson";
        }

        function activate() {
          vm.part = localStorageService.get("currentPart");
        }
      }
    };
  }
}());
