(function () {
  'use strict';

  /* @ngdoc object
   * @name directives
   * @description
   *
   */
  angular
    .module('directives', [
      'ui.router',
      'directives.menus',
      'directives.comments'
    ]);
}());
