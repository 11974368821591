(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name directives.directive:comment
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="directives">
   <file name="index.html">
   <comment></comment>
   </file>
   </example>
   *
   */
  angular
    .module('directives')
    .directive('comment', comment);

  function comment(RecursionHelper, $timeout) {
    var directive = {
      restrict: 'EA',
      scope: {
        entity: '=',
        rootCommentableType: '=',
        programId: '@'
      },
      replace: false,
      templateUrl: 'directives/comments/comment-directive.tpl.html',
      controllerAs: 'commentVm',
      controller: ['$scope', '$element', '$location', '$compile',
        'CommentsService', '$log', '$window', CommentController],
      compile: function(element) {
        function link(scope, element, attrs, ctrl) {
          $timeout(function() {
            if (angular.isDefined(ctrl.pointedCommentId) &&
              'comment-' + ctrl.pointedCommentId === attrs.id) {
              angular.element('html, body').animate({
                scrollTop: angular.element(element).offset().top + 'px'
              }, 'slow', function() {
                angular.element(element).find('.row').first().addClass('flash');
              });
            }
          });
        }
        return RecursionHelper.compile(element, link);
      }
    };

    return directive;
  }

  function CommentController($scope,
                             $element,
                             $location,
                             $compile,
                             CommentsService,
                             $log,
                             $window) {
    var vm = this;
    vm.programId = $scope.programId;
    vm.pointedCommentId = $location.search().comment;

    vm.addNewReply = function (newComment) {
      var thisComment = angular.element($element[0].querySelector('.comment__replies')),
        newReply = $compile(
          "<comment " +
          "class='comment'" +
          "entity='" + angular.toJson(newComment, false) + "'" +
          "program-id='" + vm.programId +
          "'>" +
          "</comment>"
        )($scope);

      thisComment.append(newReply);
    };

    vm.addNewCommentForm = function () {
      var newCommentWrapper = angular.element($element[0].querySelector('.comment__new')),
        newCommentElement = $compile(
          "<new-comment " +
          "class='new-comment' " +
          "commentable-id=\"" + $scope.entity.id + "\" " +
          "commentable-type=\"'" + commentableTypeForResponse() + "'\"> " +
          "</new-comment>"
        )($scope);

      newCommentWrapper.append(newCommentElement);
    };

    vm.remove = function() {
      if ($window.confirm("Are you sure you want to delete this comment?")) {
        CommentsService.remove($scope.entity.id).then(function(result) {
          $scope.entity = result.comment;
          return;
        }, function(error) {
          $log.error(error);
          return error;
        });
      }
    };

    vm.restore = function() {
      CommentsService.restore($scope.entity.id).then(function(result) {
        $scope.entity = result.comment;
        return;
      }, function(error) {
        $log.error(error);
        return error;
      });
    };

    function isRoot() {
      return angular.isDefined(vm.rootCommentableType);
    }

    function commentableTypeForResponse() {
      if (isRoot()) {
        return vm.rootCommentableType;
      }
      return 'Comment';
    }
  }
}());
