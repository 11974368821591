(function () {
  "use strict";

  /**
   * @ngdoc filter
   * @name filters.filter:msTruncate
   *
   * @description
   * Filter to truncate text at [truncate_at] characters and end with [end_string]
   * Usage: {{ text_to_truncate | msTruncate:24:"" }}
   * The above will truncate the text to 24 chars with nothing appended at the end
   * If the second param is not passed it defaults to "..."
   *
   * @param {Array} text The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module("filters")
    .filter("msTruncate", msTruncate);

  function msTruncate() {
    return function (text, truncate_at, end_string) {
      if (angular.isDefined(text) && text !== null) {
        if (isNaN(truncate_at)) {
          truncate_at = 20;
        }

        if (angular.isUndefined(end_string)) {
          end_string = "...";
        }

        if (text.length <= truncate_at || text.length - end_string.length <= truncate_at) {
          return text;
        }
        return String(text).substring(0, truncate_at - end_string.length) + end_string;
      }
    };
  }
}());
