(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name directives.directive:betaBadge
   * @restrict EA
   * @element
   *
   *
   */
  angular
    .module('directives')
    .directive('betaBadge', betaBadge);

  function betaBadge() {
    return {
      restrict: 'EA',
      templateUrl: 'directives/beta-badge-directive.tpl.html',
      replace: false
    };
  }
}());
