(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name directives.directive:projectItemVideo
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="directives">
       <file name="index.html">
        <project-item-video></project-item-video>
       </file>
     </example>
   *
   */
  angular
    .module('directives')
    .directive('projectItemVideo', projectItemVideo);

  function projectItemVideo() {
    return {
      restrict: 'EA',
      scope: true,
      templateUrl: 'directives/projects/items/project-item-video-directive.tpl.html',
      replace: false,
      bindToController: true,
      controllerAs: 'videoItemVm',
      controller: function () {
        var vm = this;
        vm.name = 'videoItemVm';
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
