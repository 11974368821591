<div class="course-outline-page">
  <div class="container-fluid">
    <span us-spinner="{top: '50vh', left: '50%', radius: 40, width: 10}" spinner-on="loading"></span>
    <part-grid-item
      ng-if="!loading"
      container-class="course-outline-container"
      collection="courseOutlineVm.currentParts"
      current-part-id="courseOutlineVm.currentPartId"
      current-program-active="courseOutlineVm.currentProgram.active">
    </part-grid-item>
  </div>
</div>
