(function () {
  'use strict';

  angular
    .module('directives')
    .directive('showGoal', showGoal);

  function showGoal() {
    var directive = {
      restrict: 'E',
      scope: {goal: "="},
      templateUrl: 'directives/workbooks/show-goal-directive.tpl.html',
      replace: true
    };

    return directive;
  }
}());
