<div class="ms-flex-container" ng-class="vmThumbTitle.titleClass">
  <round-number-flex position="vmThumbTitle.part.position" ng-if="vmThumbTitle.part.position > 0"></round-number-flex>
  <div class="thumb-name-holder ms-flex-item">
    <div class="thumb-name">
      {{vmThumbTitle.part.name}}
    </div>
    <div class="thumb-date" ng-if="vmThumbTitle.part.start_date">{{vmThumbTitle.part.start_date | formatStartDate:true}}</div>
  </div>
  <div ms-icon ng-if="!vmThumbTitle.part.active" class="" icontype="'lock'"></div>
</div>
