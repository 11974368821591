(function () {
  'use strict';

  angular
    .module('sessions')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('sessions', {
        abstract: true,
        templateUrl: 'main.html'
      })
      .state('sign-in', {
        parent: "sessions",
        url: '/sign-in',
        templateUrl: 'sessions/sign-in.tpl.html',
        controller: 'SignInCtrl',
        controllerAs: 'signInVm'
      });
  }
}());
