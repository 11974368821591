(function () {
  'use strict';

  angular
    .module('classroom')
    .service('ReflectionsService', ReflectionsService);

  function ReflectionsService(AuthService) {
    var self = {
        agent: function (workbookId) {
          return AuthService.apiService()
            .one('workbooks', workbookId);
        },
        post: function (workbookId, reflection) {
          return self.agent(workbookId)
            .all('reflections')
            .post(reflection);
        },
        put: function (workbookId, reflection) {
          return self.agent(workbookId)
            .one('reflections', reflection.id)
            .customPUT(reflection);
        },
        remove: function (workbookId, reflectionId) {
          return self.agent(workbookId)
            .one('reflections', reflectionId)
            .remove();
        }
      };
    return self;
  }
}());
