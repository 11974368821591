<div class='ms-part-caption'>
  <div class='container-fluid'>
    <div class="ms-flex-container">
      <round-number-flex position="partCaptionVm.part.position"></round-number-flex>
      <div class="ms-flex-item ms-part-caption__title">
        {{partCaptionVm.part.title}}
      </div>
      <div ng-if="partCaptionVm.buttonAction"
           class="ms-flex-item ms-part-caption__continue">
        <button ng-click="partCaptionVm.buttonAction()" class="btn btn-red btn-thin text-centered">Close</button>
      </div>
    </div>
  </div>
</div>
