(function () {
  'use strict';

  angular
    .module('programs')
    .controller('ProgramCtrl', ProgramCtrl);

  function ProgramCtrl() {
    var vm = this;
    vm.ctrlName = 'ProgramCtrl';
  }
}());
