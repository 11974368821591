(function () {
  "use strict";

  /**
   * @ngdoc object
   * @name lessons.controller:LessonCtrl
   *
   * @description
   *
   */

  angular
    .module("lessons")
    .controller("LessonCtrl", LessonCtrl);

  function LessonCtrl($state, $stateParams, $timeout,
                      $log, deviceDetector, CurrentProgram,
                      LessonsService, localStorageService,
                      OnboardingFactory) {
    var vm = this;
    vm.ctrlName = "LessonCtrl";
    vm.partId = parseInt($stateParams.partId, 10);
    vm.lessonId = parseInt($stateParams.lessonId, 10);
    vm.lesson = null;
    vm.nextLesson = null; // temporary disabled
    vm.user = null;
    vm.isMobile = deviceDetector.isMobile();

    activate();

    return vm;

    // ---------------

    function activate() {
      var storedPart = localStorageService.get("currentPart"),
        waitForNewPart = true;
      // needed to show edit pencil for admins
      vm.user = localStorageService.get("currentUser");

      if (localStorageService.get("currentPart") && localStorageService.get("currentPart").id === vm.partId) {
        vm.part = localStorageService.get("currentPart");
      }

      $timeout(function () {
        LessonsService.get(vm.lessonId).then(function (result) {
          if ($state.toParams.partId !== $state.fromParams.partId ||
              !$state.toParams.programId ||
              !storedPart ||
              storedPart.id !== vm.partId) {
            CurrentProgram.getCurrentPart(vm.partId).then(function (res) {
              localStorageService.set("currentPart", res.program_part);
              vm.part = localStorageService.get("currentPart");
              OnboardingFactory.ifBoardingThenGo(vm.part);
            });
          } else {
            waitForNewPart = false;
          }
          vm.lesson = result.lesson;
          localStorageService.set("currentLessonId", vm.lesson.id);
          if (!waitForNewPart) OnboardingFactory.ifBoardingThenGo(vm.part);
        }, function errorCallback(error) {
          $log.error(error);
        });
      });
    }
  }
}());
