/* eslint-disable max-params */
(function () {
  "use strict";

  /**
   * @ngdoc directive
   * @name directives.menus.directive:topMenu
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="directives.menus">
   <file name="index.html">
   <top-menu></top-menu>
   </file>
   </example>
   *
   */
  angular
    .module("directives.menus")
    .directive("topMenu", topMenu);

  function topMenu() {
    return {
      restrict: "EA",
      scope: {
        userId: "=",
        userAvatar: "=",
        programs: "="
      },
      templateUrl: "directives/menus/top-menu-directive.tpl.html",
      replace: false,
      controllerAs: "topMenuVm",
      controller: function (
        $stateParams,
        $state,
        $document,
        $rootScope,
        $scope,
        $window,
        $timeout,
        $location,
        CurrentUser,
        CurrentProgram,
        CurrentBriefs,
        deviceDetector,
        envService,
        localStorageService,
        SessionsService) {
        var vm = this;
        vm.currentProgram = localStorageService.get("currentProgram");
        vm.briefs = [];
        vm.briefPartFallbackId = null;
        vm.user = localStorageService.get("currentUser");

        vm.currentUserPrograms = vm.user.programs;
        vm.newNotifications = vm.user.notifications_count > 0/* jshint ignore:line */;
        vm.defaultProgram = vm.user.programs[0];
        vm.onlyProgCollab = onlyProgramCollab();
        vm.isMobile = deviceDetector.isMobile();
        vm.showHome = showHome;
        vm.showGreyBg = false;

        vm.signOut = signOut;
        vm.isBriefs = isBriefs;
        vm.isPrograms = isPrograms;
        vm.isWorkbook = isWorkbook;
        vm.hideDirBriefsAndSupport = hideDirBriefsAndSupport;

        $rootScope.$on("LocalStorageModule.notification.setitem", function (event, data) { // eslint-disable-line angular/on-watch
          var newProg = JSON.parse(data.newvalue); // eslint-disable-line angular/json-functions
          if (data.key === "currentProgram" && (!vm.currentProgram || vm.currentProgram && vm.currentProgram.id !== newProg.id)) {
            vm.currentProgram = localStorageService.get("currentProgram");
            showHome();
          }
          if (data.key === "currentPart") {
            vm.currentPart = localStorageService.get("currentPart");
          }
        });

        $rootScope.$on("$stateChangeSuccess", function () { // eslint-disable-line angular/on-watch
          showGreyBg();
        });

        $document.on("click", ".navbar-collapse.in", function (e) {
          if (angular.element(e.target).is("a")) {
            angular.element(this).collapse("hide");
          }
        });

        initializePage();

        return vm;

        // Function declarations

        function showHome() {
          return vm.currentProgram && !vm.currentProgram.free;
        }

        function hideDirBriefsAndSupport() {
          if (vm.currentProgram) {
            return envService.read("hideDirBriefsAndSupport").indexOf(vm.currentProgram.id) !== -1;
          }
          return false;
        }

        function onlyProgramCollab() {
          if (vm.user.programs.length === 1 && _.find(vm.user.programs, {id: CurrentProgram.collaborationProgramId()})) {
            return true;
          }
        }

        function signOut() {
          SessionsService.logout();;
        }

        function initializePage() {
          vm.currentPart = localStorageService.get("currentPart");
          showGreyBg();
        }

        function showGreyBg() {
          vm.showGreyBg = $state.current.name === "briefs.lesson" || isWorkbook() || ($state.current.name === "part.lesson" && !vm.isMobile); // eslint-disable-line no-extra-parens
        }

        function isBriefs() {
          return (/\/briefs/).test($location.path());
        }

        function isPrograms() {
          return (/\/programs/).test($location.path());
        }

        function isWorkbook() {
          return (/\/workbooks\//).test($location.path()) || (/\/home/).test($location.path());
        }
      }
    };
  }
}());
