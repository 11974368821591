(function () {
  'use strict';

  /* @ngdoc object
   * @name programs
   * @description
   *
   */
  angular
    .module('programs', [
      'ui.router',
      'directives'
    ]);
}());
