<div class="course-outline-page">
  <div class="container-fluid">
    <h2>Briefs</h2>
    <part-grid-item
      ng-if="!loading"
      container-class="course-outline-container"
      collection="briefsOutlineVm.currentParts"
      current-part-id="briefsOutlineVm.currentPartId"
      current-program-active="briefsOutlineVm.currentBrief.active"
      is-brief="true">
    </part-grid-item>
  </div>
</div>
