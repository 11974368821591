(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name workbook.directive:newGoal
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="directives">
       <file name="index.html">
        <new-goal></new-goal>
       </file>
     </example>
   *
   */
  angular
    .module('directives')
    .directive('newGoal', newGoal);

  function newGoal() {
    var directive = {
      restrict: 'E',
      scope: {
        add: '=',
        position: '@'
      },
      templateUrl: 'directives/workbooks/new-goal-directive.tpl.html',
      replace: true,
      bindToController: true,
      controllerAs: 'newGoalVm',
      controller: NewGoalController
    };

    return directive;
  }

  function NewGoalController() {
    var vm = this;
    vm.name = 'newGoal';
    vm.goal = {body: ""};

    vm.addGoal = function() {
      vm.add(vm.goal);
      vm.reset();
    };

    vm.reset = function() {
      vm.entered(false);
      vm.goal = {body: ""};

      return vm.goal;
    };

    vm.entered = function(arg) {
      if (angular.isDefined(arg)) {
        vm.storeEntered = arg;
      }

      return vm.storeEntered;
    };

    return vm;
  }
}());
