(function () {
  'use strict';

  /* @ngdoc object
   * @name directives.menus
   * @description
   *
   */
  angular
    .module('directives.comments', [
      'angularMoment'
    ]);
}());
