(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name programs.controller:ProgramsCtrl
   *
   * @description
   *
   */
  angular
    .module('programs')
    .controller('ProgramsCtrl', ProgramsCtrl);

  function ProgramsCtrl(localStorageService) {
    var vm = this;
    vm.ctrlName = 'ProgramsCtrl';
    vm.user = localStorageService.get("currentUser");
  }
}());
