(function () {
  "use strict";

  angular
    .module("programs")
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state("program", {
        url: "/programs/:programId",
        templateUrl: "main.html",
        resolve: {
          CurrentUser: "CurrentUser",
          currentUser: function (CurrentUser) {
            return CurrentUser.storeUser();
          }
        },
        controller: "ProgramCtrl",
        controllerAs: "programVm"
      })
      .state("courseOutline", {
        parent: "program",
        url: "/outline",
        templateUrl: "course-outline/course-outline.tpl.html",
        controller: "CourseOutlineCtrl",
        controllerAs: "courseOutlineVm",
        params: {reloadPage: false}
      })

      .state("part", {
        parent: "program",
        url: "/parts/:partId",
        template: "<div ui-view></div>",
        controller: "ProgramPartCtrl",
        controllerAs: "partsVm",
        resolve: {
          CurrentPartService: 'CurrentPartService',
          currentPart: function(CurrentPartService, $stateParams) {
            return CurrentPartService.get($stateParams.partId);
          }
        }
      })

      .state("part.lesson", {
        url: "/lessons/:lessonId",
        templateUrl: "lessons/lesson.tpl.html",
        controller: "LessonCtrl",
        controllerAs: "lessonVm",
        params: {reloadPage: false},
        resolve: {
          title: function ($stateParams) {
            var title;
            title = "Classroom | " + $stateParams.programId + " | " + $stateParams.partId + " | " + $stateParams.lessonId;
            return title;
          },
          $title: function (title) {
            return title;
          }
        }
      })

      // part menu related routes

      .state("part.projects", {
        url: "/lessons/:lessonId/projects?page",
        templateUrl: "projects/projects.tpl.html",
        controller: "ProjectsCtrl",
        controllerAs: "projectsVm",
        resolve: {
          title: function ($stateParams) {
            var title;
            title = "Projects | " + $stateParams.programId + " | " + $stateParams.partId;
            return title;
          },
          $title: function (title) {
            return title;
          }
        }
      })
      .state("part.project", {
        url: "/lessons/:lessonId/projects/:projectId",
        templateUrl: "projects/project.tpl.html",
        controller: "ProjectCtrl",
        controllerAs: "projectVm",
        resolve: {
          title: function ($stateParams) {
            var title;
            title = "Project | " + $stateParams.programId + " | " + $stateParams.partId + " | " + $stateParams.projectId;
            return title;
          },
          $title: function (title) {
            return title;
          }
        }
      })
      .state("part.project_edit", {
        url: "/lessons/:lessonId/edit-project",
        templateUrl: "projects/project.tpl.html",
        controller: "ProjectCtrl",
        controllerAs: "projectVm"
      })
      .state("part.tag-collaborators", {
        url: "/lessons/:lessonId/edit-project/tag-collaborators",
        templateUrl: "projects/tag-collaborators.tpl.html",
        controller: "ProjectCtrl",
        controllerAs: "projectVm",
        params: {reloadPage: false}
      })
      .state("part.project_item_edit", {
        url: "/lessons/:lessonId/edit-project/:itemId",
        templateUrl: "projects/project-item-edit.tpl.html",
        controller: "ProjectItemEditCtrl",
        controllerAs: "itemEditVm"
      });
  }
}());
