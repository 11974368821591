(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name workbook.directive:editGoal
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="directives">
       <file name="index.html">
        <edit-goal></edit-goal>
       </file>
     </example>
   *
   */
  angular
    .module('directives')
    .directive('editGoal', editGoal);

  function editGoal() {
    var directive = {
      restrict: 'E',
      scope: {
        goal: "=",
        update: "=",
        remove: "&",
      },
      templateUrl: 'directives/workbooks/edit-goal-directive.tpl.html',
      replace: true,
      bindToController: true,
      controllerAs: 'editGoalVm',
      controller: [EditGoalController]
    };

    return directive;
  }

  function EditGoalController() {
    var vm = this;
    vm.name = 'editGoal';
    vm.buffer = angular.copy(vm.goal);

    vm.entered = function(arg) {
      if (angular.isDefined(arg)) {
        vm.storeEntered = arg;
      }

      return vm.storeEntered;
    };

    vm.reset = function() {
      vm.entered(false);
      vm.buffer = angular.copy(vm.goal);

      return vm.goal;
    };
  }
}());
