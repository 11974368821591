<div class="new-reflection" ng-class="{showPrompt: newReflectionVm.showPrompt}">
  <div class="reflection-prompt" ng-if="newReflectionVm.showPrompt">
    <ms-icon icontype="'close'" class="close-reflection-prompt" ng-click="newReflectionVm.hidePrompt()"></ms-icon>
    <div class="prompt-text">{{newReflectionVm.reflectionPrompt}}</div>
  </div>
  <div class="form-group new-reflection-fcontainer" ng-class="{editing: newReflectionVm.editing}">
    <div class="new-reflection-fitem avatar-holder hidden-xs">
      <a href="/users/{{newReflectionVm.currentUser.id}}" class="avatar-img-circle">
        <img class="avatar-img-circle"
             ng-src="{{newReflectionVm.currentUser.avatar_url}}">
      </a>
    </div>
    <div class="new-reflection-fitem text-area-holder" id="new-reflection-textarea-holder">
      <ms-expandable-textarea
        class="text-area animated"
        ng-click="newReflectionVm.editing = true"
        editing="newReflectionVm.editing"
        ng-model="newReflectionVm.reflection.body"
        placeholder="{{newReflectionVm.placeholder}}"
        ta-height="newReflectionVm.taHeight"
        start-focused="false">
      </ms-expandable-textarea>
    </div>
  </div>
  <div class="actions-group reflection-actions-fcontainer" ng-if="newReflectionVm.editing">
    <div class="reflection-actions-fitem">
      <button ng-click="newReflectionVm.reset()"
              class="btn btn-thin btn-cancel">Cancel
      </button>
    </div>
    <div class="reflection-actions-fitem">
      <button ng-click="newReflectionVm.add()"
              ng-disabled="!newReflectionVm.reflection.body"
              class="btn btn-thin btn-red">Save
      </button>
    </div>
  </div>
</div>
