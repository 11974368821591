(function () {
  "use strict";

  /**
   * @ngdoc directive
   * @name directives.directive:msExpandableTextarea
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="directives">
   <file name="index.html">
   <ms-expandable-textarea
     item-id="editReflectionVm.reflection.id"
     editing="editReflectionVm.editing"
     ng-model="editReflectionVm.reflection.body"
     placeholder="Edit note or reflection"
     ta-height="editReflectionVm.taHeight">
   </ms-expandable-textarea>
   </file>
   </example>
   *
   */
  angular
    .module("directives")
    .directive("msExpandableTextarea", msExpandableTextarea);

  function msExpandableTextarea($document, $timeout) {
    return {
      restrict: "E",
      scope: {},
      templateUrl: "directives/ms-expandable-textarea-directive.tpl.html",
      replace: false,
      bindToController: {
        itemId: "=?",
        startFocused: "=",
        editing: "=",
        ngModel: "=",
        placeholder: "@",
        taHeight: "="
      },
      controllerAs: "msExpandableTextareaVm",
      controller: function () {
        var vm = this;
        vm.itemId = vm.itemId || 999;
        vm.autoExpand = autoExpand;

        setInitialHeight();

        return vm;

        // ------------------ Function Declarations

        function setInitialHeight() {
          $timeout(function () {
            vm.textArea = angular.element("#textarea-" + vm.itemId);
            vm.origHeight = vm.textArea.prop("scrollHeight");
            vm.taHeight = vm.origHeight - 1;
            if (vm.startFocused) vm.textArea.focus();
          });
        }

        function autoExpand(e) {
          var element = angular.isObject(e) ? e.target : $document[0].getElementById(e);
          vm.taHeight = vm.taHeight < 120 ? 120 : vm.taHeight;
          if (element.scrollHeight > vm.taHeight) {
            vm.taHeight = element.scrollHeight + 10;
          }
        }
      }
    };
  }
}());
