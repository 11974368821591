<div class="lesson-page" ng-cloak>
  <mobile-course-menu class="mobile-course-menu visible-xxs-block visible-xs-block" part-id="{{lessonVm.part.id}}" ng-if="lessonVm.isMobile && lessonVm.part"></mobile-course-menu>
  <div class="container-fluid">
    <div class="ms-flex-container lesson-flex-container">
      <div class="ms-flex-item lesson-left hidden-xs" ng-cloak>
        <div class="lessons-nav-title ms-flex-container">
          <round-number-flex position="lessonVm.part.position" ng-if="lessonVm.part.position"></round-number-flex>
          <h4 class="ms-flex-item" ng-class="{'no-margin': !lessonVm.part.position}">{{lessonVm.part.name}}</h4>
        </div>
        <module-menu class="ms-flex-container" ng-if="lessonVm.part" ng-cloak></module-menu>
      </div>

      <div class="ms-flex-item lesson-right" ng-if="lessonVm.part">
        <a ng-show="lessonVm.user.admin" ng-href="/admin/lesson/{{lessonVm.lesson.id}}/edit?return_to=/admin/lesson" target="_blank">
          <i class="fa fa-pencil" aria-hidden="true"></i>
        </a>
        <div class="lesson-body">
          <section
            data-ng-bind-html="lessonVm.lesson.body | trustAsHtml"
            class="lesson-section">
          </section>
          <div class="next-lesson-link" ng-if="lessonVm.lesson.next_lesson.id">
            <div class="divider-horizontal"></div>
            Next lesson:<br>
            <a ui-sref="^.lesson({ lessonId: lessonVm.lesson.next_lesson.id })">
              {{lessonVm.lesson.next_lesson.name}}
            </a>
            <div class="divider-horizontal"></div>
          </div>
          <section ng-if="lessonVm.lesson" ng-cloak>
            <div class="comments__wrapper" ng-if="lessonVm.lesson.name" ng-cloak>
              <comments
                id="comments"
                url-to-feed="lessons/{{lessonVm.lessonId}}/comments"
                commentable-id="lessonVm.lessonId"
                commentable-type="'Lesson'">
              </comments>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>
