<div class="dropdown three-dots-menu">
  <a class="dropdown-toggle" data-toggle="dropdown" href="" role="button">
    <ms-icon icontype="'three-dots'"class="context-more-menu-icon"></ms-icon>
  </a>
  <ul class="dropdown-menu dropdown-menu-right" role="menu">
    <li ng-repeat="item in msThreeDotsVm.menuArr track by $index"
        ng-click="msThreeDotsVm.callAction($index)"
        class="dropdown-menu-click">
      <div ng-switch="item.name">
        <div ng-switch-when="Divider" class="divider-horizontal"></div>
        <span ng-switch-default>
          <i ng-class="msThreeDotsVm.isFunction(item.iconClass) ? item.iconClass() : item.iconClass"
             class="pull-right"
             aria-hidden="true"></i>
          {{ msThreeDotsVm.isFunction(item.name) ? item.name() : item.name }}
        </span>
      </div>
    </li>
  </ul>
</div>
