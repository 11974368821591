(function() {
  'use strict';

  angular
    .module('utils', [
      'ngSanitize'
    ])
    .filter('trustAsHtml', function($sce) {
      return function(text) {
        return $sce.trustAsHtml(text);
      };
    });
}());
