(function () {
  'use strict';

  /* @ngdoc object
   * @name sessions
   * @description
   *
   */
  angular
    .module('sessions', [
      'ui.router'
    ]);
}());
