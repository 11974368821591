<div class="home-page" ng-if="workbookVm.workbook">
  <div class="container-fluid">
    <div class="home-flex-container">
      <div class="home-flex-item sidebar">
        <div class="sidebar-flex-container">
          <ms-user-links
            ng-if="workbookVm.workbook.experts_view"
            program-id="workbookVm.workbook.course_id"
            program-title="workbookVm.workbook.program_title"
            user="workbookVm.workbook.owner"></ms-user-links>
          <ms-in-session
            class="sidebar-flex-item in-session-holder"
            ng-if="!workbookVm.isExpert"></ms-in-session>
          <goals
            ng-if="workbookVm.workbook"
            ng-show="workbookVm.workbook.goals_permissions.show"
            workbook-id="{{workbookVm.workbookId}}"
            permissions="workbookVm.workbook.goals_permissions"
            header="{{workbookVm.goalsHeader}}"
            class="sidebar-flex-item goals-holder">
          </goals>
          <ms-hangout class="sidebar-flex-item"
                      ng-if="!workbookVm.hideDirBriefsAndSupport()"
                      callee-name="workbookVm.workbook.owner.name"
                      workbook-id="workbookVm.workbookId"
                      expert="workbookVm.isExpert">
          </ms-hangout>
        </div>
      </div>
      <div class="home-flex-item journal">
        <ms-journal ng-if="workbookVm.workbook.tuples"></ms-journal>
      </div>
    </div>
  </div>
</div>
