<div class="container-fluid edit-collabs">
  <form ng-submit="collabVm.createCollaborator(collabVm.itemId)">
    <div class="row row-eq-height vertical-align">
      <div class="col-xs-12 collabs-role-col">
        <h3>Add new Collaborator</h3>
      </div>
    </div>
    <div class="collab-choice-holder row">
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <label>What's your collaborator's name?</label>
            <input
              ms-focus-me=true
              ng-model="collabVm.nameField"
              ng-required="true"
              type="text"
              class="form-control other-role"
              placeholder="Name">
          </div>
          <div class="form-group">
            <label>Where can people see their work?</label>
            <input
              ng-model="collabVm.websiteField"
              type="text"
              class="form-control other-role"
              ng-required="true"
              placeholder="Website URL">
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-xs-12">
                <label>What was their role in this project</label>
              </div>
            </div>
            <div class="row">
              <label
                class="btn btn-default discipline-btn"
                ng-click="collabVm.disciplineSelected(discipline)"
                ng-class="{active: discipline.checked}"
                ng-repeat="discipline in collabVm.disciplines">
                {{discipline.name}}
              </label>
            </div>
          </div>
          <div class="form-group">
            <input
            ng-model="collabVm.disciplineField"
            type="text"
            class="form-control other-role"
            placeholder="Other (enter what they did here)">
          </div>
          <button class="btn btn-black tag-done-btn" type="submit">Done</button>
        </div>
      </div>
    </div>
  </form>
</div>
