(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name classroom.directive:newComment
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="classroom">
   <file name="index.html">
   <new-comment></new-comment>
   </file>
   </example>
   *
   */
  angular
    .module('classroom')
    .directive('newComment', newComment);

  function newComment($compile) {
    return {
      restrict: 'EA',
      scope: {
        commentableId: '=',
        commentableType: '=',
        programId: '='
      },
      templateUrl: 'directives/comments/new-comment-directive.tpl.html',
      replace: false,
      controllerAs: 'newCommentVm',
      controller: function ($scope, $element, $q, CommentsService, EnrolleesService, localStorageService) {
        var vm = this;
        vm.posting = false;
        vm.mentionableUsers = [];
        vm.comment = {
          commentable_id: $scope.commentableId, /* jshint ignore:line */ // eslint-disable-line camelcase
          commentable_type: $scope.commentableType/* jshint ignore:line */ // eslint-disable-line camelcase
        };
        vm.currentUser = localStorageService.get("currentUser");

        vm.searchForMentionableUsers = function (term) {
          return EnrolleesService.get($scope.programId, term).then(function (response) {
            var users = [];
            angular.forEach(response.enrollees, function (item) {
              if (item.username.toUpperCase().indexOf(term.toUpperCase()) >= 0) {
                users.push(item);
              }
            });
            vm.mentionableUsers = users;
            return $q.when(users);
          });
        };

        vm.getUsername = function (user) {
          return '@' + user.username;
        };

        function addReplyComment(addedComment) {
          $scope.$parent.commentVm.addNewReply(addedComment);
          angular.element($element[0]).remove();
          $scope.$destroy();
        }

        function addRootComment(addedComment) {
          var comments = angular.element($element[0]),
            newElem = $compile(
              "<comment " +
              "class='comment'" +
              "entity='" + angular.toJson(addedComment, false) + "'" +
              "program-id='" + $scope.programId + "'" +
              "root-comment='true'>" +
              "</comment>"
            )($scope);

          vm.comment.body = '';
          comments.append(newElem);
        }

        vm.sendComment = function (comment) {
          var addedComment;
          if (comment.body && vm.posting === false) {
            vm.posting = true;
            CommentsService.post({comment: comment}).then(function (result) {
              vm.posting = false;
              addedComment = result.comment;

              if (vm.comment.commentable_type === 'Comment') { /* jshint ignore:line */ // eslint-disable-line camelcase
                addReplyComment(addedComment);
              } else {
                addRootComment(addedComment);
              }
              return;
            }, function (error) {
              vm.posting = false;
              return error;
            });
          }
        };
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
