<div class="new-comment__wrapper">
  <form class="new-comment__form">
    <div class="row">
      <div class="col-xs-1 avatar-col">
        <div class="comment__avatar--wrapper">
          <img ng-src="{{newCommentVm.currentUser.avatar_square_url}}" class="comment__avatar img-circle">
          <img ng-if="newCommentVm.currentUser.staff_badge"
               ng-src="{{newCommentVm.currentUser.staff_badge}}"
               class="logo-stuff hidden-xs">
          <span ng-if="newCommentVm.currentUser.expert_badge"
                class="expert-badge hidden-xs">EXPERT</span>
          <span ng-if="newCommentVm.currentUser.alumni"
                class="alumni-badge hidden-xs">ALUMNI</span>
        </div>
      </div>
      <div class="col-xs-11 comment-col form-group">
        <textarea
          mentions
          name="xxx"
          class="float-left"
          ng-model="newCommentVm.comment.body"
          ng-model-options="{ debounce: { 'default': 250 } }"
          rows="5"
          placeholder="Share something">
        </textarea>
      </div>
      <div class="col-xs-12">
        <input type="submit" ng-click="newCommentVm.sendComment(newCommentVm.comment)" ng-if="newCommentVm.comment.body.length >= 3" value="Post"
               class="btn btn-black btn-min-w pull-right">
      </div>
    </div>
  </form>
</div>
