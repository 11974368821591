(function () {
  "use strict";

  /**
   * @ngdoc directive
   * @name directives.directive:msBanner
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="directives">
   <file name="index.html">
   <ms-banners></ms-banners>
   </file>
   </example>
   *
   */
  angular
    .module("directives")
    .directive("msBanners", msBanners);

  function msBanners() {
    return {
      restrict: "E",
      scope: {programId: "@"},
      bindToController: true,
      templateUrl: "directives/ms-banners-directive.tpl.html",
      replace: false,
      controllerAs: "msBannersVm",
      controller: function(CurrentProgram, $log, $window) {
        var vm = this;
        vm.name = "msBanners";

        vm.go = function(banner) {
          $window.open(banner.button_url, '_blank');
          return;
        };

        activate();

        return vm;

        // ------------------

        function activate() {
          CurrentProgram.banners(vm.programId).then(function(data) {
            vm.banners = data.banners;
            return vm.banners;
          }, function(error) {
            $log.error(error);
            return error;
          });
        }
      }
    };
  }
}());
