/* eslint-disable max-params */
(function () {
  "use strict";

  angular
    .module("classroom")
    .config(config)
    .run(run);

  function config(
    $urlRouterProvider,
    $cookiesProvider,
    $locationProvider,
    $provide,
    $windowProvider,
    envServiceProvider,
    localStorageServiceProvider,
    cfpLoadingBarProvider,
    laddaProvider,
    usSpinnerConfigProvider,
    ngRetinaProvider) {
    var devBaseUrl = "",
      $window = $windowProvider.$get(),
      hideDirBriefsAndSupport = [97, 98, 99];

//    $locationProvider.html5Mode(true);

    $urlRouterProvider.otherwise(function () {
      $window.location = "/404.html";
    });

    $urlRouterProvider.rule(function ($injector, $location) {
      var path = $location.path(),
        hasTrailingSlash = path[path.length - 1] === '/';

      if (hasTrailingSlash) {
        //if last charcter is a slash, return the same url without the slash
        return path.substr(0, path.length - 1);
      }
    });

    $cookiesProvider.defaults.path = "/";

    if ($window.location.hostname === "localhost") {
      devBaseUrl = "//localhost:3000";
    }

    if ($window.location.hostname === "dev.mastered.com") {
      devBaseUrl = "//dev.mastered.com:3000";
    }

    envServiceProvider.config({
      domains: {
        development: ["localhost", "mastered.test", "dev.mastered.com"],
        testing: ["testing.mastered.com"],
        staging: ["staging.mastered.com"],
        ios: ["ios.mastered.com"],
        production: ["www.mastered.com"]
      },
      vars: {
        development: {
          gaTracker: null
        },
        testing: {},
        staging: {},
        ios: {
          gaTracker: null
        },
        production: {
          honeyBadgerApiKey: "48273a89"
        },
        defaults: {
          apiUrl: "https://staging.mastered.com/api/v2",
          gaTracker: "UA-46814231-4",
          honeyBadgerApiKey: null,
          mixpanelToken: "fdd8dc2eae2bb8e274fa9c53f1fdb7dc",
          hideDirBriefsAndSupport: hideDirBriefsAndSupport,
          smartSurveyApiUrl: "https://api.smartsurvey.io/v1",
          smartSurveyApiToken: "LnutAAdeTorxrEZt34fGc8sIsZBVcjMj",
          smartSurveyApiSecret: "NgNR0JPsXUqy6O8"
        }
      }
    });
    // run the environment check, so the configuration is made
    // before controllers and services are built
    envServiceProvider.check();

    laddaProvider.setOption({
      style: "expand-right",
      spinnerSize: 30,
      spinnerColor: "#ffffff"
    });

    usSpinnerConfigProvider.setDefaults({
      lines: 17,
      length: 0,
      width: 19,
      radius: 30,
      scale: 0.25,
      corners: 0.8,
      color: "#666666",
      opacity: 0.3,
      speed: 1.3,
      trail: 40,
      fps: 20,
      top: "51%",
      left: "0%",
      hwaccel: true
    });

    // Configure loading bar
    cfpLoadingBarProvider.includeBar = true;
    cfpLoadingBarProvider.includeSpinner = false;
    cfpLoadingBarProvider.latencyThreshold = 500;
    cfpLoadingBarProvider.parentSelector = "#loading-bar-container";
    cfpLoadingBarProvider.spinnerTemplate = "<div><span class=\"fa fa-spinner\">Custom Loading Message...</div>";

    // Configure angular-retina
    // Hide images until loaded...
    ngRetinaProvider.setFadeInWhenLoaded(true);

    // add to and from states and params to $state to use in resolves
    $provide.decorator("$state", function ($delegate, $rootScope) {
      $rootScope.$on("$stateChangeStart", function (event, toState, toParams, fromState, fromParams) { // eslint-disable-line angular/on-watch
        $delegate.toState = toState;
        $delegate.toParams = toParams;
        $delegate.fromState = fromState;
        $delegate.fromParams = fromParams;
      });
      return $delegate;
    });

    // Configuration for default textangular WYSIWYG editor
    $provide.decorator("taOptions", ["$delegate", function (taOptions) {
      taOptions.toolbar = [
        ["h1", "h2", "p", "bold", "italics", "underline"],
        ["ul", "quote"],
        ["justifyLeft", "justifyCenter"],
        ["insertLink"],
        ["wordcount"]
      ];
      taOptions.classes = {
        focussed: "ta-focussed",
        toolbar: "ta-btn-toolbar",
        toolbarGroup: "ta-btn-group",
        toolbarButton: "btn btn-default ta-btn",
        toolbarButtonActive: "ta-active",
        disabled: "ta-disabled",
        textEditor: "ta-form-control",
        htmlEditor: "ta-form-control"
      };
      taOptions.defaultTagAttributes.a.target = "_blank";
      return taOptions;
    }]);

    // LocalStorageService config
    localStorageServiceProvider
      .setPrefix("ms")
      .setNotify(true, true);
  }

  function run(
    $analytics,
    $location,
    $rootScope,
    $state,
    $timeout,
    $window,
    Restangular,
    localStorageService,
    validator,
    envService) {
    Restangular.setErrorInterceptor(function (response) {
      var error = true;
      switch (response.status) {
      case 401:
        $state.go("sign-in");
        return false;
//      case 403:
//        $window.location = "/403";
//        return false;
      default:
        return error;
      }
    });

    $rootScope.$on("$stateChangeStart", function (event, toState, toParams, fromState, fromParams) { // eslint-disable-line angular/on-watch
      if ($state.href(fromState, fromParams) !== "#" + $location.path() && fromState.name && angular.isUndefined(toParams.partId)) {
        if (toParams.programId === fromParams.programId) {
          $window.location.reload();
        } else {
          // stop it looping
          fromParams.programId = toParams.programId;
          $state.go("program", { programId: toParams.programId }, { location: "replace" });
        }
      }
    });

    validator.setValidElementStyling(false);
    validator.setInvalidElementStyling(false);

    $rootScope.$on("$stateChangeSuccess", function (event, toState, toParams, fromState, fromParams) { // eslint-disable-line angular/on-watch
      var tracker,
        _hsq,
        currentUser = localStorageService.get("currentUser"),
        pagePath = envService.environment === "development" ? $state.href($state.current.name) : "classroom/" + $state.href($state.current.name),
        currentProgram = localStorageService.get("currentProgram"),
        LeadershipCourseIds = envService.read("hideDirBriefsAndSupport"),
        showBeacon = toState.url !== "/sign-in" && LeadershipCourseIds.indexOf(parseInt(toParams.programId, 10)) === -1;

      $window.scrollTo(0, 0);

      if (currentProgram) {
        showBeacon = showBeacon && LeadershipCourseIds.indexOf(currentProgram.id) === -1;
      }

//    Clear Honeybadger context
      Honeybadger.resetContext();
      Honeybadger.resetMaxErrors();

//    Set Honeybadger with curent context
      Honeybadger.setContext({
        currentPage: pagePath,
        currentProgram: currentProgram ? currentProgram.name : null,
        toParams: toParams,
        toState: toState,
        fromParams: fromParams,
        fromState: fromState
      });

      if (currentUser && currentUser.email) {
        Honeybadger.setContext({
          user_id: currentUser.id,
          user_email: currentUser.email,
          name: currentUser.name
        });
        // Hubspot Tracking subsequent page views
        _hsq = $window._hsq = $window._hsq || [];
        _hsq.push(["setPath", pagePath]);
        _hsq.push(["identify", {
          email: currentUser.email
        }]);
        _hsq.push(["trackPageView"]);

        // Helpscout - populate contact fields
        if (showBeacon) {
          HS.beacon.ready(function () {
            HS.beacon.identify({
              name: currentUser.name,
              email: currentUser.email
            });
          });
        }
      }

      if (!showBeacon) {
        HS.beacon.ready(function () {
          $timeout(function () {
            HS.beacon.destroy();
          });
        });
      }

      tracker = envService.read("gaTracker");
      if (currentUser && tracker) {
        if (envService.environment !== "development" && currentUser.id) {
          if (currentUser.admin === false || envService.environment !== "production") {
            // track by custom user id
            $analytics.setUsername(currentUser.id);
            $window.ga("create", tracker, "auto");
          }
        } else {
          $window.ga("create", tracker, "auto");
        }
      }
    });

    $rootScope.$on("cfpLoadingBar:started", function () { // eslint-disable-line angular/on-watch
      $rootScope.loading = true;
    });
    $rootScope.$on("cfpLoadingBar:loading", function (event, data) { // eslint-disable-line angular/on-watch
      if (data.url.indexOf("comments") > 0) {
        $rootScope.loading = false;
      }
    });
    $rootScope.$on("cfpLoadingBar:completed", function () { // eslint-disable-line angular/on-watch
      $rootScope.loading = false;
    });

    // Helpscout config
    HS.beacon.config({
      color: "#ef4459",
      icon: "question",
      showContactFields: true,
      poweredBy: false,
      instructions: "Reach out to our support team."
    });
  }
}());
