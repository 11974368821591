<div class="new-project-directive">
  <!-- ************************ Non brief project ************************ -->
  <div class="col-xs-12 new-project-item-container non-brief" ng-if="!projectVm.lesson.networkMaterial">
    <div class="welcome-msg-box" ng-if="projectVm.ownProject && !projectVm.project.items[0]">
      <div class="vert-center-block row">
        <div class="mastered-msg-text">
          <div class="msg-title">You haven't uploaded anything yet</div>
          <div class="msg-txt">
            Make sure you read the guidance in the Creative process module to find out what you need to upload.
          </div>
        </div>
      </div>
    </div>

    <div id="ms-alert"></div>

    <div ng-if="newItemVm.item.projectable_type || !newItemVm.canCreate" class="fade-element-in">
      <form name="newItemVm.itemForm">
        <div class="new-project-item">
          <div ng-switch="newItemVm.item.projectable_type">
            <div ng-switch-when="Image" class="fade-element-in" project-item-image></div>
            <div ng-switch-when="Text" class="fade-element-in" project-item-text></div>
            <div ng-switch-when="Video" class="fade-element-in" project-item-video></div>
            <div ng-switch-when="Pdf" class="fade-element-in" project-item-pdf></div>
          </div>
        </div>
        <div class="save-project-button-holder">
          <button ng-click="newItemVm.createProjectItem()"
                  ng-show="newItemVm.showSaveBtn()"
                  ng-disabled="newItemVm.itemForm.$invalid || !newItemVm.canCreate || newItemVm.item.projectable.length === 0"
                  ladda="!newItemVm.canCreate"
                  class="btn btn-black pull-right">
            {{newItemVm.buttonLabel}}
          </button>
          <button ng-click="newItemVm.cancel()" class="btn btn-default pull-right">Cancel</button>
        </div>
      </form>
    </div>

    <div class="row new-project-item-buttons fade-element-in" ng-if="!newItemVm.item.projectable_type">
      <div class="project-item-option col-sm-3" ng-click="newItemVm.addItem('Image')">
        <div class="new-project-item-button btn btn-default" ng-class="{'active': newItemVm.item.projectable_type === 'Image'}">
          <div class="vert-center-block">
            <div class="project-item-icon" ms-icon icontype="'image'"></div>
            <div class="button-title">Add images</div>
            <div class="font-small-pale">Insert an image, up to {{newItemVm.maxFileSize}}</div>
          </div>
        </div>
      </div>
      <div class="project-item-option col-sm-3" ng-click="newItemVm.addItem('Text')">
        <div class="new-project-item-button btn btn-default" ng-class="{'active': newItemVm.item.projectable_type === 'Text'}">
          <div class="vert-center-block">
            <div class="project-item-icon" ms-icon icontype="'text'"></div>
            <div class="button-title">Add text</div>
            <div class="font-small-pale">Write a paragraph</div>
          </div>
        </div>
      </div>
      <div class="project-item-option col-sm-3" ng-click="newItemVm.addItem('Video')">
        <div class="new-project-item-button btn btn-default" ng-class="{'active': newItemVm.item.projectable_type === 'Video'}">
          <div class="vert-center-block">
            <div class="project-item-icon" ms-icon icontype="'video'"></div>
            <div class="button-title">Add video</div>
            <div class="font-small-pale">Embed from Youtube or Vimeo</div>
          </div>
        </div>
      </div>
      <div class="project-item-option col-sm-3" ng-click="newItemVm.addItem('Pdf')">
        <div class="new-project-item-button btn btn-default" ng-class="{'active': newItemVm.item.projectable_type === 'Pdf'}">
          <div class="vert-center-block">
            <div class="project-item-icon" ms-icon icontype="'paperclip'"></div>
            <div class="button-title">Add PDF</div>
            <div class="font-small-pale">Insert a document, up to {{newItemVm.maxFileSize}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="grey-divider-line hidden-xs" ng-if="projectVm.ownProject && projectVm.project.items[0]"></div>

    <div ms-message-box ng-if="projectVm.ownProject && projectVm.project.items[0]"
         msg-title="newItemVm.masteredMsg.title"
         msg-text="newItemVm.masteredMsg.text"
         msg-btn-text="newItemVm.masteredMsg.btnText"
         msg-btn-icon="newItemVm.masteredMsg.btnIcon"
         icon-color="newItemVm.masteredMsg.iconColor"
         msg-btn-fct="projectVm.toggleComplete()">
    </div>


    <script type="text/ng-template" id="template/alert/item_alert.html">
      <div class='alert' ng-class='type && "alert-" + type'>
        <button type="button" class="close" ng-click="$hide()">&times;</button>
        <span ms-icon class="alert-icon" icontype="newItemVm.alert.icon" icon-color="newItemVm.alert.iconColor"></span>
        <span ng-bind-html="content"></span>
      </div>
    </script>
  </div>

  <!-- ************************ Brief project ************************ -->

  <div class="col-xs-12 new-project-item-container" ng-if="projectVm.lesson.networkMaterial">
    <div class="welcome-msg-box" ng-if="projectVm.ownProject && !projectVm.project.items[0]">
      <div class="vert-center-block row">
        <div class="mastered-msg-text">
          <div class="msg-title">You haven't uploaded anything yet</div>
          <div class="msg-txt">
            Make sure you read the guidance in the brief to find out what you need to upload.
          </div>
        </div>
      </div>
    </div>
    <div id="ms-alert"></div>

    <div ng-if="newItemVm.item.projectable_type || !newItemVm.canCreate" class="fade-element-in">
      <form name="newItemVm.itemForm">
        <div class="new-project-item">
          <div ng-switch="newItemVm.item.projectable_type">
            <div ng-switch-when="Image" class="fade-element-in" project-item-image></div>
            <div ng-switch-when="Text" class="fade-element-in" project-item-text></div>
            <div ng-switch-when="Video" class="fade-element-in" project-item-video></div>
            <div ng-switch-when="Pdf" class="fade-element-in" project-item-pdf></div>
          </div>
        </div>
        <div class="save-project-button-holder">
          <button ng-click="newItemVm.createProjectItem()"
                  ng-show="newItemVm.showSaveBtn()"
                  ng-disabled="newItemVm.itemForm.$invalid || !newItemVm.canCreate || newItemVm.item.projectable.length === 0"
                  ladda="!newItemVm.canCreate"
                  class="btn btn-black pull-right">
            {{newItemVm.buttonLabel}}
          </button>
          <button ng-click="newItemVm.cancel()" class="btn btn-default pull-right">Cancel</button>
        </div>
      </form>
    </div>

    <div class="row new-project-item-buttons fade-element-in" ng-if="!newItemVm.item.projectable_type">
      <div class="project-item-option col-sm-3" ng-click="newItemVm.addItem('Image')">
        <div class="new-project-item-button btn btn-default" ng-class="{'active': newItemVm.item.projectable_type === 'Image'}">
          <div class="vert-center-block">
            <div class="project-item-icon" ms-icon icontype="'image'"></div>
            <div class="button-title">Add images</div>
            <div class="font-small-pale">Insert an image, up to {{newItemVm.maxFileSize}}</div>
          </div>
        </div>
      </div>
      <div class="project-item-option col-sm-3" ng-click="newItemVm.addItem('Text')">
        <div class="new-project-item-button btn btn-default" ng-class="{'active': newItemVm.item.projectable_type === 'Text'}">
          <div class="vert-center-block">
            <div class="project-item-icon" ms-icon icontype="'text'"></div>
            <div class="button-title">Add text</div>
            <div class="font-small-pale">Write a paragraph</div>
          </div>
        </div>
      </div>
      <div class="project-item-option col-sm-3" ng-click="newItemVm.addItem('Video')">
        <div class="new-project-item-button btn btn-default" ng-class="{'active': newItemVm.item.projectable_type === 'Video'}">
          <div class="vert-center-block">
            <div class="project-item-icon" ms-icon icontype="'video'"></div>
            <div class="button-title">Add video</div>
            <div class="font-small-pale">Embed from Youtube or Vimeo</div>
          </div>
        </div>
      </div>
      <div class="project-item-option col-sm-3" ng-click="newItemVm.addItem('Pdf')">
        <div class="new-project-item-button btn btn-default" ng-class="{'active': newItemVm.item.projectable_type === 'Pdf'}">
          <div class="vert-center-block">
            <div class="project-item-icon" ms-icon icontype="'paperclip'"></div>
            <div class="button-title">Add PDF</div>
            <div class="font-small-pale">Insert a document, up to {{newItemVm.maxFileSize}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="grey-divider-line hidden-xs" ng-if="projectVm.ownProject && projectVm.project.items[0]"></div>

    <div ms-message-box ng-if="projectVm.ownProject && projectVm.project.items[0]"
         msg-title="newItemVm.masteredMsg.title"
         msg-text="newItemVm.masteredMsg.text"
         msg-btn-text="newItemVm.masteredMsg.btnText"
         msg-btn-icon="newItemVm.masteredMsg.btnIcon"
         icon-color="newItemVm.masteredMsg.iconColor"
         msg-btn-fct="projectVm.toggleComplete()">
    </div>

    <script type="text/ng-template" id="template/alert/item_alert.html">
      <div class='alert' ng-class='type && "alert-" + type'>
        <button type="button" class="close" ng-click="$hide()">&times;</button>
        <span ms-icon class="alert-icon" icontype="newItemVm.alert.icon" icon-color="newItemVm.alert.iconColor"></span>
        <span ng-bind-html="content"></span>
      </div>
    </script>
  </div>
</div>
