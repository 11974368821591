<div id="tuple-{{taskAndProjectVm.tuple.project.id}}"
     class="task-and-project-directive"
     ng-if="taskAndProjectVm.notDeleted">
  <div class="workbook-project-flexc">
    <a href="" class="workbook-project-flexitem" ng-click="taskAndProjectVm.gotoProject()" ng-if="taskAndProjectVm.tuple.project.cover_image_url">
      <div class="gallery grid-{{taskAndProjectVm.gridSize}}">
        <div class="gallery-image-holder" ng-repeat="image in taskAndProjectVm.images">
          <div class="gallery-image ratio-content">
            <img ng-src="{{image}}"
                 class="proj-image">
          </div>
        </div>
      </div>
    </a>

    <div class="workbook-project-flexitem project-banner-fitem">

      <a href="/users/{{taskAndProjectVm.owner.id}}" class="avatar-img-holder banner-flexitem">
        <img class="avatar-img-circle" ng-src="{{taskAndProjectVm.owner.avatar_url}}">
      </a>

      <div class="project-label banner-flexitem" ng-class="{'tagged-project-label': !taskAndProjectVm.myProject}">
        <strong><a href="" ng-click="taskAndProjectVm.gotoProject()">{{taskAndProjectVm.projectOwnedBy}} project</a></strong> for <strong><a href=""
                                                                                                                                          ng-click="taskAndProjectVm.gotoPart()">{{taskAndProjectVm.tuple.task.part_title}}</a></strong>
        <span class="dark-grey">| {{taskAndProjectVm.tuple.created_at | formatStartDate}}</span>
      </div>
      <div class="banner-flexitem three-dot-holder" ng-if="!taskAndProjectVm.myProject">
        <div class="three-dots">
          <ms-three-dots-menu menu-arr="taskAndProjectVm.menuArr">
          </ms-three-dots-menu>
        </div>
      </div>
    </div>

    <div class="project-collaborators" ng-if="taskAndProjectVm.collaboratorList">
      <strong>{{taskAndProjectVm.projectOwner}}</strong> with {{taskAndProjectVm.collaboratorList}}
    </div>

    <div class="expert-feedback" ng-repeat="feedback in taskAndProjectVm.feedbacks" ng-if="taskAndProjectVm.feedbacks">
      <edit-reflection reflection="feedback"></edit-reflection>
    </div>
  </div>
</div>
