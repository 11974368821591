(function () {
  'use strict';

  angular
    .module('directives')
    .directive('msPartCaption', msPartCaption);

  function msPartCaption() {
    var directive = {
      restrict: 'AE',
      scope: {
        part: '=',
        buttonAction: '='
      },
      controller: function() {},
      controllerAs: 'partCaptionVm',
      bindToController: true,
      templateUrl: 'directives/program-part/ms-part-caption.tpl.html'
    };

    return directive;
  }
}());
