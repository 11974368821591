/* eslint-disable max-params */
/* eslint-disable camelcase */
/* jshint camelcase: false */
(function () {
  "use strict";

  /**
   * @ngdoc object
   * @name projects.controller:ProjectCtrl
   *
   * @description
   *
   */
  angular
    .module("projects")
    .controller("ProjectCtrl", ProjectCtrl);

  function ProjectCtrl(
    $rootScope,
    $alert,
    $stateParams,
    $state,
    $location,
    $anchorScroll,
    $window,
    $scope,
    ProjectsService,
    ProjectItemService,
    EnrolleesService,
    CollaboratorsService) {
    var completedAlert = {
        txt: "Your project has been marked complete.",
        style: "success",
        icon: "tick",
        iconColor: "#666666"
      },
      sharedAlert = {
        txt: "Your project was shared with your peers.",
        style: "success",
        icon: "tick",
        iconColor: "#666666"
      },
      defaultCollab = {
        avatar_square_url: "https://www.mastered.com/assets/blank-profile-avatar.svg",
        avatar_url: "https://www.mastered.com/assets/blank-profile-avatar.svg",
        name: "Non-mastered teammate",
        email: "",
        id: "",
        username: "",
        current_course: "",
        searchKey: "staticOption"
      },
      vm = this;

    vm.ctrlName = "ProjectCtrl";
    vm.programId = $stateParams.programId;
    vm.partId = $stateParams.partId;
    vm.lessonId = $stateParams.lessonId;
    vm.lesson = {};
    vm.projectId = $stateParams.projectId;
    vm.userAvatar = $stateParams.userAvatar;
    vm.ownProject = false;
    vm.showEditTitle = false;
    vm.project = {};
    vm.items = [];
    vm.menuArr = [];
    vm.showEditControls = false;
    vm.collaborators = [];
    vm.potentialCollaborators = [];
    vm.potCollab = {};
    vm.selectPlaceholder = "Start typing a name...";

    vm.editProjects = editProjects;
    vm.callFunction = callFunction;
    vm.scrollTo = scrollTo;
    vm.moveItem = moveItem;
    vm.cancelTitleEdit = cancelTitleEdit;
    vm.titleEdit = titleEdit;
    vm.saveTitle = saveTitle;
    vm.getCollaborators = getCollaborators;
    vm.potentialCollabs = potentialCollabs;
    vm.onSelectCallback = onSelectCallback;
    vm.searchTerm = null;
    vm.deleteCollaborator = deleteCollaborator;
    vm.setCompletedAlert = setCompletedAlert;
    vm.setSharedAlert = setSharedAlert;
    vm.createAlert = createAlert;
    vm.isEditAction = isEditAction;

    vm.isCompleted = false;
    vm.toggleComplete = toggleComplete;
    vm.completePrompt = completePrompt;

    vm.isShared = false;
    vm.toggleShared = toggleShared;
    vm.sharedPrompt = sharedPrompt;

    vm.topMnuMobileArr = [];
    vm.setAlert = setAlert;

    loadProject();

    $scope.$watch(angular.bind(this, function () { // eslint-disable-line angular/controller-as-vm
      return this.project.items; // eslint-disable-line angular/controller-as-vm
    }), function (newVal) {
      angular.forEach(newVal, function (item, index) {
        buildMenu(item, index);
      });
    }, true);

    $scope.$watch("projectVm.project.status", function(newVal, oldVal) {
      vm.isCompleted = vm.project.status === "complete";
      if (newVal === "complete" && angular.isDefined(oldVal)) setCompletedAlert();
    });

    $scope.$watch("projectVm.project.shared", function(newVal, oldVal) {
      vm.isShared = vm.project.shared === true;
      if (newVal === true && angular.isDefined(oldVal)) setSharedAlert();
    });

    // Function definitions

    function loadProject() {
      if ((/\/edit-project/g).test($location.path())) {
        vm.ownProject = true;
        ProjectsService.currentUserProject(vm.lessonId)
          .then(function (res) {
            if (res.project.id) {
              vm.project = res.project;
              vm.lesson = res.project.lesson;
              buildTopMnuMobile();
              getCollaborators(res.project.id);
            }
            $rootScope.$broadcast("page:load");
          });
      } else {
        ProjectsService.get(vm.projectId).then(function (result) {
          vm.project = result.project;
          vm.lesson = result.project.lesson;
          getCollaborators(result.project.id);
          scrollTo();
          $rootScope.$broadcast("page:load");
        });
      }
    }

    function scrollTo(anchor) {
      $anchorScroll(anchor);
    }

    function editProjects() {
      if (vm.showEditControls) {
        vm.saveTitle();
      }
      vm.showEditControls = !vm.showEditControls;
      vm.showEditTitle = !vm.showEditTitle;
    }

    function callFunction(menuAction) {
      if (angular.isFunction(menuAction.action)) {
        if (angular.isDefined(menuAction.confirm)) {
          if ($window.confirm(menuAction.confirm)) {
            menuAction.action();
          }
        } else {
          menuAction.action();
        }
      }
    }

    function buildMenu(item) {
      vm.menuArr[item.id] = [];

//      Hide edit from images
      if (angular.isUndefined(item.projectable.cover)) {
        vm.menuArr[item.id].push({
          name: "Edit",
          iconClass: "icon icon-edit",
          action: function () {
            editItem(item);
          }
        });
      }

      vm.menuArr[item.id].push({
        name: "Delete",
        iconClass: "icon icon-delete",
        action: function () {
          deleteItem(item);
        },
        confirm: "This will permanently delete this item. Press OK to confirm or Cancel to return to your project."
      });

      // Disable up arrow on first item
      if (item.position === 1) {
        vm.menuArr[item.id].push({
          name: "Move up",
          iconClass: "icon icon-up font-small-pale"
        });
      } else {
        vm.menuArr[item.id].push({
          name: "Move up",
          iconClass: "icon icon-up",
          action: function () {
            moveItem(item, "up");
          }
        });
      }

      // Disable down arrow on last item
      if (item.position === vm.project.items.length) {
        vm.menuArr[item.id].push({
          name: "Move down",
          iconClass: "icon icon-down font-small-pale"
        });
      } else {
        vm.menuArr[item.id].push({
          name: "Move down",
          iconClass: "icon icon-down",
          action: function () {
            moveItem(item, "down");
          }
        });
      }
    }

    function editItem(item) {
      $state.go("^.project_item_edit", {
        programId: vm.programId,
        partId: vm.partId,
        lessonId: vm.lessonId,
        itemId: item.id,
        projectId: vm.project.id
      });
    }

    function deleteItem(item) {
      ProjectItemService
        .delete(vm.project.id, item)
        .then(function (result) {
          vm.project.items = result.project_items;
          if (vm.project.items.length === 0) {
            vm.project.status = "not_started";
          }
        });
    }

    function moveItem(item, dir) {
      ProjectItemService
        .move(vm.project.id, item, dir)
        .then(function (result) {
          vm.project.items = result.project_items;
        });
    }

    function titleEdit() {
      vm.origTitle = vm.project.title;
      vm.showEditTitle = true;
    }

    function cancelTitleEdit() {
      vm.project.title = vm.origTitle;
      vm.showEditTitle = false;
    }

    function saveTitle() {
      ProjectsService
        .update(vm.project)
        .then(function () {
        }, function () {
        });
    }

    function potentialCollabs(searchTerm) {
      vm.searchTerm = searchTerm;
      return EnrolleesService.findEnrollees(searchTerm, 25).then(function (response) {
        vm.potentialCollaborators = [defaultCollab].concat(response.enrollees);
      });
    }

    function getCollaborators(projectId) {
      return CollaboratorsService.getCollaborators(projectId).then(function (resp) {
        vm.collaborators = resp.project_collaborators;
        vm.projectAuthor = resp.author;
        if (vm.collaborators.length > 0) {
          vm.selectPlaceholder = "Add another collaborator...";
        }
      });
    }

    function onSelectCallback(collab) {
      if (collab.searchKey === "staticOption") {
        $state.go('^.new-collaborator', {
          programId: vm.programId,
          lessonId: vm.lessonId,
          name: vm.searchTerm
        });
      } else {
        CollaboratorsService.addCollaborator(vm.project.id, collab).then(function (resp) {
          vm.collaborators.push(resp.project_collaborator);
        });
      }
    }

    function deleteCollaborator(collab) {
      CollaboratorsService.deleteCollaborator(vm.project.id, collab.id).then(function () {
        var index = vm.collaborators.indexOf(collab);
        vm.collaborators.splice(index, 1);
      });
    }

    function setCompletedAlert() {
      vm.alert = completedAlert;
      createAlert(vm.alert.style);
    }

    function setSharedAlert() {
      vm.alert = sharedAlert;
      createAlert(vm.alert.style);
    }

    function setAlert(style) {
      vm.alert = {
        txt: "Your project has been marked complete.",
        style: style,
        icon: style === "success" ? "tick" : "",
        iconColor: "#666666"
      };
      createAlert(style);
    }

    function createAlert(style) {
      vm.displayAlert = $alert({
        title: "",
        content: vm.alert.txt,
        type: style,
        container: "#ms-tag-alert",
        templateUrl: "template/alert/tag_alert.html",
        scope: $scope,
        duration: 5,
        show: true
      });
    }

    function isEditAction() {
      var result = vm.ownProject === true && !vm.showEditControls && vm.project.items && vm.project.items.length > 0;
      return result;
    }

    function toggleComplete() {
      if (vm.project.status === "complete") {
        ProjectsService
          .started(vm.project.id)
          .then(function () {
            vm.project.status = "started";
          });
      } else {
        ProjectsService
          .complete(vm.project.id)
          .then(function () {
            vm.project.status = "complete";
          });
      }
    }

    function toggleShared() {
      if (vm.project.shared === true) {
        ProjectsService
          .unshare(vm.project.id)
          .then(function () {
            vm.project.shared = false;
          });
      } else {
        ProjectsService
          .share(vm.project.id)
          .then(function () {
            vm.project.shared = true;
          });
      }
    }

    function completePrompt() {
      return vm.project.status === "complete" ? "Marked complete" : "Mark as complete";
    }

    function sharedPrompt() {
      return vm.isShared === true ? "Shared with peers" : "Share with peers";
    }

    function buildTopMnuMobile() {
      vm.topMnuMobileArr = [
        {
          name: vm.completePrompt,
          action: vm.toggleComplete
        },
        {
          name: vm.sharedPrompt,
          action: vm.toggleShared
        },
        {
          name: "Edit",
          iconClass: "icon icon-edit",
          action: vm.editProjects
        }
      ];
    }

    return vm;
  }
}());
