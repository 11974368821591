(function () {
  "use strict";

  angular
    .module("classroom")
    .factory("OnboardingFactory", OnboardingFactory);

  function OnboardingFactory(OnboardingService, $state, localStorageService) {
    var isIntroductionVideoSkipped,
      isBookingCoachSkipped,
      isBookingFeedbackSkipped,
      storeModifiedPart,
      self = {
        part: null,
        init: function(part) {
          self.part = part;
          return self;
        },
        ifBoardingThenGo: function(part) {
          if (angular.isUndefined(part)) {
            return;
          }

          if (angular.isDefined(part) && (!self.part || self.part !== part)) {
            self.init(part);
          }

          if (!isIntroductionVideoSkipped()) {
            $state.go("part.introductionVideo", {partId: self.part.id});
          } else if (!isBookingFeedbackSkipped()) {
            $state.go("part.bookingFeedback", {partId: self.part.id});
          } else if (!isBookingCoachSkipped()) {
            $state.go("part.bookingCoaching", {partId: self.part.id});
          } else if (angular.isUndefined($state.params.lessonId)) {
            $state.go("part.lesson", {partId: self.part.id, lessonId: self.part.latest_lesson_id});
          }
        },
        skipIntroductionVideo: function() {
          self.part.redirect_to_introduction_video = false;
          storeModifiedPart();
          OnboardingService.post(self.part.id, "introduction_video");
          self.ifBoardingThenGo(self.part);
          return self;
        },
        skipBookingFeedback: function() {
          self.part.redirect_to_feedback_booking = false;
          storeModifiedPart();
          OnboardingService.post(self.part.id, "booking_feedback");
          self.ifBoardingThenGo(self.part);
          return self;
        },
        skipBookingCoach: function() {
          self.part.redirect_to_coaching_booking = false;
          storeModifiedPart();
          OnboardingService.post(self.part.id, "booking_coach");
          self.ifBoardingThenGo(self.part);
          return self;
        }
      };

    isIntroductionVideoSkipped = function() {
      return self.part.redirect_to_introduction_video === false;
    };

    isBookingFeedbackSkipped = function() {
      return self.part.redirect_to_feedback_booking === false;
    };

    isBookingCoachSkipped = function() {
      return self.part.redirect_to_coaching_booking === false;
    };

    storeModifiedPart = function() {
      localStorageService.set("currentPart", self.part);
    };

    return self;
  }
}());
