/* eslint-disable camelcase */
/* jshint camelcase: false */
(function () {
  'use strict';

  angular
    .module('classroom')
    .service('ProjectsService', ProjectsService);

  function ProjectsService(AuthService, EventTracker, localStorageService) {
    var self = {
      currentUserProject: function (lessonId) {
        return AuthService.apiService()
          .one('lessons', lessonId)
          .one('current_user_project')
          .get();
      },

      currentUserCollaborations: function (lessonId) {
        return AuthService.apiService()
          .one('lessons', lessonId)
          .one('current_user_collaborations')
          .get();
      },

      create: function (programId, partId, lessonId) {
        return AuthService.apiService()
          .one('programs', programId)
          .one('parts', partId)
          .one('lessons', lessonId)
          .one('projects')
          .post();
      },

      complete: function (projectId) {
        return AuthService.apiService()
          .one('projects', projectId)
          .one('complete')
          .put();
      },

      started: function (projectId) {
        return AuthService.apiService()
          .one('projects', projectId)
          .one('started')
          .put();
      },

      share: function (projectId) {
        return AuthService.apiService()
          .one('projects', projectId)
          .one('share')
          .put();
      },

      unshare: function (projectId) {
        return AuthService.apiService()
          .one('projects', projectId)
          .one('unshare')
          .put();
      },

      get: function (projectId) {
        // Record program id if program - but not for briefs
        var currentProgram = localStorageService.get("currentProgram"),
          currentProgramId = currentProgram ? currentProgram.id : null;

        return AuthService.apiService()
          .one('projects', projectId)
          .get()
          .then(function (res) {
            EventTracker.track("Viewed Project", {
              app: "web",
              user_id: localStorageService.get("currentUser").id,
              course_id: currentProgramId,
              object_id: res.project.id
            });

            return res;
          });
      },

      getAll: function (lessonId, page) {
        page = page || '1';
        return AuthService.apiService()
          .one('lessons', lessonId)
          .one('projects')
          .get({page: page});
      },

      update: function (project) {
        return AuthService.apiService()
          .one('projects', project.id)
          .customPUT(project);
      }
    };

    return self;
  }
}());
