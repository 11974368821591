(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name directives.directive:msFocusMe
   * @restrict EA
   * @element
   *
   *
   */
  angular
    .module('directives')
    .directive('msFocusMe', msFocusMe);

  function msFocusMe($timeout) {
    var directive = {
      scope: {trigger: '=msFocusMe'},
      link: function(scope, element) {
        scope.$watch('trigger', function(newValue) {
          if (newValue) {
            $timeout(function() {
              angular.element(element).focus();
            }, 0);
          }
        });
      }
    };

    return directive;
  }
} ());
