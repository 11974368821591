/* eslint-disable camelcase */
/* eslint-disable no-loop-func */
/* jshint camelcase: false*/
(function () {
  "use strict";

  angular
    .module("directives")
    .directive("newProjectItem", newProjectItem);

  function newProjectItem() {
    return {
      restrict: "E",
      scope: true,
      templateUrl: "directives/projects/new-project-item-directive.tpl.html",
      replace: false,
      controllerAs: "newItemVm",
      bindToController: true,
      controller: function ($scope, $alert, $q, ProjectItemService, ProjectsService, deviceDetector, $log) {
        var vm = this,
          projectVm = $scope.projectVm;
        vm.name = "newProjectItem";
        vm.images = [];
        vm.pdfs = [];
        vm.item = {
          projectable_type: "",
          projectable: []
        };
        vm.canCreate = true;
        vm.maxFiles = 10;
        vm.maxFileSize = "50MB";
        vm.maxFilesExceeded = false;
        vm.buttonLabel = "Save to project";
        vm.os = deviceDetector.os;
        vm.isMobile = deviceDetector.isMobile();
        vm.isCollapsed = true;
        vm.createProjectItem = createProjectItem;
        vm.cancel = reset;
        vm.disableButtons = disableButtons;
        vm.enableButtons = enableButtons;
        vm.showSaveBtn = showSaveBtn;
        vm.checkMaxFiles = checkMaxFiles;
        vm.addImages = addImages;
        vm.addPdfs = addPdfs;
        vm.removeImg = removeImg;
        vm.removePdf = removePdf;
        vm.showAlert = false;
        vm.alert = {};
        vm.showImgDropzone = true;
        vm.showPdfDropzone = true;

        vm.addItem = addItem;

        $scope.$watch("projectVm.project.status", function() {
          setMsgText();
        });

        if (projectVm.project.items.length === 0) {
          vm.item.projectable_type = null;
        }

        // ------------------ Function declarations ------------------

        function saveProjectItem(item, reenableButtons) {
          ProjectItemService
            .create(projectVm.project.id, item)
            .then(function (res) {
              projectVm.project.items = res.project_items;
              projectVm.project.status = "started";

              if (reenableButtons) {
                vm.showAlert = true;
                angular.element(".alert").show();
                setAlert(item.projectable_type, "success");
                reset();
              }
            }, function () {
              reset();
            });
        }

        function addItem(type) {
          reset();
          vm.item.projectable_type = type;
        }

        function setAlert(type, style) {
          switch (type) {
          case "Image":
            vm.alert = {
              txt: pluralizeImgTxt(vm.images.length),
              style: style,
              icon: style === "success" ? "tick" : "",
              iconColor: "#666666"
            };
            break;
          case "Pdf":
            vm.alert = {
              txt: pluralizePdfTxt(vm.pdfs.length),
              style: style,
              icon: style === "success" ? "tick" : "",
              iconColor: "#666666"
            };
            break;
          default:
            vm.alert = {
              txt: "Your " + type.toLowerCase() + " was successfully added to your project. Way to go.",
              style: style,
              icon: style === "success" ? "tick" : "",
              iconColor: "#666666"
            };
          }
          projectVm.alert = vm.alert;
          projectVm.createAlert(style);
        }

        function setMsgText() {
          if (projectVm.project.status === "complete") {
            vm.masteredMsg = {
              title: "Your project is marked as complete",
              text: "You can un-mark it to continue editing or adding to your project.",
              btnText: "Unmark as complete",
              btnIcon: "close",
              iconColor: "#E9445F"
            };
          } else {
            vm.masteredMsg = {
              title: "Finished your project?",
              text: "Mark it as completed. You can always return to add more later.",
              btnText: "Mark complete",
              btnIcon: "tick",
              iconColor: "#666666"
            };
          }
        }

        function createProjectItem() {
          disableButtons();
          saveProjectItem(vm.item, true);
        }

        function disableButtons() {
          vm.canCreate = false;
          vm.buttonLabel = "Saving...";
          vm.showImgDropzone = false;
          vm.showPdfDropzone = false;
        }

        function showSaveBtn() {
          return ['Image', 'Pdf'].indexOf(vm.item.projectable_type) < 0;
        }

        function enableButtons() {
          vm.canCreate = true;
          vm.buttonLabel = "Save to project";
          vm.maxFilesExceeded = false;
          vm.showImgDropzone = true;
          vm.showPdfDropzone = true;
        }

        function reset() {
          switch (vm.item.projectable_type) {
          case "Image":
            vm.images = [];
            vm.showImgDropzone = true;
            break;
          case "Pdf":
            vm.pdfs = [];
            vm.showPdfDropzone = true;
            break;

          // no default
          }

          vm.enableButtons();
          vm.item = {
            projectable: []
          };
          vm.invalidFiles = [];
        }

        function checkMaxFiles() {
          switch (vm.item.projectable_type) {
          case "Image":
            vm.maxFilesExceeded = vm.images.length > vm.maxFiles;
            break;
          case "Pdf":
            vm.maxFilesExceeded = vm.pdfs.length > 1;
            break;

          // no default
          }
          return vm.maxFilesExceeded;
        }

        //
        // Images related mathods
        //

        function pluralizeImgTxt(num) {
          switch (num) {
          case 0:
            return "No images were successfully added to your project.";
          case 1:
            return "1 image was successfully added to your project. Nice one.";
          default:
            return num + " images were successfully added to your project. Nice one.";
          }
        }

        function addImages() {
          var reenableButtons = false,
            chain = $q.when(),
            i = 0;

          // vm.images is populated by the file upload component via ng-model
          if (vm.images.length <= vm.maxFiles) {
            vm.maxFilesExceeded = false;
            vm.item.projectable = vm.item.projectable.concat(vm.images);

            disableButtons();

            for (i = 0; i < vm.images.length; i++) {
              (function (index) { /* jshint ignore:line */ // eslint-disable-line wrap-iife
                chain = chain.then(function () {
                  vm.item.projectable.image = vm.images[index];
                  vm.imgIndex = index;
                  vm.isCollapsed = false;
                  return ProjectItemService
                    .create(projectVm.project.id, vm.item)
                    .then(function (res) {
                      projectVm.project.items = res.plain().project_items;
                      reenableButtons = index === vm.images.length - 1;
                      projectVm.project.status = "started";

                      if (reenableButtons) {
                        vm.showAlert = true;
                        angular.element(".alert").show();
                        setAlert(vm.item.projectable_type, "success");
                        if (vm.invalidFiles.length === 0) {
                          reset();
                        } else {
                          vm.images = [];
                          vm.showImgDropzone = true;
                        }
                      }
                    });
                }, function(error) {
                  $log.error(error);
                  return error;
                });
              })(i);
            }
          } else {
            vm.images = [];
            vm.maxFilesExceeded = true;
          }
        }

        function removeImg(index) {
          vm.images.splice(index, 1);
          if (vm.images.length === 0) {
            vm.item.projectable = [];
          }
        }

        //
        // PDFs related methods
        //

        function pluralizePdfTxt(num) {
          switch (num) {
          case 0:
            return "No PDFs were successfully added to your project.";
          case 1:
            return "1 PDF was successfully added to your project. Nice one.";
          default:
            return num + " pdfs were successfully added to your project. Nice one.";
          }
        }

        function addPdfs() {
          var reenableButtons = false,
            chain = $q.when(),
            i = 0;
          // vm.pdfs array is populated by the file upload component via ng-model
          if (vm.pdfs.length <= vm.maxFiles) {
            vm.maxFilesExceeded = false;
            vm.item.projectable = vm.item.projectable.concat(vm.pdfs);

            disableButtons();

            for (i = 0; i < vm.pdfs.length; i++) {
              (function (index) { /* jshint ignore:line */ // eslint-disable-line wrap-iife
                chain = chain.then(function () {
                  vm.item.projectable.pdf = vm.pdfs[index];
                  vm.pdfIndex = index;
                  vm.isCollapsed = false;
                  return ProjectItemService
                    .create(projectVm.project.id, vm.item)
                    .then(function (res) {
                      projectVm.project.items = res.plain().project_items;
                      reenableButtons = index === vm.pdfs.length - 1;
                      projectVm.project.status = "started";

                      if (reenableButtons) {
                        vm.showAlert = true;
                        angular.element(".alert").show();
                        setAlert(vm.item.projectable_type, "success");
                        if (vm.invalidFiles.length === 0) {
                          reset();
                        } else {
                          vm.pdfs = [];
                          vm.showPdfDropzone = true;
                        }
                      }
                    });
                }, function(error) {
                  $log.error(error);
                  return error;
                });
              })(i);
            }
          } else {
            vm.pdfs = [];
            vm.maxFilesExceeded = true;
          }
        }

        function removePdf(index) {
          vm.pdfs.splice(index, 1);
          if (vm.pdfs.length === 0) {
            vm.item.projectable = [];
          }
        }
      }
    };
  }
}());
