(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name directives.directive:comments
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="directives">
       <file name="index.html">
        <comments></comments>
       </file>
     </example>
   *
   */
  angular
    .module('directives')
    .directive('comments', comments);

  function comments() {
    var directive = {
      restrict: 'EA',
      scope: {
        commentableId: '=',
        commentableType: '=',
        urlToFeed: '@',
        programId: '='
      },
      bindToController: true,
      replace: false,
      templateUrl: 'directives/comments/comments-directive.tpl.html',
      controllerAs: 'commentsVm',
      controller: ['CommentsService', '$location', '$log', CommentsController],
      link: function(scope, element, attrs, ctrl) {
        if (angular.isDefined(ctrl.pointedCommentId)) {
          angular.element('html, body').animate({
            scrollTop: angular.element(element).offset().top + 'px'
          }, 1000);
        }
      }
    };

    return directive;
  }

  function CommentsController(CommentsService, $location, $log) {
    var vm = this;

    vm.pointedCommentId = $location.search().comment;
    vm.commentsPage = $location.search().page;

    vm.loadRecent = function(page) {
      vm.fetchingRecent = true;
      CommentsService.get(vm.urlToFeed, page).then(function(result) {
        vm.fetchingRecent = false;
        vm.meta.prev_page = result.meta.prev_page; /* jshint ignore:line */ // eslint-disable-line camelcase
        vm.comments = _.union(result.comments, vm.comments);
        return;
      }, function(error) {
        $log.error(error);
        return error;
      });
    };

    vm.loadNext = function(page) {
      vm.fetchingNext = true;
      CommentsService.get(vm.urlToFeed, page).then(function(result) {
        vm.fetchingNext = false;
        vm.meta.next_page = result.meta.next_page; /* jshint ignore:line */ // eslint-disable-line camelcase
        vm.comments = _.union(vm.comments, result.comments);
        return;
      }, function(error) {
        $log.error(error);
        return error;
      });
    };

    load(vm.commentsPage);

    return vm;

    function load(page) {
      vm.loading = true;
      CommentsService.get(vm.urlToFeed, page).then(function(result) {
        vm.loading = false;
        vm.comments = result ? result.comments : null;
        vm.meta = result ? result.meta : null;
        return;
      }, function(error) {
        $log.error(error);
        return error;
      });
    }
  }
}());
