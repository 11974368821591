(function () {
  "use strict";

  angular
    .module("workbook")
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state("workbooks", {
        parent: "program",
        url: "/workbooks/:workbookId",
        templateUrl: "workbook/home.tpl.html",
        resolve: {
          CurrentUser: "CurrentUser",
          currentUser: function (CurrentUser) {
            return CurrentUser.storeUser();
          }
        },
        controller: "WorkbookCtrl",
        controllerAs: "workbookVm"
      })
      .state("workbooks_home", {
        parent: "program",
        url: "^/home",
        templateUrl: "workbook/home.tpl.html",
        controller: "WorkbookCtrl",
        controllerAs: "workbookVm"
      })
      .state("workbook", {
        parent: "part",
        url: "/workbooks/:workbookId",
        templateUrl: "workbook/home.tpl.html",
        resolve: {
          CurrentUser: "CurrentUser",
          currentUser: function (CurrentUser) {
            return CurrentUser.storeUser();
          }
        },
        controller: "WorkbookCtrl",
        controllerAs: "workbookVm"
      });
  }
}());
