(function () {
  "use strict";

  angular
    .module("directives")
    .directive("editReflection", editReflection);

  function editReflection($document, $timeout) {
    return {
      restrict: "E",
      templateUrl: "directives/workbooks/edit-reflection-directive.tpl.html",
      scope: {
        reflection: "=",
        removeFromWorkbook: "&",
        updateWorkbook: "&"
      },
      link: function (scope, element, attrs, ctrl) {
        $timeout(function () {
          if (angular.isDefined(ctrl.pointedTupleId) &&
            "tuple-" + ctrl.pointedTupleId === attrs.id) {
            angular.element(element).css('opacity', 0);
            angular.element("html, body").animate({
              scrollTop: angular.element(element).offset().top + "px"
            }, "slow", function () {
              angular.element(element).addClass("flash");
            });
          }
        });
      },
      bindToController: true,
      replace: true,
      controllerAs: "editReflectionVm",
      controller: function ($location) {
        var vm = this;
        vm.editing = false;
        vm.menuArr = [];
        vm.showActionGroup = false;
        vm.buffer = angular.copy(vm.reflection);
        vm.pointedTupleId = $location.search().tuple;
        vm.taHeight = 36;
        vm.origHeight = 36;

        vm.update = update;
        vm.reset = reset;

        buildMenu();
        setAuthorTitle();
        reset();

        return vm;

        // ------------------ Function Declarations

        function update() {
          vm.updateWorkbook(vm.reflection);
          vm.buffer.body = vm.reflection.body;
          vm.taHeight = vm.origHeight + 10;
          vm.editing = false;
        }

        function reset() {
          vm.editing = false;
          vm.reflection.body = vm.buffer.body;
          vm.taHeight = vm.origHeight + 10;
        }

        function setAuthorTitle() {
          if (vm.reflection.is_author) {
            vm.authorTitle = "<strong>You</strong>";
          } else if (vm.reflection.author.role) {
            vm.authorTitle = "<strong>" + vm.reflection.author.name + "</strong> (" + vm.reflection.author.role + ")";
          } else {
            vm.authorTitle = "<strong>" + vm.reflection.author.name + "</strong>";
          }
        }

        function buildMenu() {
          if (vm.reflection.allow_update === true) {
            vm.menuArr.push({
              name: "Edit",
              iconClass: "icon icon-edit",
              action: function () {
                vm.editing = true;
              }
            });
          }

          if (vm.reflection.allow_destroy === true) {
            vm.menuArr.push({
              name: "Delete",
              iconClass: "fa fa-trash",
              action: function () {
                vm.removeFromWorkbook();
              },
              confirm: "Are you sure you want to delete this?"
            });
          }
        }
      }
    };
  }
}());
