(function () {
  'use strict';

  /* @ngdoc object
   * @name courseOutline.briefsOutline
   * @description
   *
   */
  angular
    .module('courseOutline.briefsOutline', [
      'ui.router'
    ]);
}());
