(function () {
  'use strict';

  angular
    .module('directives')
    .directive('mentions', mentions);

  function mentions(EnrolleesService, $stateParams) {
    return {
      restrict: 'A',
      link: function(scope, element, attrs, vm) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        angular.element(element).atwho({
          at: '@',
          searchKey: 'searchKey',
          minLen: 3,
          limit: 50,
          insertTpl: '${atwho-at}${username}',
          displayTpl: '<li><img src="${avatar_square_url}" width="38" height="40"> ${name} (${atwho-at}${username})</li>',
          callbacks: {
            remoteFilter: function(query, callback) {
              var programId = $stateParams.programId; // limit results if in program's scope

              EnrolleesService.get(programId, query).then(function(res) {
                callback(res.enrollees);
              });
            }
          }
        });
      }
    };
  }
}());
