(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name directives.directive:msIcon
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="directives">
   <file name="index.html">
   <ms-icon icontype="'part'" num=22 disabled=false feedback=true></ms-icon>
   </file>
   </example>
   *
   */
  angular
    .module('directives')
    .directive('msIcon', msIcon);

  function msIcon() {
    return {
      restrict: 'EA',
      scope: {
        icontype: '=',
        num: '=?',
        disabled: '=?',
        feedback: '=?',
        watchIcon: '=?',
        iconColor: '=?'
      },
      templateUrl: 'directives/ms-icon-directive.tpl.html',
      replace: false,
      controllerAs: 'msIcon',
      controller: function () {
        var vm = this;
        vm.name = 'msIcon';
      },
      link: function (scope, element, attrs, vm) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        vm.icontype = scope.num ? 'part' + scope.num : scope.icontype;
        vm.feedback = scope.feedback ? 'fb' : '';
        if (scope.disabled) {
          vm.iconStyle = {color: '#bdbdbd', borderColor: '#bdbdbd'};
        } else if (scope.iconColor) {
          vm.iconStyle = {color: scope.iconColor, borderColor: scope.iconColor};
        }

        vm.iconColor = scope.iconColor || null;
        if (scope.watchIcon) {
          scope.$watch('icontype', function (newValue) {
            vm.icon = 'icon-' + newValue;
            if (!scope.disabled && scope.iconColor) {
              vm.iconStyle = {color: scope.iconColor, borderColor: scope.iconColor};
            }
          });
        } else {
          vm.icon = 'icon-' + vm.icontype + vm.feedback;
        }
      }
    };
  }
}());
