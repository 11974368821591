(function() {
  'use strict';

  angular
    .module('classroom')
    .service('UsersService', UsersService);

  function UsersService(AuthService) {
    var self = {
      get: function(userId) {
        return AuthService.apiService()
          .one('users', userId)
          .get();
      },
    };

    return self;
  }
}());
