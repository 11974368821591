(function () {
  "use strict";

  /**
   * @ngdoc filter
   * @name filters.filter:msOrFilter
   *
   * @description
   *
   * AngularJS default filter with the following expression:
   * "person in people | filter: {name: $select.search, age: $select.search}"
   * performs an AND between 'name: $select.search' and 'age: $select.search'.
   * This performs an OR when used like:
   * "person in people | propsFilter: {name: $select.search, age: $select.search}"
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module("filters")
    .filter("msOrFilter", msOrFilter);

  function msOrFilter() {
    return function (items, props) {
      var out = [],
        keys = Object.keys(props);
      if (angular.isArray(items)) {
        items.forEach(function (item) {
          var itemMatches = false,
            i,
            prop,
            text;
          for (i = 0; i < keys.length; i++) {
            prop = keys[i];
            text = props[prop].toLowerCase();
            if (item[prop].toString() === "staticOption" || item[prop].toString().toLowerCase().indexOf(text) !== -1) {
              itemMatches = true;
              break;
            }
          }
          if (itemMatches) {
            out.push(item);
          }
        });
      } else {
        // Let the output be the input untouched
        out = items;
      }
      return out;
    };
  }
}());
