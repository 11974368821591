<div class="row project-content" ng-if="itemEditVm.type !== 'Text'">
  <div class="col-xs-12 col-sm-10 col-sm-offset-1">
    <section class="project">
      <div ng-bind-html="itemEditVm.editable | trustAsHtml" class="project-item"></div>
    </section>
  </div>
</div>

<div class="row project-edit">
  <form name="itemEditVm.itemForm">
    <div class="col-xs-12 new-project-item-container">
      <div ng-switch="itemEditVm.type">
        <div ng-switch-when="Image" project-item-image></div>
        <div ng-switch-when="Text" project-item-text></div>
        <div ng-switch-when="Video" project-item-video></div>
        <div ng-switch-when="Pdf" project-item-pdf></div>
      </div>

      <div class="save-project-button-holder">
        <button ng-click="itemEditVm.save()"
                ng-disabled="!itemEditVm.canSave || itemEditVm.itemForm.$invalid"
                class="btn btn-black pull-right">
          Save
        </button>
        <button ng-click="itemEditVm.cancel()" class="btn btn-default pull-right">Cancel</button>
      </div>
    </div>
  </form>
</div>
