<!-- Desktop -->
<div>
  <div class="lessons-list">
    <div class="lessons-list-item" ng-repeat="lesson in lessonMenuVm.lessons" ui-sref-active="active">
      <a class="lesson-link ms-flex-container" ui-sref="^.lesson({lessonId: lesson.id })">
        <div class="thumbnail-wrapper ms-flex-item" ng-if="lesson.image_url">
          <span ms-icon icontype="'video'" ng-if="lesson.icon == 'video'" class="video-icon"></span>
          <img ng-src="{{lesson.image_url}}" class="thumbnail-img">
        </div>
        <div class="lesson-title ms-flex-item" ng-class="{'no-padding': !lesson.image_url}">
          {{lesson.name}}
        </div>
      </a>
      <a class="cta-link ms-flex-container" ui-sref="^.projects({ lessonId: lesson.id })" ng-if="lesson.task && lesson.project_index_btn_text">
        <div class="thumbnail-wrapper ms-flex-item">
          <img ng-src="images/icon-peer-projects.svg" class="thumbnail-img">
        </div>
        <div class="cta-title ms-flex-item">
          {{lesson.project_index_btn_text}}
        </div>
      </a>
    </div>
  </div>
</div>
