<div class="expandable-textarea">
  <textarea class="text-area animated"
            id="textarea-{{msExpandableTextareaVm.itemId}}"
            ng-model="msExpandableTextareaVm.ngModel"
            ng-model-options="{ debounce: { 'default': 250 } }"
            placeholder="{{msExpandableTextareaVm.placeholder}}"
            ng-keyup="msExpandableTextareaVm.autoExpand($event)"
            ng-focus="msExpandableTextareaVm.autoExpand($event)"
            ng-style="{height: msExpandableTextareaVm.taHeight + 'px'}">
  </textarea>
</div>
