<div class="form-group">
  <label>Add a video</label>
  <p class="help-block">Paste in the website address (URL) of any YouTube or Vimeo video page</p>
  <input placeholder="e.g: https://www.youtube.com/watch?v=95Aq8QFYRgo"
         type="text"
         name="projvid"
         ng-model="vm.item.data"
         class="form-control new-project-video"
         disable-validation-message=""
         ng-pattern="/https?:\/\/(www\.)?(youtube|vimeo)\.com\/.+/"
         required>
  <i class="file-error" ng-show="imgForm.projvid.$error.pattern">Please enter a valid Youtube or Vimeo link.</i>
</div>
