<div class="project-page">
  <div class="container-fluid">
    <div id="ms-tag-alert" class="tagging-alert" ng-if="true"></div>
    <div class="edit-msg-holder" ng-if="projectVm.showEditControls"></div>
    <div class="mastered-msg-box edit-project-msg" ng-if="projectVm.showEditControls">
      <div class="vertical-align row visible-xs-block">
        <div class="text-col col-xs-6">
          <div class="mastered-msg-text">
            <strong>Edit your project</strong><br>
          </div>
        </div>
        <div class="complete-col col-xs-6">
          <div class="btn btn-default mastered-msg-btn pull-right" ng-click="projectVm.editProjects()">
            Done
          </div>
        </div>
      </div>

      <div class="container-fluid hidden-xs">
        <div class="vertical-align row">
          <div class="col-sm-4 col-sm-offset-4">
            <div class="mastered-msg-text center-block">
              <strong>Edit your project</strong><br>
            </div>
          </div>
          <div class="complete-col col-sm-2 col-sm-offset-2">
            <div class="btn btn-default mastered-msg-btn pull-right" ng-click="projectVm.editProjects()">
              Done
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="project-banner-bg hidden-xs" ng-class="{'blurred': !projectVm.project.banner, 'editing': projectVm.showEditControls}">
      <img class="project-banner-img" ng-src="{{projectVm.project.banner}}">
    </div>

    <div class="row project-top-row vertical-align" ng-if="projectVm.project" ng-cloak ng-class="{'editing': projectVm.showEditControls}">
      <div class="col-xs-11 col-sm-6"
           ng-class="{'blurred': !projectVm.project.banner, 'col-sm-12': projectVm.showEditControls || projectVm.ownProject === false, 'own-project': projectVm.ownProject}">
        <div class="project-byline-holder" ng-class="{'editing': projectVm.showEditControls}">
          <h3 ng-if="projectVm.showEditControls === false">{{projectVm.project.title}}</h3>

          <div ng-if="projectVm.showEditControls === true" class="">
            <form name="projectVm.projectTitleForm">
              <div class="form-group row">
                <div class="col-xs-12 title-input-col">
                  <input placeholder="Enter your project's title here"
                         type="text"
                         name="projtit"
                         ng-model="projectVm.project.title"
                         class="form-control project-title-input"
                         disable-validation-message=""
                         minlength="3"
                         required>

                  <i class="file-error" ng-show="projectTitleForm.projtit.$error">Please enter your project title.</i>
                </div>
              </div>
            </form>
          </div>

          <div class="project-banner-byline" ng-if="projectVm.project.user_fullname" ng-cloak>
            in
            <a ui-sref="^.lesson({ lessonId: projectVm.project.program_part.latest_lesson_id })">
              <strong>{{projectVm.project.program_part.name}}</strong>
            </a>
            |
            <a href ng-click="projectVm.scrollTo('comments-holder')">
              <div ms-icon icontype="'comment'" class="comment-icon"></div>
              <strong>{{projectVm.project.comments_count}}</strong>
            </a>
            <span ng-show="projectVm.project.status === 'complete'" class="project-complete">
          |
          <div ms-icon icontype="'tick'" iconColor="" class="tick-icon"></div>
          <strong>Complete</strong>
        </span>
          </div>
        </div>
      </div>

      <!-- Desktop view -->
      <div class="hidden-xs col-xs-2 col-sm-6 switches-group"
           ng-if="projectVm.isEditAction()">
        <div class="col-sm-5 vertical-align">
          <label class="switch">
            <input type="checkbox"
                   name="isCompleted"
                   ng-model="projectVm.isCompleted"
                   ng-change="projectVm.toggleComplete()"
                   id="isCompleted">
            <span class="slider round"></span>
          </label>
          <span class="prompt"
                ng-bind="projectVm.completePrompt()"
                ng-click="projectVm.toggleComplete()"></span>
        </div>
        <div class="col-sm-5 vertical-align">
          <label class="switch">
            <input type="checkbox"
                   name="isShared"
                   ng-model="projectVm.isShared"
                   ng-change="projectVm.toggleShared()"
                   id="isShared">
            <span class="slider round"></span>
          </label>
          <span class="prompt"
                ng-bind="projectVm.sharedPrompt()"
                ng-click="projectVm.toggleShared()">
      </span>
        </div>
        <button ng-click="projectVm.editProjects()" class="btn btn-default edit-project-btn col-sm-2 edit-project-btn-holder">
          <strong ng-if="!projectVm.showEditControls">Edit</strong>
        </button>
      </div>

      <!-- Mobile view -->
      <div class="visible-xs col-xs-1 three-dots" ng-if="projectVm.isEditAction()">
        <h3>
          <ms-three-dots-menu menu-arr="projectVm.topMnuMobileArr"></ms-three-dots-menu>
        </h3>
      </div>
    </div>

    <ms-project-collaborations lesson-id="{{projectVm.lessonId}}"
                               ng-if="projectVm.ownProject && projectVm.lesson.networkMaterial"></ms-project-collaborations>

    <div class="row collaborator-row" ng-if="projectVm.project" ng-cloak ng-class="{'editing': projectVm.showEditControls}">
      <div class="col-xs-12 col-sm-4 user-detail-column" ng-if="projectVm.project.user_fullname" ng-cloak>
        <collaborator-item
          class="collaborator-item"
          user-profile-url="projectVm.projectAuthor.profile_url"
          user-avatar-url="projectVm.projectAuthor.avatar_url"
          user-fullname="projectVm.ownProject? 'You': projectVm.projectAuthor.name"
          user-discipline="projectVm.project.discipline"
          user-title="'(Author)'"
          user-course="projectVm.projectAuthor.current_course">
        </collaborator-item>
      </div>

      <div class="col-xs-12 col-sm-4 user-detail-column" ng-repeat="collaborator in projectVm.collaborators" ng-cloak>
        <collaborator-item ng-if="projectVm.project.user_fullname"
                           class="collaborator-item"
                           user-profile-url="collaborator.profile_url"
                           user-avatar-url="collaborator.avatar_square_url"
                           user-fullname="collaborator.name"
                           user-discipline="collaborator.discipline"
                           user-course="collaborator.current_course">
        </collaborator-item>
      </div>

      <div class="col-xs-12 col-sm-4 user-detail-column" ng-if="projectVm.project.user_fullname && projectVm.ownProject && projectVm.lesson.networkMaterial" ng-cloak>
        <div class="collaborator-item">
          <div class="collab-holder">
            <a ui-sref="^.tag-collaborators({lessonId: projectVm.lessonId, projectId: projectVm.project.id})">
              <div class="vertical-align">
                <div class="flex2">
                  <img class="img-circle img-responsive user-avatar" ng-src="images/default_avatar.png">
                </div>
                <div class="flex10 name-col">
                  <div class="name-bit"><strong>Add/edit collaborators</strong></div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="clearfix visible-xs-block"></div>

    <div class="row project-content item" ng-if="projectVm.project" ng-repeat="item in projectVm.project.items" ng-animate="'item'">
      <div ng-class="projectVm.showEditControls ? 'col-xs-10 col-sm-9' : 'col-xs-12 col-sm-10'" class="col-sm-offset-1">
        <section class="project">
          <div ng-if="item.projectable.body" ng-bind-html="item.projectable.body | trustAsHtml" class="project-item"></div>
          <div ng-if="item.projectable.text" ng-bind-html="item.projectable.text | trustAsHtml" class="project-item"></div>
        </section>
      </div>
      <div ng-class="projectVm.showEditControls ? 'col-xs-2 col-sm-1' : 'hidden'" class="project-controls-col" ng-if="projectVm.ownProject === true">
        <div class="project-item-controls">
          <div ng-repeat="menuAction in projectVm.menuArr[item.id] track by $index"
               ng-click="projectVm.callFunction(menuAction)"
               class="menu-click">
            <div ng-if="menuAction.name === 'Divider'" class="divider-horizontal"></div>
            <span ng-if="menuAction.name !== 'Divider'">
              <i class="{{ menuAction.iconClass }}"></i>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="row project-edit" ng-if="projectVm.ownProject === true  && projectVm.project.id">
      <new-project-item></new-project-item>
    </div>

    <div class="row project-comments">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xs-12 col-sm-10 col-sm-offset-1">
            <section ng-if="projectVm.project.accepts_comment">
              <div class="comments__wrapper" id="comments-holder">
                <h3 id="comments" ng-if="projectVm.lesson.networkMaterial">Discuss this project</h3>
                <comments
                  url-to-feed="projects/{{projectVm.project.id}}/comments"
                  commentable-id="projectVm.project.id"
                  commentable-type="'Project'">
                </comments>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<script type="text/ng-template" id="template/alert/tag_alert.html">
  <div class='alert' ng-class='type && "alert-" + type'>
    <button type="button" class="close" ng-click="$hide()">&times;</button>
    <span ms-icon class="alert-icon" icontype="projectVm.alert.icon" icon-color="projectVm.alert.iconColor"></span>
    <span ng-bind-html="content"></span>
  </div>
</script>
