<div class="ms-banners">
  <div ng-repeat='banner in msBannersVm.banners track by $index'>
    <div class="banner">
      <div class="container-fluid">
        <div class="row valign">
          <div class="banner__body col-xxs-12 col-md-9">
            <div class="banner__body__inner" ng-bind-html="banner.html"></div>
          </div>
          <div class="banner__button col-xxs-12 col-md-3">
            <button ng-click="msBannersVm.go(banner)"
                    class="btn btn--narrow btn--white text-centered">
              {{banner.button_label}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
