<div>
  <div class="thumbnail-holder fade-element-in" ng-if="newItemVm.images[0].name">
    <div ng-repeat="image in newItemVm.images" class="upload-img-thumbnail-list fade-element-in">
      <div class="thumb-title">
        <div class="thumb-holder" ng-if="$index === newItemVm.imgIndex">{{image.name}} {{image.$error}} {{image.$errorParam}} {{image.progress}}</div>
      </div>
    </div>
    <div class="thumb-title" id="loading-bar-container"></div>
  </div>

  <div class="alert alert-dismissible alert-danger" ng-repeat="f in newItemVm.invalidFiles" ng-if="!newItemVm.maxFilesExceeded" ng-cloak>
    <span ng-if="f.$error === 'maxSize'">Sorry, <strong>{{f.name}}</strong> is over {{f.$errorParam}} in size. Please resize or pick another file.</span>
    <span ng-if="f.$error === 'pattern'">Sorry, we don't support the file type of <strong>{{f.name}}</strong>. Please try a JPG, PNG or GIF.</span>
  </div>
  <div class="alert alert-dismissible alert-danger" ng-if="newItemVm.maxFilesExceeded">
    Woah there! You can only upload a maximum of {{newItemVm.maxFiles}} images at a time. Please try again with fewer files.
  </div>

  <div ng-show="!itemEditVm  && newItemVm.showImgDropzone"
       class="form-group img-upload-form"
       ngf-drop="newItemVm.addImages()"
       ngf-select="newItemVm.addImages()"
       ng-model="newItemVm.images"
       ngf-drag-over-class="'file-over'"
       ngf-multiple="true"
       accept="image/*"
       ngf-pattern=".jpg,.jpeg,.png,.gif"
       ngf-keep="distinct"
       ngf-validate="{size: {max: newItemVm.maxFileSize, min: '10B'}, duration: {max: '2m'}}"
       ngf-model-invalid="newItemVm.invalidFiles">

    <div class="dropzone-holder">
      <div class="img-drop-zone">
        <div class="drop-zone-label">
          <div class="dropzone-img-icon" ms-icon icontype="'image'"></div>
          <h4 class="hidden-xs">Drag files here</h4>
          <h4 class="visible-xs">Click to select files</h4>
          <div class="drop-zone-sub hidden-xs">
            (or click to select)
          </div>
          <div class="drop-zone-rules">
            {{newItemVm.maxFileSize}} limit per image<br>
            JPG, PNG or GIFs<br>
            Up to {{newItemVm.maxFiles}} images at a time
          </div>
        </div>
      </div>
    </div>
  </div>

  <div ng-if="itemEditVm" class="form-group">
    <label>Add a caption</label>
    <p class="help-block">Optional</p>
    <textarea ng-model="itemEditVm.item.caption" class="form-control"></textarea>
    <br/>
    <input type="checkbox" ng-model="itemEditVm.item.cover">
    <span>Set as cover image</span>
  </div>
</div>
