<div class="projects-page">
  <div class="container-fluid">
    <div class="row" ng-cloak>
      <div class="projects col-xs-12" ng-if="projectsVm.projects.length >= 1">
        <h3>{{projectsVm.title}}</h3>
        <div class="projects-byline">
          in
          <a ui-sref="^.lesson({ lessonId: projectsVm.programPart.lessonId })">
            <strong>{{projectsVm.programPart.name}}</strong>
          </a>
        </div>

        <!--For iPhone 5 or earlier-->
        <ul class="projects-list row"
            ag-id="projects-grid"
            ng-if="projectsVm.oldiPhone && projectsVm.projects.length >= 1"
            ng-cloak>

          <li ng-repeat="project in projectsVm.projects" class="project-list-item col-sm-12 col-md-4">
            <a
              ui-sref="^.project({ lessonId: projectsVm.lessonId, projectId: project.id, userAvatar: project.user_avatar_url })"
              class="project-grid-item">
              <div class="project__thumb-wrapper">
                <div class="project__thumb-image-wrapper">
                  <span ng-if="project.thumbnail"><img class="project-thumb-image" ng-src="{{project.thumbnail}}"></span>
                  <div ng-if="!project.thumbnail" class="project__thumb-image-fallback">
                    <div class="project__thumb-image-fallback--gradient"></div>
                    <img ng-src="/classroom/images/logox2.png" width="25" height="44.2">
                  </div>
                </div>
                <div class="project__thumb-text-wrapper">
                  <img class="img-circle" ng-src="{{project.user_avatar_url}}">
                  <div class="project__thumb-text">
                    <strong>{{project.user_fullname}}</strong>
                    {{project.activity}}
                    <br/>
                    <span am-time-ago="project.updated_at" class="project__thumb-text--time-ago"></span>
                  </div>
                </div>
              </div>
            </a>
          </li>
        </ul>

        <!--All other devices-->
        <ul class="projects-list row"
            angular-grid="projectsVm.projects"
            ag-id="projects-grid"
            ng-if="!projectsVm.oldiPhone  && projectsVm.projects.length >= 1"
            ng-cloak>

          <li ng-repeat="project in projectsVm.projects" class="project-list-item col-xs-12 col-md-4">
            <a ui-sref="^.project({
           lessonId: projectsVm.lessonId,
           projectId: project.id,
           userAvatar: project.user_avatar_url
        })" class="project-grid-item" target="_blank">
              <div class="project__thumb-wrapper">
                <div class="project__thumb-image-wrapper">
                  <span ng-if="project.thumbnail"><img class="project-thumb-image animate" ng-src="{{project.thumbnail}}" data-noretina></span>
                  <div ng-if="!project.thumbnail" class="project__thumb-image-fallback">
                    <div class="project__thumb-image-fallback--gradient"></div>
                    <img ng-src="/classroom/images/logox2.png" width="25" height="44.2">
                  </div>
                </div>
                <div class="project__thumb-text-wrapper">
                  <img class="img-circle" ng-src="{{project.user_avatar_url}}">
                  <div class="project__thumb-text">
                    <strong>{{project.user_fullname}}</strong>
                    {{project.activity}}
                    <br/>
                    <span am-time-ago="project.updated_at" class="project__thumb-text--time-ago"></span>
                  </div>
                </div>
              </div>
            </a>
          </li>
        </ul>

      </div>
    </div>
    <div class="row">
      <div class="project-control-holder center-block">
        <span us-spinner="{left: '50%', radius: 10, width: 8}" ng-if="projectsVm.fetchingProjects && projectsVm.moreToLoad"></span>
        <button class="btn btn-black load-more" ng-click="projectsVm.paginate()" ng-if="projectsVm.moreToLoad && projectsVm.showMoreButton">
          Load more
        </button>
      </div>
    </div>
  </div>
</div>
