<div>
  <div class="form-group" ng-if="!itemEditVm">
    <label>Add some text</label>
    <div
      text-angular
      ng-model="newItemVm.item.projectable"
      placeholder="Start typing..."></div>
  </div>

  <div class="form-group" ng-if="itemEditVm">
    <label>Edit your text</label>
    <div
      text-angular
      ng-model="itemEditVm.item.projectable.text"
      placeholder="Start typing..."></div>
  </div>
</div>
