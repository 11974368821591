<div>
  <div class="form-group" ng-if="!itemEditVm">
    <label>Add a video</label>

    <p class="help-block">Paste in the website address (URL) of any YouTube or Vimeo video page</p>

    <input placeholder="e.g: https://www.youtube.com/watch?v=95Aq8QFYRgo"
           type="text"
           name="projvid"
           ng-model="newItemVm.item.projectable"
           class="form-control new-project-video"
           disable-validation-message=""
           ng-pattern="/https?:\/\/(www\.)?(((m.)?youtube|vimeo)\.com|youtu.be)\/.+/"
           required>

    <i class="file-error" ng-show="itemForm.projvid.$error.pattern">Please enter a valid Youtube or Vimeo link.</i>
  </div>

  <div class="form-group" ng-if="itemEditVm">
    <label>Update or replace your video</label>

    <p class="help-block">Paste in the website address (URL) of any YouTube or Vimeo video page</p>

    <input placeholder="e.g: https://www.youtube.com/watch?v=95Aq8QFYRgo"
           type="text"
           name="projvid"
           ng-model="itemEditVm.item.projectable.embed_video_url"
           class="form-control new-project-video"
           ng-pattern="/https?:\/\/(www\.)?(((m.)?youtube|vimeo)\.com|youtu.be)\/.+/"
           disable-validation-message=""
           required>

    <i class="file-error" ng-show="itemForm.projvid.$error.pattern">Please enter a valid Youtube or Vimeo link.</i>
  </div>
</div>
