<div ng-if="!entity.is_deleted || entity.show_deleted">

  <div class="row">
    <div ng-if="entity.show_deleted">
      <div class="col-xs-1 avatar-col">
        <div class="comment__avatar--wrapper">
          <img class="img-circle img-responsive user-avatar"
               ng-src="images/default_avatar.png">
        </div>
      </div>

      <div>
        <span class="comment__restore-info">Original comment deleted by author</span>
      </div>

      <div class="comment__links">
        <a href=""
           ng-if="entity.allow_restore"
           ng-click="commentVm.restore()">Restore comment</a>
      </div>
    </div>

    <div ng-if="!entity.is_deleted">
      <div  class="col-xs-1 avatar-col">
        <div class="comment__avatar--wrapper">
          <a href="{{entity.user.profile_url}}"
             target="_blank">
            <img ng-src="{{entity.user.avatar_square_url}}"
                 class="comment__avatar img-circle">
            <img ng-if="entity.user.staff_badge"
                 ng-src="{{entity.user.staff_badge}}"
                 class="logo-stuff hidden-xs">
            <span ng-if="entity.user.expert_badge"
                  class="expert-badge hidden-xs">EXPERT</span>
            <span ng-if="entity.user.alumni"
                  class="alumni-badge hidden-xs">ALUMNI</span>
          </a>
        </div>
      </div>

      <div class="col-xs-11 comment-col">
        <div class="comment__body--wrapper">
          <div ng-if="entity.is_deleted===false">
            <span class="comment__author">
              <a href="{{entity.user.profile_url}}" target="_blank">
                {{entity.user.name}}
              </a>
            </span>
            <span class="comment__author--info hidden-xs"
                  ng-if="entity.user.role"> | {{entity.user.role}}</span>
            <br/>
            <span class="comment__body"
                  ng-bind-html="entity.html | trustAsHtml"
                  ms-expandable></span>
          </div>

          <div class="comment__links">
            <span am-time-ago="entity.created_at"
                  class="comment__time-ago"></span>
            <span ng-if="entity.allow_delete"
                  class="separator">|</span>
            <a ng-if="entity.allow_delete"
               href=""
               class="comment__link-delete"
               ng-click="commentVm.remove()">Delete</a>
            <span class="separator comment__link-reply">|</span>
            <a class="comment__link-reply"
               href=""
               ng-click="commentVm.addNewCommentForm()">Reply</a>
          </div>

          <div class="comment__new"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-11 col-xs-offset-1">
      <div class="comment__replies">
        <comment 
          class="comment"
          id="comment-{{reply.id}}"
          entity="reply"
          program-id="{{commentVm.programId}}"
          ng-repeat="reply in entity.replies"></comment>
      </div>
    </div>
  </div>

</div>
