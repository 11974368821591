(function () {
  "use strict";

  /**
   * @ngdoc object
   * @name courseOutline.controller:CourseOutlineCtrl
   *
   * @description
   *
   */
  angular
    .module("courseOutline")
    .controller("CourseOutlineCtrl", CourseOutlineCtrl);

  function CourseOutlineCtrl(
    $stateParams,
    $state,
    $timeout,
    $rootScope,
    $window,
    localStorageService) {
    var vm = this;
    vm.ctrlName = "CourseOutlineCtrl";
    vm.currentUser = localStorageService.get("currentUser");
    vm.currentProgram = localStorageService.get("currentProgram");
    vm.currentParts = vm.currentProgram ? localStorageService.get("currentProgram").program_parts : null;
    vm.currentPartId = vm.currentProgram ? vm.currentProgram.latest_part_id : null;

    $rootScope.loading = !($state.fromState.name.indexOf("part.") >= 0 || $state.fromState.name.indexOf("workbook") >= 0);

    angular.element("html, body").animate({
      scrollTop: angular.element(document.getElementsByClassName("course-outline-page")[0]).offset().top - 200 + "px" // eslint-disable-line angular/document-service
    }, 500);

    $rootScope.$on("LocalStorageModule.notification.setitem", function (event, data) { // eslint-disable-line angular/on-watch
      var newProg = JSON.parse(data.newvalue); // eslint-disable-line angular/json-functions
      if (data.key === "currentProgram" && (!vm.currentProgram || vm.currentProgram && vm.currentProgram.id !== newProg.id)) {
        $window.location.reload();
      }
    });
  }
}());
