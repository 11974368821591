/* eslint wrap-iife: [2, "inside"] */
(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name directives.directive:msExpandable
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="directives">
       <file name="index.html">
        <ms-expandable></ms-expandable>
       </file>
     </example>
   *
   */

  angular
    .module('directives')
    .directive('msExpandable', msExpandable);

  function msExpandable($compile, $timeout, $window) {
    var directive = {
      restrict: 'A',
      scope: {showChar: "@"},
      link: function link($scope, element) {
        $timeout(function() {
          if (angular.isUndefined($scope.showChar) || !$scope.showChar) $scope.showChar = 250;
          if (element.text().length <= $scope.showChar) return;

          $scope.shrinked = true;

          activate();
        }, 0);

        // ------------------------

        function activate() {
          var moreTpl = "...&nbsp;<a href ng-click='shrinked=false; $event.stopPropagation();'>View all</a>",
            lessTpl = "&nbsp;<a href ng-click='shrinked=true; $event.stopPropagation();'>View less</a>";

          $scope.contentShrinked = angular
            .element("<div class='ms-expandable'></div>")
            .append("<p>" + element.html().substr(0, $scope.showChar) + "</p>")
            .append(moreTpl);

          $scope.contentExpanded = angular
            .element("<div class='ms-expandable'></div>")
            .append(element.html())
            .append(lessTpl);

          $window.onbeforeprint = showExpanded;
          $window.onafterprint = toggle;

          $scope.$watch('shrinked', function() {
            toggle();
          });
        }

        function toggle() {
          return $scope.shrinked === true ? showShrinked() : showExpanded();
        }

        function showShrinked() {
          element.html($compile($scope.contentShrinked)($scope));
        }

        function showExpanded() {
          element.html($compile($scope.contentExpanded)($scope));
        }
      }
    };

    return directive;
  }
})();
