<div class="edit-reflection">
  <form novalidate>
    <div class="form-group edit-reflection-fcontainer">
      <div class="edit-reflection-fitem avatar-holder">
        <a href="/users/{{editReflectionVm.reflection.author.id}}" class="avatar-img-circle">
          <img class="avatar-img-circle"
               ng-src="{{editReflectionVm.reflection.author.avatar_url}}">
        </a>
      </div>
      <div class="edit-reflection-fitem text-area-holder" ng-class="{editing: editReflectionVm.editing}">
        <div ms-expandable
             data-ng-bind-html="editReflectionVm.reflection.html"
             ng-if="!editReflectionVm.editing"
             class="text-area animated">
        </div>
        <ms-expandable-textarea
          class="text-area animated"
          item-id="editReflectionVm.reflection.id"
          ng-if="editReflectionVm.editing"
          editing="editReflectionVm.editing"
          ng-model="editReflectionVm.reflection.body"
          placeholder="Edit note or reflection"
          ta-height="editReflectionVm.taHeight"
          start-focused="true">
        </ms-expandable-textarea>

      </div>
      <div class="edit-reflection-fitem three-dot-holder" ng-if="!editReflectionVm.editing">
        <div class="three-dots">
          <ms-three-dots-menu ng-if="editReflectionVm.menuArr.length > 0"
                              menu-arr="editReflectionVm.menuArr">
          </ms-three-dots-menu>
        </div>
      </div>
    </div>
    <div class="reflection-footer-fcontainer">
      <div class="reflection-info-fitem author-details" ng-if="!editReflectionVm.editing">
        <span ng-bind-html="editReflectionVm.authorTitle | trustAsHtml"></span>&nbsp;| {{editReflectionVm.reflection.created_at | date:"d MMM"}}
      </div>
      <div class="form-group actions-group  reflection-info-fitem reflection-actions-fcontainer" ng-if="editReflectionVm.editing">
        <div class="reflection-actions-fitem">
          <button ng-click="editReflectionVm.reset()"
                  class="btn btn-thin btn-cancel">Cancel
          </button>
        </div>
        <div class="reflection-actions-fitem">
          <button ng-click="editReflectionVm.update()"
                  ng-disabled="!editReflectionVm.reflection.body"
                  class="btn btn-thin btn-red">Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>






