(function () {
  "use strict";

  /**
   * @ngdoc directive
   * @name directives.directive:coursePartLink
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="directives">
   <file name="index.html">
   <course-part-link part="part-object"></course-part-link>
   </file>
   </example>
   *
   */
  angular
    .module("directives")
    .directive("coursePartLink", coursePartLink);

  function coursePartLink() {
    return {
      restrict: "E",
      scope: {
        part: "=",
        isBrief: "=?"
      },
      templateUrl: "directives/building-blocks/course-part-link-directive.tpl.html",
      replace: false,
      controllerAs: "vmCoursePartLink",
      controller: function ($rootScope, $scope, $state, CurrentPartService, localStorageService, OnboardingFactory) {
        var vm = this;
        vm.part = $scope.part;
        vm.isBrief = $scope.isBrief || false;
        vm.briefsOrPart = vm.isBrief ? "briefs" : "part";

        vm.checkPartDetails = checkPartDetails;

        vm.srefState = vm.part.active ? vm.briefsOrPart + ".lesson({ partId: vmCoursePartLink.part.id, lessonId: vmCoursePartLink.part.latest_lesson_id })" : ".";

        function checkPartDetails() {
          if (vm.part.active) {
            if (angular.isUndefined(vm.part.redirect_to_introduction_video)) {
              CurrentPartService.get(vm.part.id).then(function (res) {
                vm.part = res.program_part;
                localStorageService.set("currentPart", vm.part);
                goToState();
              });
            } else {
              goToState();
            }
          }
        }

        function goToState() {
          $rootScope.loading = true;
          return vm.isBrief ?
            $state.go("briefs.lesson", {partId: vm.part.id, lessonId: vm.part.latest_lesson_id}) :
            OnboardingFactory.ifBoardingThenGo(vm.part);
        }
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
