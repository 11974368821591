(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name directives.directive:msHangout
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="directives">
       <file name="index.html">
        <ms-hangout></ms-hangout>
       </file>
     </example>
   *
   */
  angular
    .module('directives')
    .directive('msHangout', msHangout);

  function msHangout() {
    return {
      restrict: 'E',
      scope: {},
      templateUrl: 'directives/home-page/ms-hangout-directive.tpl.html',
      bindToController: {
        calleeName: "=",
        workbookId: "=",
        expert: "="
      },
      replace: false,
      controllerAs: 'msHangoutVm',
      controller: function () {
        var vm = this;
        vm.hangoutTitle = 'Your calls';
        vm.hangoutMsg = "Your feedback, coaching and support calls will happen through this private Google hangout.";
        vm.btnMsg = "Start Hangout";

        vm.firstName = this.calleeName.split(" ")[0];

        if (this.expert) {
          vm.hangoutTitle = "Call " + vm.firstName;
          vm.hangoutMsg = vm.firstName + " will join you in this Google Hangout at the time of your call.";
          vm.btnMsg = "Start Hangout";
        }
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
