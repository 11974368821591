(function() {
  'use strict';

  angular
    .module('classroom')
    .service('OnboardingService', OnboardingService);

  function OnboardingService(AuthService) {
    var self = {
        agent: function (partId) {
          return AuthService.apiService()
            .one('parts', partId);
        },

        post: function (partId, subject) {
          return self.agent(partId)
            .all('skip-onboarding-step')
            .post({subject: subject});
        }
      };

    return self;
  }
}());
