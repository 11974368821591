(function () {
  "use strict";

  /**
   * @ngdoc directive
   * @name directives.directive:roundNumberFlex
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="directives">
   <file name="index.html">
   <round-number-flex posiiton="number"></round-number-flex>
   </file>
   </example>
   *
   */
  angular
    .module("directives")
    .directive("roundNumberFlex", roundNumberFlex);

  function roundNumberFlex() {
    return {
      restrict: "E",
      scope: {
        position: "="
      },
      templateUrl: "directives/building-blocks/round-number-flex-directive.tpl.html",
      replace: false,
      controllerAs: "vmRoundNumberFlex",
      controller: function () {
        var vm = this;
        vm.name = "vmRoundNumberFlex";
      },
      link: function (scope, element, attrs, vm) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        scope.$watch("position", function (newValue) {
          vm.position = newValue;
        });
      }
    };
  }
}());
