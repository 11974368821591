(function () {
  'use strict';

  angular
    .module('classroom')
    .service('ProjectItemService', ProjectItemService);

  function ProjectItemService(AuthService) {
    var self = {
      create: function (projectId, item) {
        var fd = new FormData(),
          req = AuthService.apiService()
            .one('projects', projectId)
            .all('items');

        fd.projectable_type = 'Project' + item.projectable_type; /* jshint ignore:line */ // eslint-disable-line camelcase
        fd.projectable = {};
        switch (item.projectable_type) { /* jshint ignore:line */ // eslint-disable-line camelcase
        case 'Image':
          fd.projectable.image = item.projectable.image;
          fd.append('project_item[projectable_type]', fd.projectable_type); /* jshint ignore:line */ // eslint-disable-line camelcase
          fd.append('project_item[projectable][image]', item.projectable.image);
          return req
            .withHttpConfig({transformRequest: angular.identity})
            .customPOST(fd, undefined, undefined, {'Content-Type': undefined});
        case 'Pdf':
          fd.projectable.pdf = item.projectable.pdf;
          fd.append('project_item[projectable_type]', fd.projectable_type); /* jshint ignore:line */ // eslint-disable-line camelcase
          fd.append('project_item[projectable][pdf]', item.projectable.pdf);
          return req
            .withHttpConfig({transformRequest: angular.identity})
            .customPOST(fd, undefined, undefined, {'Content-Type': undefined});
        case 'Text':
          fd.projectable.text = item.projectable;
          return req.post({project_item: fd}); /* jshint ignore:line */ // eslint-disable-line camelcase
        case 'Video':
          fd.projectable.video_url = item.projectable; /* jshint ignore:line */ // eslint-disable-line camelcase
          return req.post({project_item: fd}); /* jshint ignore:line */ // eslint-disable-line camelcase
        // no default
        }
      },

      update: function (projectId, item) {
        if (item.projectable.embed_video_url) { /* jshint ignore:line */ // eslint-disable-line camelcase
          item.projectable.video_url = item.projectable.embed_video_url; /* jshint ignore:line */ // eslint-disable-line camelcase
          item.projectable = _.omit(item.projectable, "embed_video_url", "body");
        }
        return AuthService.apiService()
          .one('projects', projectId)
          .one('items', item.id)
          .customPUT({project_item: item}); /* jshint ignore:line */ // eslint-disable-line camelcase
      },

      delete: function (projectId, item) {
        return AuthService.apiService()
          .one('projects', projectId)
          .one('items', item.id)
          .remove();
      },

      move: function (projectId, item, direction) {
        direction = direction === 'up' ? 'move_higher' : 'move_lower';
        return AuthService.apiService()
          .one('projects', projectId)
          .one('items', item.id)
          .one(direction)
          .post();
      }
    };

    return self;
  }
}());
