<h2>signIn</h2>
<p>{{signInVm.ctrlName}}</p> Is this working?
<div class="container-fluid">

  <div class="row">
    <div class="col-sm-12 col-md-6" ng-if="!signInVm.authenticatedUser">

      <form name="loginForm" ng-submit="signInVm.login()">
        <div ng-if="showError" class="alert alert-danger">
          {{signInVm.loginErrorMsg}}
        </div>
        <div class="form-group">

          <label>Email</label>
          <input class="form-control" name="email" ng-model="signInVm.user.email" placeholder="Enter your email address">
          <div ng-if="formErrors.email" class="alert alert-danger">
            {{ formErrors.email }}
          </div>
        </div>

        <div class="form-group">
          <label>Password</label>
          <input class="form-control" type="password" name="password" ng-model="signInVm.user.password">
        </div>

        <div class="form-group">
          <button ng-click="login()" type="submit" [disabled]="loginForm.invalid">Sign in</button>
        </div>
      </form>

    </div>
  </div>

  <div ng-if="signInVm.authenticatedUser">
    <button ng-click="signInVm.logout()">Sign out</button>
  </div>
</div>
`
