<div class="hangout-directive">
  <div class="divider-horizontal"></div>
  <h4>{{msHangoutVm.hangoutTitle}}</h4>
  <div class="call-message">
    {{msHangoutVm.hangoutMsg}}<br>
    <a href="https://support.mastered.com/article/212-how-do-i-join-my-feedback-and-coaching-calls-on-google-hangouts"
       target="_blank"
       ng-if="!msHangoutVm.expert">
      Read how Google Hangouts work
    </a>
  </div>
  <a class="btn btn-hangout" href="https://hangouts.google.com/hangouts/_/mastered.co/{{msHangoutVm.workbookId}}" target="_blank">
    <div style="fill: white; padding-top: 3px;"><svg width="24px" height="24px" class="pTh3n"><path d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z"></path></svg></div>
    <div class="btn-label">&nbsp;{{msHangoutVm.btnMsg}}</div>
  </a>
  <div class="divider-horizontal visible-xs-block"></div>
</div>
