<div>
  <div class="thumbnail-holder fade-element-in" ng-if="newItemVm.pdfs[0].name">
    <div ng-repeat="pdf in newItemVm.pdfs" class="upload-img-thumbnail-list fade-element-in">
      <div class="thumb-title">
        <div class="thumb-holder" ng-if="$index === newItemVm.pdfIndex">{{pdf.name}} {{pdf.$error}} {{pdf.$errorParam}} {{pdf.progress}}</div>
      </div>
    </div>
    <div class="thumb-title" id="loading-bar-container"></div>
  </div>

  <div class="alert alert-dismissible alert-danger" ng-repeat="f in newItemVm.invalidFiles" ng-if="!newItemVm.maxFilesExceeded" ng-cloak>
    <span ng-if="f.$error === 'maxSize'">Sorry, <strong>{{f.name}}</strong> is over {{f.$errorParam}} in size. Please resize or pick another file.</span>
    <span ng-if="f.$error === 'pattern'">Sorry, we don't support the file type of <strong>{{f.name}}</strong>. Please try a PDF.</span>
  </div>
  <div class="alert alert-dismissible alert-danger" ng-if="newItemVm.maxFilesExceeded">
    Woah there! You can only upload one document at a time. Please try again with fewer files.
  </div>

  <div ng-show="!itemEditVm  && newItemVm.showPdfDropzone"
       class="form-group img-upload-form"
       ngf-drop="newItemVm.addPdfs()"
       ngf-select="newItemVm.addPdfs()"
       ng-model="newItemVm.pdfs"
       ngf-drag-over-class="'file-over'"
       ngf-multiple="true"
       accept="*/pdf"
       ngf-pattern=".pdf"
       ngf-keep="distinct"
       ngf-validate="{size: {max: newItemVm.maxFileSize, min: '10B'}, duration: {max: '2m'}}"
       ngf-model-invalid="newItemVm.invalidFiles">

    <div class="dropzone-holder">
      <div class="img-drop-zone">
        <div class="drop-zone-label">
          <div class="dropzone-img-icon" ms-icon icontype="'image'"></div>
          <h4 class="hidden-xs">Drag files here</h4>
          <h4 class="visible-xs">Click to select files</h4>
          <div class="drop-zone-sub hidden-xs">
            (or click to select)
          </div>
          <div class="drop-zone-rules">
            {{newItemVm.maxFileSize}} limit per PDF<br>
            Up to one document at a time
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
