(function () {
  'use strict';

  angular
      .module('directives')
      .directive('sentimentTester', sentimentTester);

  function sentimentTester($location, SentimentTesterService) {
    return {
      restrict: 'E',
      templateUrl: 'directives/sentiment-tester-directive.tpl.html',
      controllerAs: 'vm',
      controller: function () {
        var vm = this;
        vm.visible = false;
        vm.score = null;
        vm.message = null;
        // FIXME: inject programId in data attrs
        vm.programId = $location.path().split('/')[2];

        vm.show = function () {
          vm.visible = true;
        };

        vm.hide = function () {
          vm.visible = false;
        };

        vm.sendScore = function (score) {
          SentimentTesterService.sendScore(vm.programId, score).then(function () {
            vm.score = parseInt(score, 10);
            vm.setMessage();
          });
        };

        vm.checkForSentimentTest = function () {
          SentimentTesterService.checkTest(vm.programId).then(function (res) {
            if (res.data) {
              vm.show();
            }
          });
        };

        vm.setMessage = function () {
          switch (vm.score) {
          case 1:
          case 2:
            vm.message = "We're sorry to hear that. Your talent manager will be in touch to see if they can help. Feel free to drop them an email in the meantime.";
            break;
          case 3:
            vm.message = "Thanks for sharing your thoughts. Sounds like things could be better. <a href='https://mastered.desk.com/customer/portal/articles/2402784-how-to-manage-your-time-on-the-program' target='_blank'>Let us know</a> if we can help.";
            break;
          case 4:
          case 5:
            vm.message = "We're happy to hear that. Let us know if there's anything we can do to keep the good vibes going.";
            break;
          default:
          }
        };
      },
      link: function (scope, element, attrs, vm) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        vm.checkForSentimentTest(vm.programId);
      }
    };
  }
}());
