(function () {
  'use strict';

  angular
    .module('projects')
    .directive('projectItemPdf', projectItemPdf);

  function projectItemPdf() {
    return {
      restrict: 'EA',
      scope: true,
      templateUrl: 'directives/projects/items/project-item-pdf-directive.tpl.html',
      replace: false
    };
  }
}());
