(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name directives.directive:goals
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="directives">
   <file name="index.html">
   <goals></goals>
   </file>
   </example>
   *
   */
  angular
    .module('directives')
    .directive('goals', goals);

  function goals() {
    return {
      restrict: 'EA',
      scope: {
        workbookId: '@',
        permissions: '=',
        header: '@'
      },
      bindToController: true,
      replace: false,
      templateUrl: 'directives/workbooks/goals-directive.tpl.html',
      controllerAs: 'goalsVm',
      controller: function (GoalsService, $window, $log) {
        var vm = this,
          MAX_GOALS = 5;

        vm.msgBlocked = "You will be able to set your goals here soon.";
        vm.addGoal = addGoal;
        vm.updateGoal = updateGoal;
        vm.removeGoal = removeGoal;
        vm.reachedTheLimit = reachedTheLimit;

        activate();

        return vm;

        // -----------------

        function activate() {
          GoalsService.get(vm.workbookId).then(function (res) {
            vm.goals = res.goals;
          });
          return vm.goals;
        }

        function addGoal(goal) {
          GoalsService.post(vm.workbookId, {goal: goal}).then(function (resp) {
            vm.goals.push(resp.goal);
          }, function (error) {
            $log.error(error);
            return error;
          });

          return vm.goal;
        }

        function updateGoal(goal) {
          GoalsService.put(vm.workbookId, goal).then(function (resp) {
            var idx;
            for (idx = 0; idx < vm.goals.length; idx++) {
              if (vm.goals[idx].id === goal.id) {
                vm.goals[idx] = resp.goal;
                break;
              }
            }
          }, function (error) {
            $log.error(error);
            return error;
          });
        }

        function removeGoal(goal) {
          if ($window.confirm("Are you sure you want to delete the goal?")) {
            GoalsService.remove(vm.workbookId, goal.id).then(function (resp) {
              vm.goals = resp.goals;
            }, function (error) {
              $log.error(error);
              return error;
            });
          }
        }

        function reachedTheLimit() {
          if (!vm.goals) return true;
          return vm.goals.length >= MAX_GOALS;
        }
      }
    };
  }
}());
