<div class="social-links-directive">
  <h4> {{msSocialLinksVm.user.name}}</h4>
  <div class="social-links-fcontainer">
    <a href="{{user.profile_url}}" target="_blank" class="social-link-fitem">
      <img class="avatar-img-circle" ng-src="{{user.avatar_url}}">
    </a>
    <div class="social-link-fitem" ng-repeat="(platform, link) in msSocialLinksVm.user.social_links">
      <a href="{{link}}" target="_blank">
        <i class="fa fa-{{platform}}"></i>
      </a>
    </div>
  </div>
  <div class="divider-horizontal"></div>
</div>
