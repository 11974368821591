(function () {
  'use strict';

  /* @ngdoc object
   * @name classroom
   * @description
   *
   */
  angular
    .module('classroom', [
      'angulartics',
      'angulartics.google.analytics',
      'angulartics.mixpanel',
      'ngAria',
      'ui.router',
      'ui.router.title',
      'ui.select',
      'ngAnimate',
      'mgcrea.ngStrap',
      'ui.bootstrap',
      'angular-loading-bar',
      'ngFileUpload',
      'LocalStorageModule',
      'jcs-autoValidate',
      'angular-ladda',
      'angularGrid',
      'restangular',
      'ng.deviceDetector',
      'environment',
      'offClick',
      'ngCookies',
      'ngRetina',
      'angularSpinner',
      'duScroll',
      'programs',
      'workbook',
      'briefs',
      'directives',
      'lessons',
      'utils',
      'ngSanitize',
      'filters',
      'projects',
      'textAngular',
      'courseOutline',
      'msHoneybadger',
      'sessions'
    ]);
}());
