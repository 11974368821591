/* eslint-disable camelcase*/
/* jshint camelcase: false */

(function () {
  "use strict";

  /**
   * @ngdoc service
   * @name directives.service:CollaboratorsService
   *
   * @description
   *
   */
  angular
    .module("classroom")
    .service("CollaboratorsService", CollaboratorsService);

  function CollaboratorsService(AuthService) {
    var self = {
      getCollaborators: function (projectId) {
        var endpoint = "projects/" + projectId + "/project_collaborators";
        return AuthService.apiService()
          .one(endpoint)
          .get();
      },
      getCollaborator: function (projectId, collaboratorId) {
        return AuthService.apiService()
          .one("projects", projectId)
          .one("project_collaborators", collaboratorId)
          .get();
      },

      addCollaborator: function (projectId, collaborator) {
        var newCollab = {
          project_collaborator: {
            user_id: collaborator.id,
            discipline: collaborator.discipline,
            name: collaborator.name,
            website: collaborator.website
          }
        };
        return AuthService.apiService()
          .one("projects", projectId)
          .post("project_collaborators", newCollab);
      },

      updateCollaborator: function (projectId, collaborator) {
        return AuthService.apiService()
          .one("projects", projectId)
          .one("project_collaborators", collaborator.id)
          .customPUT({project_collaborator: collaborator});
      },

      deleteCollaborator: function (projectId, collaboratorId) {
        return AuthService.apiService()
          .one("projects", projectId)
          .one("project_collaborators", collaboratorId)
          .remove();
      }
    };

    return self;
  }
}());
