(function () {
  "use strict";

  /**
   * @ngdoc directive
   * @name directives.directive:msWhiteCta
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="directives">
   <file name="index.html">
   <ms-white-cta></ms-white-cta>
   </file>
   </example>
   *
   */
  angular
    .module("directives")
    .directive("msWhiteCta", msWhiteCta);

  function msWhiteCta() {
    return {
      restrict: "EA",
      scope: true,
      bindToController: {
        btnFn: "&",
        btnMsg: "="
      },
      templateUrl: "directives/ms-white-cta-directive.tpl.html",
      replace: false,
      controllerAs: "msWhiteCtaVm",
      controller: function () {
        var vm = this;
        return vm;
      }
    };
  }
}());
