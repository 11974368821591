<div class='edit-goal'>
  <div class='col-xs-1'>
    <span class="goals-directive__position circle">
      {{ editGoalVm.goal.position }}
    </span>
  </div>

  <div class='col-xs-11 goals-directive__item pointer'>
    <div ng-show="!editGoalVm.entered()"
         ng-click='editGoalVm.entered(true); $event.stopPropagation();'
         ng-bind-html="editGoalVm.goal.html"
         ms-expandable
         show-char="100">
    </div>
    <div ng-show="editGoalVm.entered()"
         class='col-xs-11'>
      <!-- form wrapper -->
      <form novalidate
            class="edit-goal__form"
            name="formGoal">
        <div class="row">
          <div class="form-group">
              <textarea ng-model="editGoalVm.buffer.body"
                        ng-model-options="{ debounce: { 'default': 250 } }"
                        rows='1'
                        class='col-xs-12'
                        name="body"
                        ms-focus-me="editGoalVm.entered()"></textarea>
          </div>
          <div class="col-xs-12" ng-show="editGoalVm.buffer.body.length>600">
            <p class="goals-directive__too-long-msg">
              The goal is getting long. Keep your goals concise to help you stay focussed.
            </p>
          </div>
        </div>

        <div class="row form--actions">
          <div class="col-xs-12">
            <div class='form-group actions-group'>
              <button ng-click="editGoalVm.remove(editGoalVm.goal)"
                      class="btn btn--narrow btn-delete col-xs-4">
                <i class="fa fa-trash-o"></i>Delete</button>
              <button ng-click="editGoalVm.reset()"
                      class="btn btn--narrow btn-cancel col-xs-4">Cancel</button>
              <button ng-click="editGoalVm.update(editGoalVm.buffer)"
                      ng-disabled="!editGoalVm.buffer.body"
                      class="btn btn--narrow btn-red col-xs-4">Save</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
