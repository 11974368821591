(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name filters.filter:formatStartDate
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('filters')
    .filter('formatStartDate', formatStartDate);

  function formatStartDate($filter) {
    return function (startDate, withOpenText, dontShowYear) {
      var
        today = new Date(),
        opens = new Date(startDate);
      withOpenText = withOpenText || false;
      dontShowYear = dontShowYear || false;
      if (!startDate) {
        return '';
      }
      if (startDate.substr(0, 4) === moment().year().toString() || dontShowYear) {
        startDate = $filter('date')(startDate, 'd MMMM');
      } else {
        startDate = $filter('date')(startDate, 'd MMMM yyyy');
      }
      if (withOpenText) {
        if (today <= opens) {
          startDate = 'Opens ' + startDate;
        } else {
          startDate = 'Opened ' + startDate;
        }
      }
      return startDate;
    };
  }
}());
