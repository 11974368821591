<div class="new-goal">
  <div class="col-xs-1">
    <span class="goals-directive__position goals-directive__position--shaded circle">{{newGoalVm.position}}</span>
  </div>
  <div class="col-xs-11">

    <!-- when form is hideen -->

    <a ng-if="!newGoalVm.entered()"
       href
       class="goals-directive__item goals-directive__item--shaded pointer"
       ng-click='newGoalVm.entered(true)'>{{ newGoalVm.position === '1' ? 'Add your first goal' : 'Add new goal' }}</a>

    <!-- when form is entered -->

    <div ng-if="newGoalVm.entered()"
         class='col-xs-11'>
        <!-- form wrapper -->
      <form novalidate
            class="new-goal__form"
            name="formGoal">
        <div class="row">
          <div class="form-group">
              <textarea ng-model="newGoalVm.goal.body"
                        ng-model-options="{ debounce: { 'default': 250 } }"
                        rows='1'
                        class='col-xs-12'
                        name='body'
                        ms-focus-me="newGoalVm.entered()"
                        ng-attr-placeholder="{{ newGoalVm.position === '1' ? 'Add your first goal' : 'Add new goal' }}"></textarea>
          </div>
          <div class="col-xs-12" ng-show="newGoalVm.goal.body.length>600">
            <p class="goals-directive__too-long-msg">
              The goal is getting long. Keep your goals concise to help you stay focussed.
            </p>
          </div>
        </div>

        <div class="row form--actions">
          <div class="col-xs-12 col-xs-push-4">
            <div class='form-group actions-group'>
              <button ng-click="newGoalVm.reset()"
                      class="btn btn--narrow btn-cancel col-xs-4">Cancel</button>
              <button ng-click="newGoalVm.addGoal()"
                      ng-disabled="!newGoalVm.goal.body"
                      class="btn btn--narrow btn-red col-xs-4">Save</button>
            </div>
          </div>
        </div>

      </form>
    </div>
  </div>
</div>
