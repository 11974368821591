(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name projects.controller:ProjectsCtrl
   *
   * @description
   *
   */
  angular
    .module('projects')
    .controller('ProjectsCtrl', ProjectsCtrl);

  function ProjectsCtrl($rootScope, $state, $stateParams, $timeout, $window, ProjectsService, CurrentPartService) {
    var vm = this;

    vm.programId = $stateParams.programId;
    vm.partId = $stateParams.partId;
    vm.lessonId = $stateParams.lessonId;
    vm.page = parseInt($stateParams.page, 10) || 1;
    vm.projects = [];
    vm.moreToLoad = false;
    vm.showMoreButton = true;
    vm.oldiPhone = iPhone5orLess();

    vm.paginate = function () {
      paginate();
    };

    $timeout(function () {
      init();
    });

    function init() {
      CurrentPartService.get(vm.partId).then(function (res) {
        vm.programPart = {
          name: res.program_part.name, /* jshint ignore:line *//* eslint-disable-line camelcase */
          lessonId: res.program_part.latest_lesson_id /* jshint ignore:line *//* eslint-disable-line camelcase */
        };

        if (res.program_part.feedback) { /* jshint ignore:line */
          $state.go('lesson', {
            programId: vm.programId,
            partId: vm.partId,
            lessonId: vm.lessonId
          });
        } else {
          getProjects();
        }
      });
    }

    function paginate() {
      vm.page++;
      getProjects();
    }

    function getProjects() {
      vm.fetchingProjects = true;
      vm.moreToLoad = false;
      ProjectsService
        .getAll(vm.lessonId, vm.page)
        .then(function (result) {
          vm.title = result.title;
          if (vm.page === 1) {
            vm.projects = result.projects;
          } else {
            vm.projects = vm.projects.concat(result.projects);
          }
          if (result.projects.length === 20) {
            vm.moreToLoad = true;
          }
          vm.fetchingProjects = false;
          // ToDo: refactoring $rootScope
          $rootScope.$broadcast("page:load");
        });
    }

    // iPhone model checks.
    function iPhone5orLess() {
      return $window.screen.height / $window.screen.width === 1.775 && $window.devicePixelRatio === 2;
    }

    return vm;
  }
}());
