/* eslint-disable camelcase */
/* jshint camelcase: false */
(function () {
  "use strict";

  /**
   * @ngdoc service
   * @name lessons.factory:CurrentProgram
   *
   * @description
   *
   */
  angular
    .module("lessons")
    .factory("CurrentProgram", CurrentProgram);

  function CurrentProgram($location, $state, $window, AuthService, CurrentPartService, localStorageService, EventTracker) {
    var currentUser = localStorageService.get("currentUser"),
      currentProgram = {
        getCurrentProgram: getCurrentProgram,
        setCurrentProgram: setCurrentProgram,
        getCurrentPart: getCurrentPart,
        collaborationProgramId: collaborationProgramId,
        banners: banners,
        inSession: inSession
      };

    function getCurrentProgram() {
      currentUser = currentUser || localStorageService.get("currentUser");
      return AuthService.apiService()
        .one("programs", $state.toParams.programId || currentUser.programs[0].id) /* fallback */
        .get();
    }

    function banners(programId) {
      return AuthService.apiService()
        .one("programs", programId)
        .one("banners")
        .get();
    }

    function inSession(programId) {
      return AuthService.apiService()
        .one("programs", programId)
        .one("in_session")
        .get();
    }

    function getCurrentPart(partId) {
      partId = partId || parseInt($state.toParams.partId, 10) || localStorageService.get("currentProgram").latest_part_id;
      return AuthService.apiService()
        .one("parts", partId)
        .get();
    }

    function storeCurrents(course, brief) {
      var toParams = $state.toParams;

      if (!brief || !localStorageService.get("currentProgram")) {
        localStorageService.set("currentProgram", course);
      }

      if ($window.location.href.indexOf("/outline") === -1) {
        getCurrentPart().then(function (res) {
          localStorageService.set("currentPart", res.program_part);
        }, function errorCallback(error) {
          console.log(error);
        });
      }

      localStorageService.set("currentLessonId", parseInt(toParams.lessonId, 10) || localStorageService.get("currentProgram").latest_lesson_id);
    }

    function setCurrentProgram() {
      var toParams = $state.toParams,
        toState = $state.toState,
        isBriefs = toState.name === "briefs" || toState.name === "briefs.lesson" || toState.name === "briefOutline",
        isOutline = toState.name === "courseOutline" || toState.name === "briefOutline";
      if (updateCurrentProgram()) {
        getCurrentProgram().then(function (res) {
          var currentPartId;

          if (angular.isDefined(currentUser) && currentUser)
            EventTracker.identify(currentUser.id);
          /* rescue for awhile SM2071120 */
          storeCurrents(res.course, isBriefs);

          currentPartId = localStorageService.get("currentPart.id");

          if (currentPartId !== null &&
            toState.name.indexOf("introduction-video") < 0 &&
            toState.name.indexOf("booking") < 0 &&
            toState.name.indexOf("workbook") < 0 &&
            toState.name.indexOf("project") === -1 &&
            !isBriefs &&
            angular.isUndefined(toParams.lessonId)) {
            if (res.course.id >= 57 && !isOutline) {
              $state.go("part.lesson", {
                programId: localStorageService.get("currentProgram").id,
                partId: currentPartId,
                lessonId: localStorageService.get("currentLessonId")
              });
            } else {
              $state.go("courseOutline", {programId: res.course.id});
            }
          }
        });
      }
    }

    function updateCurrentProgram() {
      var updateProgram,
        changedParams,
        dontUpdate;
      if (localStorageService.get("currentProgram")) {
        updateProgram = parseInt($location.path().split("/")[2], 10) !== localStorageService.get("currentProgram").id;
        changedParams = $state.toParams.programId !== $state.fromParams.programId;
        dontUpdate = $state.fromState.name === "briefs.lesson" && $state.toState.name === "briefOutline";
        return !dontUpdate && (updateProgram || changedParams);
      }
      return true;
    }

    function collaborationProgramId() {
      return 48;
    }

    return currentProgram;
  }
}());
