<div class="mobile-course-nav crsmnu-flex-container" role="navigation" ng-class="{'hide-menu': mobileCourseMenuVm.hideMenu()}" ng-if="lessonVm.lesson">
  <div class="dropdown crsmnu-flex-item" ng-click="mobileCourseMenuVm.rotate = !mobileCourseMenuVm.rotate">
    <a class="dropdown-toggle drpdwn-hldr"
       data-toggle="dropdown"
       href=""
       role="button">
      <ms-icon
        class="menu-icon burger-menu-icon"
        icontype="'burger'"></ms-icon>
      <div class="drpdwn-flex-item nmbr" ng-if="mobileCourseMenuVm.part.position">
        <round-number-flex position="mobileCourseMenuVm.part.position"></round-number-flex>
      </div>
      <div class="drpdwn-flex-item ttle" ng-class="{'extra-margin': !mobileCourseMenuVm.part.position}">
        <div class="part-title" ng-if="mobileCourseMenuVm.part">
          {{mobileCourseMenuVm.part.title | msTruncate:27}}<br>
          <span class="lesson-title">{{lessonVm.lesson.name}}</span></div>
      </div>
    </a>
    <div class="dropdown-menu course-part-dropdown mob" ng-click="mobileCourseMenuVm.rotate = !mobileCourseMenuVm.rotate" ng-if="mobileCourseMenuVm.part">
      <module-menu
        class="ms-flex-container"
        part-id="{{mobileCourseMenuVm.part.id}}">
      </module-menu>
    </div>
  </div>

  <div class="crsmnu-flex-item nav-step" ng-if="lessonVm.lesson.previous_lesson.id">
    <a ui-sref="^.lesson({ lessonId: lessonVm.lesson.previous_lesson.id })">
      <ms-icon class="menu-icon" icontype="'back'"></ms-icon>
    </a>
  </div>
  <div class="crsmnu-flex-item nav-step" ng-if="lessonVm.lesson.next_lesson.id">
    <a ui-sref="^.lesson({ lessonId: lessonVm.lesson.next_lesson.id })">
      <ms-icon class="menu-icon" icontype="'fwd'"></ms-icon>
    </a>
  </div>
</div>
