(function () {
  'use strict';

  angular
    .module('classroom')
    .service('DisciplinesService', DisciplinesService);

  function DisciplinesService(AuthService) {
    var self = {
        fetch: function () {
          return AuthService.apiService()
            .one('disciplines')
            .get();
        }
      };

    return self;
  }
}());
