(function () {
  "use strict";

  angular
    .module("programs")
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state("part.introductionVideo", {
        url: "/introduction-video",
        templateUrl: "onboarding/introduction-video.tpl.html",
        controller: "IntroductionVideoCtrl",
        controllerAs: "introductionVideoVm",
        resolve: {
          localStorageService: "localStorageService",
          introductionData: function(localStorageService) {
            return localStorageService.get("currentPart");
          }
        }
      })

      .state("part.bookingFeedback", {
        url: "/booking-feedback",
        templateUrl: "onboarding/ycbm.tpl.html",
        controller: "BookingCtrl",
        controllerAs: "bookingVm",
        resolve: {
          CurrentPartService: "CurrentPartService",
          bookingData: function(CurrentPartService, $stateParams) {
            return CurrentPartService.bookingFeedback($stateParams.partId);
          }
        }
      })

      .state("part.bookingCoaching", {
        url: "/booking-coaching",
        templateUrl: "onboarding/ycbm.tpl.html",
        controller: "BookingCtrl",
        controllerAs: "bookingVm",
        resolve: {
          CurrentPartService: "CurrentPartService",
          bookingData: function(CurrentPartService, $stateParams) {
            return CurrentPartService.bookingCoaching($stateParams.partId);
          }
        }
      });
  }
}());
