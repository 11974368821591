<div class="footer-directive">
  <div class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-3">
          <ul class="list-unstyled">
            <li>
              <a href="/">
                <img ng-src="images/logo-white.svg" alt="Mastered">
              </a>
            </li>
            <li>
              <p>Talent. Meet opportunity</p>
            </li>
          </ul>
        </div>

        <div class="col-sm-3">
          <div class="top-line"></div>
          <ul class="list-unstyled">
            <li><a href="/news/accelerator-mastered">Accelerators</a></li>
            <li><a href="/about">About</a></li>
            <li><a href="/jobs">Jobs</a></li>
          </ul>
        </div>

        <div class="col-sm-3">
          <div class="top-line"></div>
          <ul class="list-unstyled">
            <li><a href="/contact">Contact Us</a></li>
            <li><a href="/terms-of-service">Terms &amp; Conditions</a></li>
            <li><a href="/privacy" title="Privacy Policy">Privacy Policy</a></li>
          </ul>
        </div>

        <div class="col-sm-3">
          <div class="top-line"></div>
          <ul class="list-unstyled">
            <li><p>Find us on social media</p></li>
            <li>
              <div class="row">
                <div class="col-xs-1 social-icons">
                  <a href="https://www.facebook.com/masteredhq" target="_blank">
                    <i class="fa fa-facebook"></i>
                  </a>
                </div>
                <div class="col-xs-1 social-icons">
                  <a href="https://www.instagram.com/masteredhq" target="_blank">
                    <i class="fa fa-instagram"></i>
                  </a>
                </div>
                <div class="col-xs-1 social-icons">
                  <a href="https://twitter.com/masteredhq" target="_blank">
                    <i class="fa fa-twitter"></i>
                  </a>
                </div>
                <div class="col-xs-1 social-icons">
                  <a href="https://the-dots.com/pages/mastered-73601" target="_blank">
                    <img class="the-dots" src="images/the-dots-white.svg" alt="the dots">
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <script type="text/javascript">(function (w, d) {
    var loader = function () {
      var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0];
      s.src = "//cdn.iubenda.com/iubenda.js";
      tag.parentNode.insertBefore(s, tag);
    };
    if (w.addEventListener) {w.addEventListener("load", loader, false);} else if (w.attachEvent) {w.attachEvent("onload", loader);} else {w.onload = loader;}
  })(window, document);</script>
</div>
