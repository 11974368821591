<div class="comments">
  <new-comment
    class="new-comment"
    commentable-id="commentsVm.commentableId"
    commentable-type="commentsVm.commentableType"
    program-id="commentsVm.programId">
  </new-comment>

  <div class="row" ng-show="commentsVm.loading">
    <div class="col-xs-12">
      <span us-spinner="{left: '50%', radius: 10, width: 5}" spinner-on="commentsVm.loading"></span>
    </div>
  </div>

  <div ng-show="commentsVm.meta.prev_page" class="recent row">
    <div class="col-xs-12">
      <a href ng-click="commentsVm.loadRecent(commentsVm.meta.prev_page)" ng-hide="commentsVm.fetchingRecent">Load recent comments</a>
      <span us-spinner="{left: '50%', radius: 10, width: 5} " spinner-on="commentsVm.fetchingRecent">
    </div>
  </div>

  <comment
    class="comment"
    id="comment-{{comment.id}}"
    entity="comment"
    root-commentable-type="commentsVm.commentableType"
    program-id="'{{commentsVm.programId}}'"
    ng-repeat="comment in commentsVm.comments">
  </comment>

  <div ng-show="commentsVm.meta.next_page" class="next row">
    <div class="col-xs-12">
      <a href ng-click="commentsVm.loadNext(commentsVm.meta.next_page)" ng-hide="commentsVm.fetchingNext">Load more comments</a>
      <span us-spinner="{left: '50%', radius: 10, width: 5} " spinner-on="commentsVm.fetchingNext">
    </div>
  </div>
</div>
