<div class="container-fluid tag-collabs">
  <span us-spinner="{top: '30%', left: '50%', radius: 10, width: 8}" ng-if="!projectVm.projectAuthor"></span>
  <div class="row">
    <h3>Add collaborators</h3>
  </div>
  <div class="collab-choice-holder row">
    <div class="col-xs-12">
      <h5>Tag the people you worked with on this project</h5>
    </div>
    <div class="row" ng-if="projectVm.projectAuthor">
      <div class="col-xs-11 user-detail-column">
        <collaborator-item
          class="collaborator-item"
          user-profile-url="projectVm.projectAuthor.profile_url"
          user-avatar-url="projectVm.projectAuthor.avatar_url"
          user-fullname="projectVm.ownProject? 'You': projectVm.projectAuthor.name"
          user-title="projectVm.ownProject? '(Author)' : null"
          user-discipline="projectVm.project.discipline"
          user-course="projectVm.projectAuthor.current_course">
        </collaborator-item>
      </div>
      <div class="col-xs-1">
        <ms-icon ui-sref="^.edit-collaborator({itemId: 'author', lessonId: projectVm.lessonId})" icontype="'edit'" class="edit-collab"></ms-icon>
      </div>
    </div>
    <div class="row" ng-if="projectVm.projectAuthor" ng-repeat="collaborator in projectVm.collaborators" ng-cloak>
      <div class="col-xs-11 user-detail-column">
        <collaborator-item
          class="collaborator-item"
          user-profile-url="collaborator.profile_url"
          user-avatar-url="collaborator.avatar_square_url"
          user-fullname="collaborator.name"
          user-discipline="collaborator.discipline"
          user-course="collaborator.current_course">
        </collaborator-item>
      </div>
      <div class="col-xs-1">
        <ms-icon ui-sref="^.edit-collaborator({itemId: collaborator.id, lessonId: projectVm.lessonId})" icontype="'edit'" class="edit-collab"></ms-icon>
      </div>
    </div>
    <div class="col-xs-12 ui-select-holder" ng-if="projectVm.projectAuthor">
      <ui-select
        class="select-collab"
        ng-model="projectVm.potCollab.selected"
        theme="bootstrap"
        title="Select Mastered collaborator"
        on-select="projectVm.onSelectCallback($item); $item.status == 'inactive';"
        reset-search-input="true"
        spinner-enabled="true">
        <ui-select-match placeholder="{{projectVm.selectPlaceholder}}">
          {{projectVm.selectPlaceholder}}
        </ui-select-match>
        <ui-select-choices
          repeat="potentialCollaborator.name as potentialCollaborator in projectVm.potentialCollaborators | msOrFilter: {name: $select.search, searchKey: $select.search, email: $select.search, searchKey: $select.search}"
          refresh="projectVm.potentialCollabs($select.search)"
          minimum-input-length="3"
          refresh-delay="500">
          <div class="row">
            <div class="collab-holder">
              <a href="">
                <div class="vertical-align">
                  <div class="flex2">
                    <img class="img-circle img-responsive user-avatar" ng-src="{{potentialCollaborator.avatar_square_url}}">
                  </div>
                  <div class="flex10 name-col">
                    <div class="name-bit" ng-bind-html="potentialCollaborator.name | highlight: $select.search"></div>
                    <div>{{potentialCollaborator.current_course}}</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </ui-select-choices>
      </ui-select>
    </div>
  </div>
  <div class="row">
    <a ui-sref="^.project_edit({lessonId: projectVm.lessonId})">
      <button class="btn btn-black tag-done-btn">
        Done
      </button>
    </a>
  </div>
</div>

